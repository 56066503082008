import { Container } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  Card,
  Divider,
  HeaderBreadcrumbs,
  NumberLabel,
  Stack,
  Tab,
  Tabs,
  YearNavigationBlock,
} from '@pflegenavi/web-components';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { useTranslation } from 'react-i18next';
import { useTransactionsForFamilyMember } from '@pflegenavi/frontend/api-nursing-home';

import {
  TransactionSourceType,
  TransactionStatusColors,
} from '@pflegenavi/shared/api';

import { FmTransactionsToolbar } from '../components/transactions/FmTransactionsToolbar';
import {
  TransactionStatusValues,
  useFmTransactionsTable,
} from '../hooks/useFmTransactionsTable';
import { FmTransactionsTable } from '../components/transactions/FmTransactionsTable';
import { fmApplySortFilter } from '../helpers/fmTransactionsTableFilter';
import { useLocale } from '@pflegenavi/frontend/localization';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface TransactionsPageProps {}

// We don't want to create a new object every time the component is rendered
const sourceTypes = [
  TransactionSourceType.Receipt,
  TransactionSourceType.RecurringItem,
  TransactionSourceType.CancelledReceipt,
];

export const TransactionsPage: FC<TransactionsPageProps> = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { selectedResidentId } = useFMContext();

  const {
    isLoading,
    isError,
    data: transactions,
  } = useTransactionsForFamilyMember(
    selectedResidentId ?? 'undefined',
    sourceTypes
  );

  const {
    filterTerms,
    setFilterTerms,
    reportingYear,
    setReportingYear,
    order,
    orderBy,
    setPage,
    ...tableProps
  } = useFmTransactionsTable();

  const dataFiltered = useMemo(() => {
    return fmApplySortFilter({
      dataFiltered: transactions ?? [],
      comparator: tableProps.comparator,
      filterTerms: filterTerms,
      reportingYear,
      locale,
    });
  }, [transactions, tableProps.comparator, filterTerms, reportingYear, locale]);

  const TABS = useMemo(() => {
    return [
      {
        value: TransactionStatusValues.ALL,
        label: t(`transaction-status.${TransactionStatusValues.ALL}`),
        color: TransactionStatusColors.ALL,
        count: dataFiltered?.length ?? 0,
      },
    ];
  }, [dataFiltered, t]);

  return (
    <Container sx={{ maxWidth: 1400 }}>
      <HeaderBreadcrumbs
        heading={t('receipts.main')}
        links={[
          {
            name: t('receipts.main'),
            href: fmAppMainPages.TRANSACTIONS,
          },
          { name: t('common.overview') },
        ]}
        sx={{
          pb: 0,
        }}
      />

      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={TransactionStatusValues.ALL}
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              label={
                <Stack spacing={1} direction="row" alignItems="center">
                  <div>{tab.label}</div>
                  <NumberLabel color={tab.color} variant="ghost">
                    {tab.count}
                  </NumberLabel>
                </Stack>
              }
            />
          ))}
          <Stack
            sx={{
              flex: 1,
            }}
          />
          <Stack
            sx={{
              justifyContent: 'center',
            }}
          >
            <YearNavigationBlock
              reportingYear={reportingYear}
              setReportingYear={setReportingYear}
            />
          </Stack>
        </Tabs>

        <Divider />
        <FmTransactionsToolbar
          filterTerms={filterTerms}
          onFilterTerms={setFilterTerms}
        />

        <FmTransactionsTable
          dataAvailableBeforeFilter={Boolean((transactions ?? []).length > 0)}
          transactions={dataFiltered}
          isError={isError}
          isLoading={isLoading}
          isFiltered={!!filterTerms}
          order={order}
          orderBy={orderBy}
          setPage={setPage}
          {...tableProps}
        />
      </Card>
    </Container>
  );
};
