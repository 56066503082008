import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import {
  Box,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { Iconify, MenuPopover, NameAvatar } from '@pflegenavi/web-components';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { useInitializeFM } from '../../hooks/useInitializeFM';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface SelectResidentProps {}

const ITEM_HEIGHT = 65;

export const SelectResident: FC<SelectResidentProps> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<HTMLElement | null>(null);

  const { initResident, selectedResidentId } = useFMContext();
  const { selectedResident, residents } = useInitializeFM();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleSelectedResident = (id: string) => {
    initResident(id);
    handleClose();
  };

  const residentsToList = useMemo(() => {
    const residentSelected = residents?.find(
      (resident) => resident.residentId === selectedResidentId
    );
    const otherResidents = residents?.filter(
      (resident) => resident.residentId !== selectedResidentId
    );
    if (residentSelected && otherResidents) {
      return [residentSelected, ...otherResidents];
    }
    return residents;
  }, [residents, selectedResidentId]);

  const SCROLLBAR_HEIGHT = useMemo(() => {
    const defaultHeight = 3 * ITEM_HEIGHT;
    if (residents) {
      const nbrOfItems = residents.length;
      return nbrOfItems > 3 ? defaultHeight : nbrOfItems * ITEM_HEIGHT;
    }
    return defaultHeight;
  }, [residents]);

  if (!residentsToList || !selectedResident) {
    return null;
  }

  return (
    <>
      <SelectionButton direction="row" onClick={handleOpen} spacing={2}>
        <Iconify
          icon={'eva:person-fill'}
          width={20}
          height={20}
          sx={{ mr: 2 }}
        />
        {`${selectedResident.firstName} ${selectedResident.lastName}`}
      </SelectionButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        arrow="top-left"
        sx={{
          mt: 0.5,
          ml: 6,
          width: 320,
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px',
          pb: 2,
          paddingX: 1.2,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 1.5,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          {t('fm-web-navigation.resident-selection')}
        </Typography>

        <Box style={{ overflowY: 'auto' }} sx={{ height: SCROLLBAR_HEIGHT }}>
          {residentsToList.map((resident) => (
            <MenuItem
              key={resident.residentId}
              onClick={() => handleSelectedResident(resident.residentId)}
              sx={{
                bgcolor:
                  selectedResidentId === resident.residentId ? 'grey.200' : '',
              }}
            >
              <ListItemAvatar sx={{ position: 'relative' }}>
                <NameAvatar
                  name={`${resident.firstName} ${resident.lastName}`}
                  gender={resident.gender}
                />
              </ListItemAvatar>

              <ListItemText
                primaryTypographyProps={{
                  typography: 'body1',
                  color: 'primary.main',
                }}
                secondaryTypographyProps={{ typography: 'caption' }}
                primary={`${resident.firstName} ${resident.lastName}`}
                secondary={
                  selectedResidentId === resident.residentId
                    ? t('common.current-selection')
                    : null
                }
              />
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
};

const SelectionButton = styled(Stack)(
  sx({
    mb: 5,
    cursor: 'pointer',
    bgcolor: 'secondary.light',
    pt: 1.6,
    pb: 1.4,
    pl: 2,
    pr: 2,
    borderRadius: 1.5,
  })
);
