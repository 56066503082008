import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  styled,
} from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { HeaderBreadcrumbs } from '@pflegenavi/web-components';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { SetupDirectDebit } from '../components/directDebit/SetupDirectDebit';
import { DirectDebitStatusCard } from '../components/directDebit/DirectDebitStatusCard';
import { CancelDirectDebit } from '../components/directDebit/CancelDirectDebit';
import {
  usePaymentInfoByResidentIdAsFamilyMember,
  useResidentSummary,
} from '@pflegenavi/frontend/api-nursing-home';
import {
  DirectDebitStatus,
  PaymentInitiatedBy,
  PendingChargePaymentStatus,
  ResidentState,
} from '@pflegenavi/shared/api';
import { experimental_sx as sx } from '@mui/system';
import { DirectDebitInfoBox } from '../components/directDebit/DirectDebitInfoBox';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { DirectDebitNonActiveCases } from '../components/directDebit/DirectDebitNonActiveCases';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DirectDebitPageProps {}

// eslint-disable-next-line complexity
export const DirectDebitPage: FC<DirectDebitPageProps> = () => {
  const { t } = useTranslation();
  const { selectedResidentId } = useFMContext();

  const { data: residentSummary, isLoading: isSummaryLoading } =
    useResidentSummary(selectedResidentId ?? '');

  const isResidentActive =
    residentSummary?.resident?.state === ResidentState.active;

  const { data: paymentInfo, isLoading: paymentInfoLoading } =
    usePaymentInfoByResidentIdAsFamilyMember(selectedResidentId ?? '');

  const directDebit = useMemo(() => {
    if (paymentInfoLoading) {
      return undefined;
    }
    if (paymentInfo?.initiatedBy === PaymentInitiatedBy.System) {
      return paymentInfo?.directDebit;
    }
    return DirectDebitStatus.Inactive;
  }, [paymentInfo, paymentInfoLoading]);

  const status = directDebit ?? DirectDebitStatus.Inactive;

  const paymentMethod = useMemo(() => {
    if (paymentInfo?.initiatedBy === PaymentInitiatedBy.System) {
      return paymentInfo?.paymentMethod;
    }
    return undefined;
  }, [paymentInfo]);

  const pendingPayment =
    paymentInfo?.status.status === PendingChargePaymentStatus.Pending;

  const waitForPendingPaymentResult = paymentInfoLoading && !isResidentActive;

  const isPaymentDisabled =
    paymentInfo?.status.status === PendingChargePaymentStatus.Disabled;

  return (
    <Container sx={{ maxWidth: 1400 }}>
      <HeaderBreadcrumbs
        heading={t('fm-web-navigation.direct-debit')}
        links={[
          {
            name: t('fm-web-navigation.direct-debit'),
            href: fmAppMainPages.DIRECT_DEBIT,
          },
          { name: t('common.overview') },
        ]}
        sx={{
          pb: 0,
        }}
      />
      {(!directDebit ||
        paymentInfoLoading ||
        isSummaryLoading ||
        waitForPendingPaymentResult) && (
        <LoadingContainer alignItems="center">
          <CircularProgress variant="indeterminate" />
        </LoadingContainer>
      )}
      {directDebit && residentSummary && (
        <span>
          {!isResidentActive || isPaymentDisabled ? (
            <DirectDebitNonActiveCases
              residentState={residentSummary.resident?.state}
              nursingHomePhone={
                residentSummary.resident?.nursingHomePhone ?? ''
              }
              pendingPayment={pendingPayment}
              paymentDisabled={isPaymentDisabled}
            />
          ) : (
            <>
              <DirectDebitInfoBox status={status} />
              <Grid
                container
                direction="row"
                alignItems="flex-start"
                spacing={3}
              >
                <Grid item xs={12} md={7} lg={8}>
                  <SetupDirectDebit
                    status={status}
                    paymentMethod={paymentMethod}
                  />
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                  <DirectDebitStatusCard status={status} />
                  <CancelDirectDebit status={status} />
                </Grid>
              </Grid>
            </>
          )}
        </span>
      )}
    </Container>
  );
};

const LoadingContainer = styled(Stack)(
  sx({
    mt: 12,
  })
);
