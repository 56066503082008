import type { FC } from 'react';
import * as React from 'react';

import { Card, Stack, styled, TextField } from '@mui/material';

import { experimental_sx as sx } from '@mui/system';
import { useTranslation } from 'react-i18next';

interface FamilyMemberProfileInfoProps {
  firstName: string;
  lastName: string;
  email: string;
}

export const FamilyMemberProfileInfo: FC<FamilyMemberProfileInfoProps> = ({
  firstName,
  lastName,
  email,
}) => {
  const { t } = useTranslation();

  return (
    <ContainerCard>
      <Stack direction="row" gap={2} sx={{ mb: 2 }}>
        <TextField
          value={firstName}
          id="firstName"
          name="firstName"
          disabled
          label={t('resident.form.firstName')}
          placeholder={t('resident.form.firstName')}
          fullWidth
        />
        <TextField
          value={lastName}
          id="lastName"
          name="lastName"
          disabled
          label={t('resident.form.lastName')}
          placeholder={t('resident.form.lastName')}
          fullWidth
        />
      </Stack>
      <Stack direction="row" gap={2} sx={{ mb: 2 }}>
        <TextField
          value={email}
          id="eMail"
          disabled
          name="eMail"
          label={t('mobile.family-member.email-field')}
          type="email"
          placeholder={t('mobile.family-member.email-field')}
          fullWidth
        />
        <TextField
          sx={{
            visibility: 'hidden',
          }}
          fullWidth
        />
      </Stack>
    </ContainerCard>
  );
};

const ContainerCard = styled(Card)(
  sx({
    flex: 1,
    p: 3,
  })
);
