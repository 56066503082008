import React, { useMemo } from 'react';

import type { LabelColor } from '@pflegenavi/web-components';
import {
  Label,
  TableCell,
  TableRow,
  Typography,
} from '@pflegenavi/web-components';

import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { TransactionSourceType, TransactionType } from '@pflegenavi/shared/api';
import { Stack, useTheme } from '@mui/material';
import { useFormatting, useLocale } from '@pflegenavi/frontend/localization';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import { TransactionTypeLogo } from '../general/TransactionTypeLogo';
import { IconTooltip } from '@pflegenavi/transaction-components';
import { getTransactionSourceIcon } from '../../helpers/getTransactionSourceIcon';
import { useTranslation } from 'react-i18next';

interface Props {
  row: GetTransactionsWithReceiptDto;
  handleShowReceiptImage: () => void;
}

export function FmTransactionTableRow({
  row,
  handleShowReceiptImage,
}: Props): JSX.Element {
  const { t } = useTranslation();
  const locale = useLocale();
  const theme = useTheme();
  const { fCurrency } = useFormatting();
  const {
    receiptType,
    serviceProvider,
    amount,
    date,
    receiptImageIds,
    type,
    recurringItem,
    sourceType,
    cancelled,
    sequenceID,
  } = row;

  // eslint-disable-next-line complexity
  const renderReceiptIcon = useMemo(() => {
    if (
      receiptImageIds &&
      receiptImageIds.length > 0 &&
      !cancelled &&
      sourceType !== TransactionSourceType.CancelledReceipt
    ) {
      return (
        <IconTooltip
          title={t('tooltip.show-receipt-image')}
          color={theme.palette.success.main}
          icon="check"
          onClick={handleShowReceiptImage}
        />
      );
    }

    if (
      type === TransactionType.DEPOSIT &&
      sourceType !== TransactionSourceType.CancelledReceipt
    ) {
      return (
        <IconTooltip
          title={t('tooltip.no-receipt-for-deposit')}
          color={theme.palette.grey['400']}
          icon="remove"
        />
      );
    }

    return (
      <IconTooltip
        title={t('tooltip.no-image-available')}
        color={theme.palette.grey['400']}
        icon="remove"
      />
    );
  }, [
    t,
    cancelled,
    handleShowReceiptImage,
    receiptImageIds,
    theme.palette.grey,
    theme.palette.success.main,
    type,
    sourceType,
  ]);

  const category = useMemo(() => {
    if (recurringItem) {
      return recurringItem.name;
    }
    return t(`receiptType.${receiptType ?? 'Andere'}`);
  }, [receiptType, recurringItem, t]);

  const transactionType = useMemo(() => {
    if (cancelled) {
      return t('transaction-type.cancelled');
    }

    if (sourceType === TransactionSourceType.CancelledReceipt) {
      return t('transaction-type.refund');
    }
    if (sourceType === TransactionSourceType.RecurringItem) {
      return t('transaction-type.recurring-item');
    }

    return t('receipts.standard-receipt');
  }, [cancelled, sourceType, t]);

  const { subIcon, color } = useMemo(() => {
    const { icon, color } = getTransactionSourceIcon({ sourceType, cancelled });
    return {
      subIcon: icon,
      // this is a string reference to the color returned by getTransactionSourceIcon, eg : "error", "info"
      color,
    };
  }, [cancelled, sourceType]);

  let serviceProviderString = serviceProvider || t('receiptType.Andere');
  serviceProviderString =
    typeof serviceProvider === 'string' ? serviceProvider : '--';

  return (
    <TableRow hover>
      <TableCell
        align="left"
        sx={{
          maxWidth: 182,
          color: 'text.secondary',
          fontSize: 13,
        }}
      >
        {sequenceID ?? '--'}
      </TableCell>
      <TableCell align="left">
        <Stack direction="row" alignItems="center">
          <TransactionTypeLogo
            size={40}
            logoType={receiptType}
            additionalSubIcon={subIcon}
          />
          <Typography
            variant="subtitle2"
            sx={{ ml: 2, fontWeight: 600 }}
            noWrap
          >
            {category}
          </Typography>
        </Stack>
      </TableCell>

      <TableCell
        align="left"
        sx={{
          maxWidth: 270,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
      >
        {serviceProviderString}
      </TableCell>

      <TableCell align="left"> {transactionType} </TableCell>

      <TableCell align="right">
        <Label
          variant="ghost"
          color={color.length > 0 ? (color as LabelColor) : 'default'}
        >
          {fCurrency(amount)}
        </Label>
      </TableCell>
      <TableCell align="left"> </TableCell>
      <TableCell align="left">
        {date.toLocaleDateString(locale, YEAR_MONTH_DAY_SHORT_FORMAT)}
      </TableCell>
      <TableCell
        align="center"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {renderReceiptIcon}
      </TableCell>
    </TableRow>
  );
}
