import { useCallback } from 'react';
import {
  TransactionInitiatedBy,
  TransactionSourceType,
} from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePaymentTypeStringFromSourceType = () => {
  const { t } = useTranslation();
  const getPaymentTypeString = useCallback(
    // eslint-disable-next-line complexity
    ({
      sourceType,
      initiatedBy,
    }: {
      sourceType: TransactionSourceType;
      initiatedBy: TransactionInitiatedBy;
    }) => {
      switch (sourceType) {
        case TransactionSourceType.InitialDeposit:
          return t('mobile.family-member.initialAccountBalance');
        case TransactionSourceType.ManualDeposit:
          return t('mobile.family-member.manualTopup');
        case TransactionSourceType.CancelledManualDeposit:
          return t('mobile.family-member.manualTopup');
        case TransactionSourceType.DirectDebit:
          if (initiatedBy === TransactionInitiatedBy.FamilyMember) {
            return t('mobile.family-member.manualDirectDebitType');
          }
          return t('mobile.family-member.directDebitType');
        case TransactionSourceType.Dispute:
          if (initiatedBy === TransactionInitiatedBy.FamilyMember) {
            return t('mobile.family-member.manualDirectDebitType');
          }
          return t('mobile.family-member.directDebitType');
        case TransactionSourceType.FinalSettlement:
          return t('mobile.family-member.finalSettlementType');
        default:
          return t('mobile.family-member.directDebitType');
      }
    },
    [t]
  );

  const getAdditionalPaymentTypeString = useCallback(
    ({
      sourceType,
      disputed,
      cancelled,
    }: {
      sourceType: TransactionSourceType;
      disputed: boolean | undefined;
      cancelled: boolean | undefined;
    }) => {
      if (disputed) {
        return ` (${t('transaction-type.disputed')})`;
      }
      if (cancelled) {
        return ` (${t('transaction-type.cancelled')})`;
      }
      if (
        sourceType === TransactionSourceType.Dispute ||
        sourceType === TransactionSourceType.CancelledManualDeposit
      ) {
        return ` (${t('transaction-type.reversed')})`;
      }
      return undefined;
    },
    [t]
  );

  return {
    getPaymentTypeString,
    getAdditionalPaymentTypeString,
  };
};
