import type { FC } from 'react';
import React, { useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell as TableCellOriginal,
  TableContainer as TableContainerOriginal,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import type { HeadCell } from '@pflegenavi/web-components';
import {
  Iconify,
  TableEmptyRows,
  TableFillerTextStyled,
  TableNoData,
  TableSkeleton,
  useFileUrlToTypeMapping,
  OneLineTypography,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import { useFormatting, useLocale } from '@pflegenavi/frontend/localization';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { TransactionSourceType, TransactionType } from '@pflegenavi/shared/api';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import {
  EnlargeImageGallery,
  IconTooltip,
} from '@pflegenavi/transaction-components';
import { TransactionTypeLogo } from '../general/TransactionTypeLogo';
import { experimental_sx as sx } from '@mui/system';

import NoDataImage from '../../assets/undraw_no_records.svg';
import { useNavigate } from 'react-router-dom';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { getTransactionSourceIcon } from '../../helpers/getTransactionSourceIcon';
import { useUrlsToImages } from '@pflegenavi/shared-frontend/platform';
import { useReceiptImages } from '@pflegenavi/frontend/api-nursing-home';

// ----------------------------------------------------------------------

enum LatestTransactionsColumns {
  type = 'type',
  date = 'date',
  receipt = 'receipt',
  amount = 'amount',
}

interface LatestFMTransactionsProps {
  isLoading: boolean;
  data: GetTransactionsWithReceiptDto[];
}

// eslint-disable-next-line complexity
export const LatestFMTransactions: FC<LatestFMTransactionsProps> = ({
  isLoading,
  data,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [receiptImageIds, setReceiptImageIds] = useState<string[] | undefined>(
    undefined
  );

  const { receiptImages: receiptImageUrls, ImageUrlsLoading } = useUrlsToImages(
    {
      receiptImageIds,
      useReceiptImages,
    }
  );

  const imageUrlToTypeMap = useFileUrlToTypeMapping(receiptImageUrls);

  const TABLE_HEAD = useMemo<Array<HeadCell<LatestTransactionsColumns>>>(
    () => [
      {
        id: LatestTransactionsColumns.type,
        label: t('common.type'),
        align: 'left',
      },
      {
        id: LatestTransactionsColumns.date,
        label: t('common.date'),
        align: 'left',
      },
      {
        id: LatestTransactionsColumns.receipt,
        label: t('common.receipt'),
        align: 'center',
      },
      {
        id: LatestTransactionsColumns.amount,
        label: t('common.amount'),
        align: 'right',
      },
    ],
    [t]
  );

  return (
    <Card sx={{ minHeight: '490px', position: 'relative' }} raised>
      <CardHeader
        title={t('mobile.family-member.latest-transactions')}
        sx={{ mb: 3 }}
        titleTypographyProps={{
          sx: { fontWeight: 600 },
        }}
      />
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {TABLE_HEAD.map((head) => (
                <ThCell key={head.id} align={head.align}>
                  {head.label}
                </ThCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {isLoading &&
              Array.from(Array(tableMaxRows).keys()).map((row: number) => (
                <TableSkeleton
                  key={`row- ${row}`}
                  colSpan={TABLE_HEAD.length}
                />
              ))}

            {!isLoading && data?.length === 0 && (
              <TableNoData colSpan={TABLE_HEAD.length} isNotFound>
                <img
                  alt="empty content"
                  src={NoDataImage}
                  style={{ marginBottom: 25 }}
                  height={180}
                  width={220}
                />
                <TableFillerTextStyled>
                  {t('table.no-data')}
                </TableFillerTextStyled>
              </TableNoData>
            )}

            {!isLoading &&
              data.map((transaction) => {
                return (
                  <LatestFMTransactionRow
                    key={transaction.id}
                    transaction={transaction}
                    handleShowReceiptImage={() => {
                      if (
                        transaction?.receiptImageIds &&
                        transaction.receiptImageIds.length > 0
                      ) {
                        setReceiptImageIds(transaction.receiptImageIds);
                      }
                    }}
                  />
                );
              })}

            {!isLoading && (
              <EnlargeImageGallery
                loading={ImageUrlsLoading}
                open={Boolean(receiptImageIds)}
                handleClose={() => setReceiptImageIds(undefined)}
                initialSelectedIndex={0}
                imageUrls={receiptImageUrls || []}
                imageUrlToTypeMap={imageUrlToTypeMap}
              />
            )}
            {data && data.length > 0 && (
              <TableEmptyRows
                height={68}
                emptyRows={tableMaxRows - data.length}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {data?.length > 0 && (
        <Box
          sx={{
            p: 1,
            textAlign: 'right',
          }}
        >
          <Button
            size="small"
            color="inherit"
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
            onClick={() => navigate(fmAppMainPages.TRANSACTIONS)}
          >
            {t('dashboard.label.viewAll')}
          </Button>
        </Box>
      )}
    </Card>
  );
};

interface TransactionRow {
  transaction: GetTransactionsWithReceiptDto;
  handleShowReceiptImage: () => void;
}

const LatestFMTransactionRow: FC<TransactionRow> = ({
  transaction,
  handleShowReceiptImage,
}) => {
  const theme = useTheme();
  const { fCurrency } = useFormatting();
  const locale = useLocale();
  const { t } = useTranslation();

  // eslint-disable-next-line complexity
  const renderReceiptIcon = useMemo(() => {
    if (
      transaction.receiptImageIds &&
      transaction.receiptImageIds.length > 0 &&
      !transaction.cancelled &&
      transaction.sourceType !== TransactionSourceType.CancelledReceipt
    ) {
      return (
        <IconTooltip
          title={t('tooltip.show-receipt-image')}
          color={theme.palette.success.main}
          icon="check"
          onClick={handleShowReceiptImage}
        />
      );
    }

    if (
      transaction.type === TransactionType.DEPOSIT &&
      transaction.sourceType !== TransactionSourceType.CancelledReceipt
    ) {
      return (
        <IconTooltip
          title={t('tooltip.no-receipt-for-deposit')}
          color={theme.palette.grey['400']}
          icon="remove"
        />
      );
    }

    return (
      <IconTooltip
        title={t('tooltip.no-image-available')}
        color={theme.palette.grey['400']}
        icon="remove"
      />
    );
  }, [
    transaction.cancelled,
    handleShowReceiptImage,
    t,
    theme.palette.grey,
    theme.palette.success.main,
    transaction.receiptImageIds,
    transaction.sourceType,
    transaction.type,
  ]);

  const transactionType = useMemo(() => {
    if (transaction.recurringItem) {
      return t('transaction-type.recurring-item');
    }
    return t(`receiptType.${transaction.receiptType ?? 'Andere'}`);
  }, [t, transaction.receiptType, transaction.recurringItem]);

  const { subIcon } = useMemo(() => {
    const { icon } = getTransactionSourceIcon({
      sourceType: transaction?.sourceType,
      cancelled: transaction?.cancelled,
    });
    return {
      subIcon: icon,
    };
  }, [transaction.cancelled, transaction.sourceType]);

  const amountColor = useMemo(() => {
    if (transaction?.cancelled) {
      return theme.palette.error.main;
    } else if (
      transaction.sourceType === TransactionSourceType.CancelledReceipt
    ) {
      return theme.palette.success.main;
    }
    return theme.palette.primary.main;
  }, [
    theme.palette.error.main,
    theme.palette.primary.main,
    theme.palette.success.main,
    transaction?.cancelled,
    transaction.sourceType,
  ]);

  return (
    <TableRow
      hover
      sx={{
        borderBottomStyle: 'solid',
        borderBottomColor: theme.palette.grey[500_24],
        borderBottomWidth: 0,
        cursor: 'pointer',
      }}
    >
      <TableCell align="left">
        <Stack direction="row" alignItems="center">
          <TransactionTypeLogo
            logoType={transaction.receiptType}
            additionalSubIcon={subIcon}
          />
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{ ml: 1.5 }}
          >
            <OneLineTypography
              variant="body1"
              sx={{
                fontWeight: 600,
                lineHeight: 1.2,
                maxWidth: 230,
              }}
            >
              {transaction.recurringItem
                ? transaction.recurringItem.name
                : transactionType}
            </OneLineTypography>
            <OneLineTypography
              variant="body2"
              sx={{ color: 'grey.500', maxWidth: 230 }}
              noWrap
            >
              {transaction.recurringItem
                ? transactionType
                : transaction.serviceProvider ?? '--'}
            </OneLineTypography>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle2">
          {new Date(transaction.date).toLocaleDateString(
            locale,
            YEAR_MONTH_DAY_SHORT_FORMAT
          )}
        </Typography>
      </TableCell>

      <TableCell
        align="center"
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        {renderReceiptIcon}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          color: amountColor,
        }}
      >
        {fCurrency(transaction.amount)}
      </TableCell>
    </TableRow>
  );
};

const tableMaxRows = 5;

const TableCell = styled(TableCellOriginal)`
  padding: 12px;
`;

const ThCell = styled(TableCell)(sx({ color: 'grey.600' }));

const TableContainer = styled(TableContainerOriginal)(
  sx({
    maxWidth: '96%',
    height: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  })
);
