import type { Dispatch, FC } from 'react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';

import { useImage } from '@pflegenavi/frontend/api-nursing-home';
import {
  endOfDay,
  endOfMonth,
  endOfYear,
  isBefore,
  isSameDay,
  max,
  startOfDay,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';

import {
  Iconify,
  Modal,
  ModalInner,
  useErrorSnackbar,
} from '@pflegenavi/web-components';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { pflegenaviBase64Logo } from './LogoBase64Encoded';
import { useNursingHomeContext } from '@pflegenavi/frontend/nursing-home-context';
import type { ReceiptAttachment } from '../utils/getImagesFromReceiptIds';
import { useReceiptImagesForPdf } from '../utils/getImagesFromReceiptIds';

import { prepareResidentOverviewData } from '../utils/prepareResidentOverviewData';
import { usePrintPdfForResident } from '../handlePrintPdfForResident';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { TransactionSourceType } from '@pflegenavi/shared/api';
import type { PdfResident } from '../interfaces/PdfResident';

const isValidDate = (d: any): d is Date => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return d instanceof Date && !isNaN(d);
};

interface DownloadPdfButtonProps {
  getTransactionsResident: (params: {
    dateFrom: Date;
    dateTo: Date;
  }) => Promise<GetTransactionsWithReceiptDto[]>;

  getResidentBalanceUntilDate(dataIn: {
    params: {
      residentId: string;
      date: Date;
    };
  }): Promise<{
    residentId: string;
    balance: number;
  }>;

  resident: PdfResident | undefined;
  isLoadingResident: boolean;
  showCancelledReceiptsCheckbox?: boolean;
  automaticTransactionNumberingEnabled: boolean;
}

export const DownloadPdfButton: FC<DownloadPdfButtonProps> = ({
  getResidentBalanceUntilDate,
  isLoadingResident,
  resident,
  getTransactionsResident,
  showCancelledReceiptsCheckbox,
  automaticTransactionNumberingEnabled,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="text"
        startIcon={<Iconify icon="ph:download-simple-bold" width={20} />}
      >
        {t('actions.download-pdf')}
      </Button>
      {!isLoadingResident && resident && (
        <ResidentPdfExportModal
          // Re-mount if resident changes
          key={resident.id}
          open={open}
          setOpen={setOpen}
          getTransactionsResident={getTransactionsResident}
          getResidentBalanceUntilDate={getResidentBalanceUntilDate}
          resident={resident}
          showCancelledReceiptsCheckbox={showCancelledReceiptsCheckbox}
          automaticTransactionNumberingEnabled={
            automaticTransactionNumberingEnabled
          }
        />
      )}
    </>
  );
};

interface ResidentPdfExportModalProps {
  open: boolean;
  setOpen: Dispatch<boolean>;
  getTransactionsResident: (params: {
    dateFrom: Date;
    dateTo: Date;
  }) => Promise<GetTransactionsWithReceiptDto[]>;

  getResidentBalanceUntilDate(dataIn: {
    params: {
      residentId: string;
      date: Date;
    };
  }): Promise<{
    residentId: string;
    balance: number;
  }>;

  showCancelledReceiptsCheckbox?: boolean;

  resident: PdfResident;
  automaticTransactionNumberingEnabled: boolean;
}

// eslint-disable-next-line complexity
const ResidentPdfExportModal: FC<ResidentPdfExportModalProps> = ({
  open,
  setOpen,
  getTransactionsResident,
  getResidentBalanceUntilDate,
  resident,
  showCancelledReceiptsCheckbox,
  automaticTransactionNumberingEnabled,
}) => {
  const { t } = useTranslation();
  const { enqueueErrorSnackbar } = useErrorSnackbar();

  const { getImagesFromReceiptIds, getPdfsFromReceiptIds } =
    useReceiptImagesForPdf();

  const [preparingPDF, setPreparingPDF] = useState(false);

  const { handlePrintPdfForResident } = usePrintPdfForResident(
    automaticTransactionNumberingEnabled
  );

  const initialStartDate = useMemo(() => {
    let startDate = startOfMonth(startOfDay(new Date()));
    if (
      resident.created_date &&
      startOfDay(resident.created_date) > startDate
    ) {
      startDate = startOfDay(resident.created_date);
    }
    return startDate;
  }, [resident.created_date]);

  // export options
  const [dateFrom, setDateFrom] = React.useState<Date | null>(initialStartDate);
  const [dateTo, setDateTo] = React.useState<Date | null>(endOfDay(new Date()));

  const { selectedNursingHome: nursingHome } = useNursingHomeContext();

  const { data: pdfLogo, isLoading: isImageLoading } = useImage(
    nursingHome?.pdfLogoId
  );
  const [includeReceiptImages, setIncludeReceiptImages] = useState(false);
  const [
    includeCancelledReceiptAttachments,
    setIncludeCancelledReceiptAttachments,
  ] = useState(false);

  // state for the receipts that are in pdf format
  const [pdfFiles, setPdfFiles] = useState<ReceiptAttachment[]>([]);

  // loading state to avoid flickering calculating pdf receipts
  const [receiptPdfsLoading, setReceiptPdfsLoading] = useState(false);

  const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
  const [relevantTransactions, setRelevantTransactions] = useState<
    GetTransactionsWithReceiptDto[]
  >([]);
  useEffect(() => {
    const abort = { current: false };
    setIsLoadingTransactions(true);
    getTransactionsResident({
      dateFrom: dateFrom ?? initialStartDate,
      dateTo: dateTo ?? endOfDay(new Date()),
    })
      .then((transactions) => {
        if (!abort.current) {
          setRelevantTransactions(transactions);
        }
      })
      .catch((err) => {
        enqueueErrorSnackbar(err, t('errors.something-went-wrong'));
      })
      .finally(() => {
        if (!abort.current) {
          setIsLoadingTransactions(false);
        }
      });

    return () => {
      // If the effect is re-computed, a new request is loading, so we don't want to set the loading state to false
      abort.current = true;
    };
  }, [
    enqueueErrorSnackbar,
    t,
    dateFrom,
    dateTo,
    getTransactionsResident,
    initialStartDate,
  ]);

  const residentOverviewData = useMemo(() => {
    return prepareResidentOverviewData(relevantTransactions);
  }, [relevantTransactions]);

  const hasCancelledReceipts = useMemo(() => {
    return residentOverviewData.withdrawals.some(
      (withdrawal) =>
        withdrawal.cancelled ||
        withdrawal.sourceType === TransactionSourceType.CancelledReceipt
    );
  }, [residentOverviewData.withdrawals]);

  // sync pdf file based on time frame selection
  useEffect(() => {
    (async () => {
      if (includeReceiptImages) {
        setReceiptPdfsLoading(true);
        const pdfs = await getPdfsFromReceiptIds(
          residentOverviewData.withdrawals
        );
        const pdfsWithoutCancelledAndCancellations = pdfs.filter(
          (pdf) =>
            !pdf.transactionData.cancelled && !pdf.transactionData.cancellation
        );
        setPdfFiles(
          includeCancelledReceiptAttachments
            ? pdfs
            : pdfsWithoutCancelledAndCancellations
        );
        setTimeout(() => {
          setReceiptPdfsLoading(false);
        }, 1000);
      } else {
        setPdfFiles([]);
      }
    })();
  }, [
    includeCancelledReceiptAttachments,
    dateFrom,
    dateTo,
    getPdfsFromReceiptIds,
    residentOverviewData,
    includeReceiptImages,
  ]);

  // eslint-disable-next-line complexity
  const handleClickExport = useCallback(async () => {
    try {
      setPreparingPDF(true);
      if (dateFrom && dateTo) {
        const {
          amountOfDepositsCents,
          numberOfDeposits,
          numberOfDepositCancellations,
          withdrawals,
          amountOfWithdrawalsCents,
          numberOfWithdrawals,
          numberOfWithdrawalCancellations,
        } = residentOverviewData;

        // Get Receipts images uris
        const receiptImages = includeReceiptImages
          ? await getImagesFromReceiptIds(withdrawals)
          : [];

        const receiptImagesWithoutCancelledAndCancellations =
          receiptImages.filter(
            (image) =>
              !image.transactionData.cancelled &&
              !image.transactionData.cancellation
          );

        const residentBalance = await getResidentBalanceUntilDate({
          params: {
            date: dateTo,
            residentId: resident.id,
          },
        });

        await handlePrintPdfForResident({
          dateFrom,
          dateTo,
          resident,
          amountOfDepositsCents,
          amountOfWithdrawalsCents,
          numberOfWithdrawals,
          numberOfDeposits,
          numberOfDepositCancellations,
          numberOfWithdrawalCancellations,
          receiptImages: includeCancelledReceiptAttachments
            ? receiptImages
            : receiptImagesWithoutCancelledAndCancellations,
          pdfFiles,
          balanceEndCents: residentBalance?.balance ?? 0,
          logo: pdfLogo ? pdfLogo.url : pflegenaviBase64Logo,
          allEntries: relevantTransactions,
        });
        setOpen(false);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      enqueueErrorSnackbar(e, t('errors.something-went-wrong'), {
        variant: 'error',
      });
    } finally {
      setPreparingPDF(false);
    }
  }, [
    relevantTransactions,
    includeCancelledReceiptAttachments,
    resident,
    dateFrom,
    dateTo,
    residentOverviewData,
    includeReceiptImages,
    getImagesFromReceiptIds,
    getResidentBalanceUntilDate,
    handlePrintPdfForResident,
    pdfFiles,
    pdfLogo,
    setOpen,
    enqueueErrorSnackbar,
    t,
  ]);

  const handleClickLastMonth = useCallback(() => {
    const startDates = [startOfMonth(subMonths(startOfDay(new Date()), 1))];
    if (resident.created_date) {
      startDates.push(resident.created_date);
    }

    setDateFrom(max(startDates));
    setDateTo(endOfMonth(subMonths(endOfDay(new Date()), 1)));
  }, [resident]);

  const lastMonthButtonActive = useMemo(() => {
    return (
      dateTo &&
      dateFrom &&
      isSameDay(dateTo, endOfMonth(subMonths(new Date(), 1))) &&
      isSameDay(dateFrom, startOfMonth(subMonths(new Date(), 1)))
    );
  }, [dateFrom, dateTo]);

  const handleClickLastYear = useCallback(() => {
    const startDates = [startOfYear(subYears(startOfDay(new Date()), 1))];
    if (resident.created_date) {
      startDates.push(resident.created_date);
    }

    setDateFrom(max(startDates));
    setDateTo(endOfYear(subYears(endOfDay(new Date()), 1)));
  }, [resident]);

  const lastYearButtonActive = useMemo(() => {
    return (
      dateTo &&
      dateFrom &&
      isSameDay(dateTo, endOfYear(subYears(new Date(), 1))) &&
      isSameDay(startOfYear(subYears(new Date(), 1)), dateFrom)
    );
  }, [dateFrom, dateTo]);

  const residentCreatedDateOlderThanCurrentMonth = useMemo(() => {
    if (resident) {
      return isBefore(resident.created_date, startOfMonth(new Date()));
    }
    return false;
  }, [resident]);

  const residentCreatedDateOlderThanCurrentYear = useMemo(() => {
    if (resident) {
      return isBefore(resident.created_date, startOfYear(new Date()));
    }
    return false;
  }, [resident]);

  // eslint-disable-next-line complexity
  const dateError = useMemo(() => {
    if (dateFrom && dateTo) {
      if (dateFrom >= dateTo) {
        return t('resident-pdf.modal.date-error');
      } else if (startOfDay(dateTo) > startOfDay(new Date())) {
        return t('resident-pdf.modal.date-in-future-error');
      }
    }
    if (dateFrom && dateFrom < startOfDay(resident.created_date)) {
      return t('resident-pdf.modal.date-in-before-resident-entry-error');
    }
    return undefined;
  }, [dateFrom, dateTo, resident, t]);

  return (
    <Modal open={open} handleClose={() => setOpen(false)}>
      <ModalInner>
        <Box>
          <Card sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight={700} sx={{ mb: 1 }}>
              {t('resident-pdf.modal.title')}
            </Typography>
            <Typography sx={{ mb: 1 }}>
              {t('resident-pdf.modal.please-select-range')}:
            </Typography>
            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <DatePicker
                label={t('resident-pdf.modal.from')}
                value={dateFrom}
                onChange={(newValue) => {
                  if (isValidDate(newValue)) {
                    setDateFrom(newValue ? startOfDay(newValue) : newValue);
                  } else {
                    setDateFrom(null);
                  }
                }}
                minDate={resident.created_date}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    label="From"
                    {...params}
                    helperText={dateError}
                    error={dateError ? true : false}
                  />
                )}
              />
              <DatePicker
                label={t('resident-pdf.modal.to')}
                value={dateTo}
                onChange={(newValue) => {
                  if (isValidDate(newValue)) {
                    setDateTo(endOfDay(newValue));
                  } else {
                    setDateTo(null);
                  }
                }}
                minDate={resident.created_date}
                maxDate={new Date()}
                renderInput={(params) => (
                  <TextField
                    placeholder="To"
                    {...params}
                    error={dateError ? true : false}
                  />
                )}
              />
            </Stack>

            <Stack
              direction="row"
              justifyContent="flex-start"
              gap={1}
              sx={{
                mt: 1,
                mb: 3,
              }}
            >
              <Button
                disabled={!residentCreatedDateOlderThanCurrentMonth}
                onClick={handleClickLastMonth}
                variant={lastMonthButtonActive ? 'contained' : 'outlined'}
                size="small"
              >
                {t('resident-pdf.modal.last-month')}
              </Button>
              <Button
                disabled={!residentCreatedDateOlderThanCurrentYear}
                onClick={handleClickLastYear}
                variant={lastYearButtonActive ? 'contained' : 'outlined'}
                size="small"
              >
                {t('resident-pdf.modal.last-year')}
              </Button>
            </Stack>
            <Typography>{t('resident-pdf.modal.receipt-options')}</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={includeReceiptImages}
                  onChange={(e, checked) => setIncludeReceiptImages(checked)}
                />
              }
              label={t('resident-pdf.modal.include-receipt-images')}
            />
            {includeReceiptImages &&
              hasCancelledReceipts &&
              showCancelledReceiptsCheckbox && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={includeCancelledReceiptAttachments}
                      onChange={(e, checked) =>
                        setIncludeCancelledReceiptAttachments(checked)
                      }
                    />
                  }
                  label={t(
                    'resident-pdf.modal.include-cancelled-receipt-attachments'
                  )}
                />
              )}

            <DownloadPdfReceipts
              pdfFiles={pdfFiles}
              loading={receiptPdfsLoading}
            />

            <Stack direction="row" justifyContent="flex-end" gap={1}>
              <Button
                variant="outlined"
                disabled={preparingPDF || receiptPdfsLoading}
                onClick={() => setOpen(false)}
              >
                {t('resident-pdf.modal.cancel')}
              </Button>

              <LoadingButton
                color="primary"
                variant="contained"
                loading={
                  preparingPDF ||
                  isLoadingTransactions ||
                  isImageLoading ||
                  receiptPdfsLoading
                }
                disabled={
                  preparingPDF || Boolean(dateError) || !dateFrom || !dateTo
                }
                onClick={handleClickExport}
              >
                {t('resident-pdf.modal.export')}
              </LoadingButton>
            </Stack>
          </Card>
        </Box>
      </ModalInner>
    </Modal>
  );
};

const DownloadPdfReceipts = ({
  pdfFiles,
  loading,
}: {
  pdfFiles: ReceiptAttachment[];
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  if (loading) {
    return <Skeleton height={50} />;
  }

  if (pdfFiles.length === 0) {
    return null;
  }

  const receiptCountMap = pdfFiles.reduce((acc, file) => {
    const count = acc.get(file.sequenceNumber) || 0;
    acc.set(file.sequenceNumber, count + 1);
    return acc;
  }, new Map<number, number>());

  return (
    <Stack sx={{ mt: 0.5 }}>
      <Typography variant="body1" sx={{ mb: 0.1 }}>
        {t('resident-pdf.modal.pdf-files')}:
      </Typography>

      {pdfFiles.map((file) => (
        <Stack direction="row" gap={1} key={file.image.uri}>
          <Typography variant="body2">
            {t('resident-pdf.modal.receipt-number', {
              number:
                (receiptCountMap.get(file.sequenceNumber) ?? 0) > 1
                  ? `${file.sequenceNumber}-${file.count}`
                  : `${file.sequenceNumber}`,
            })}
            :
          </Typography>

          <Typography variant="body2" fontWeight="bold">
            <a
              href={file.image.uri}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                color: theme.palette.primary.main,
              }}
            >
              {t('resident-pdf.modal.download-pdf')}
            </a>
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
