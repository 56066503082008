import React, { Suspense, useEffect, useState } from 'react';
import { PageNotFound, Text } from '@pflegenavi/web-components';
import {
  DevLangSwitcher,
  localeStorage,
} from '@pflegenavi/frontend/localization-web-app';
import { useAuthentication } from '@pflegenavi/frontend/authentication';
import { Box, CircularProgress, styled } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { DashboardLayout } from '@pflegenavi/frontend/layout';
import { DashboardPage } from '../pages/DashboardPage';
import { TransactionsPage } from '../pages/TransactionsPage';
import { PaymentsPage } from '../pages/PaymentsPage';
import { SelectResident } from '../components/sideBar/SelectResident';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { DirectDebitPage } from '../pages/DirectDebitPage';
import { ProfilePage } from '../pages/ProfilePage';
import { FamilyMemberProfileDetails } from '../components/profile/FamilyMemberProfileDetails';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import { useUpdateTenantId } from '@pflegenavi/frontend/tenant';
import * as Sentry from '@sentry/react';
import { useSyncLocaleWithServer } from '@pflegenavi/shared/frontend';
import {
  useFamilyMemberApi,
  useFamilyMemberProfile,
  useUserProfileApi,
} from '@pflegenavi/frontend/api-nursing-home';
import { renderSwitchLanguageMenuItem } from '../components/general/SwitchLanguageMenuItem';
import { getTokenPayloadFromToken } from '@pflegenavi/shared/utils';
import { SentryRoutes } from '../sentry';
import { useInitializeFM } from '../hooks/useInitializeFM';
import {
  Login,
  RedirectResetPassword,
} from '@pflegenavi/shared-web/authentication';
import { shouldUseNewLogin } from '@pflegenavi/shared-frontend/authentication';

const LoadResident = () => {
  const { t } = useTranslation();
  const {
    logout,
    user,
    // @ts-expect-error // getToken is hidden and only for internal use, but required here
    getToken,
  } = useAuthentication();
  const { initFamilyMember, initResident } = useFMContext();

  const familyMemberApi = useFamilyMemberApi();

  const { updateTenant } = useUpdateTenantId();

  const [loggingOut, setLoggingOut] = useState(false);

  // TODO: use api endpoint
  useEffect(() => {
    if (!user) {
      return;
    }

    const fetchData = async () => {
      try {
        const token = await getToken();
        const tokenPayload = getTokenPayloadFromToken(token);
        const familyMember = tokenPayload
          ? {
              email: tokenPayload.email,
              email_verified: tokenPayload.email_verified,
              family_name: tokenPayload.family_name,
              given_name: tokenPayload.given_name,
              name: tokenPayload.name,
              preferred_username: tokenPayload.preferred_username,
              sub: tokenPayload.sub,
            }
          : undefined;
        if (!familyMember) {
          return;
        }
        initFamilyMember(familyMember);
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    fetchData();
  }, [getToken, initFamilyMember, user]);

  useEffect(() => {
    // eslint-disable-next-line complexity
    const fetchTenant = async () => {
      if (!user || loggingOut) {
        return;
      }

      try {
        const tenants = await familyMemberApi.getFamilyMemberTenant();
        if (!tenants || tenants.length === 0) {
          setLoggingOut(true);
          return;
        }
        const storedResidentId = await localStorage.getItem(
          'selectedResidentId'
        );
        const storedResident = tenants.find(
          (tenant) => tenant.residentId === storedResidentId
        );
        if (storedResident) {
          updateTenant(storedResident.tenant as Tenant);
          initResident(storedResident.residentId);
        } else {
          updateTenant(tenants[0].tenant as Tenant);
          initResident(tenants[0].residentId);
        }
      } catch (err) {
        Sentry.captureException(err);
      }
    };
    fetchTenant();
  }, [
    loggingOut,
    user,
    familyMemberApi,
    updateTenant,
    t,
    initResident,
    logout,
  ]);

  useEffect(() => {
    if (!loggingOut) {
      return;
    }

    (async () => {
      alert(t('mobile.no-family-member-found.title'));
      await logout();
    })();
  }, [loggingOut, t, logout]);

  return (
    <LoadingContainer>
      <CircularProgress variant="indeterminate" />
    </LoadingContainer>
  );
};

// eslint-disable-next-line complexity
export default function App(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuthentication();
  const { selectedResidentId } = useFMContext();

  useSyncLocaleWithServer({ localeStorage });

  useSetupSentryScopeWithSelectedResident();

  useEffect(() => {
    // @ts-expect-error Beacon is untyped
    if (!window.Beacon) {
      return;
    }

    // @ts-expect-error Beacon is untyped
    window.Beacon('event', {
      type: 'page-viewed',
      url: location.pathname,
      title: document.title,
    });
    // @ts-expect-error Beacon is untyped
    window.Beacon('suggest');
    if (location && location.pathname === '/') {
      // @ts-expect-error Beacon is untyped
      window.Beacon('navigate', '/');
    }
  }, [location.pathname, location]);

  if (!user) {
    if (shouldUseNewLogin()) {
      return <Login />;
    }
  }

  // TODO: We might want to include the resident in the token and make
  // changing the resident an operation where the user receives a new token.
  if (!user || !selectedResidentId) {
    return <LoadResident />;
  }

  return (
    <Suspense
      fallback={
        <Text color="primary" variant="h2">
          {t('')}
        </Text>
      }
    >
      {import.meta.env.DEV && <DevLangSwitcher />}
      <ResidentNursingHomeHelpscoutSync />
      <SentryRoutes>
        <Route
          path={'/reset-password/:token'}
          element={<RedirectResetPassword />}
        />
        <Route
          path={fmAppMainPages.HOME}
          element={
            <DashboardLayout
              UnderLogoComponent={<SelectResident />}
              configKey="fm-web"
              home={fmAppMainPages.HOME}
              renderSwitchLanguageMenuItem={renderSwitchLanguageMenuItem}
            />
          }
        >
          <Route path={fmAppMainPages.DASHBOARD} element={<DashboardPage />} />
          <Route
            path={fmAppMainPages.TRANSACTIONS}
            element={<TransactionsPage />}
          />
          {/*<Route*/}
          {/*  path={fmAppMainPages.RECURRING_ITEMS}*/}
          {/*  element={<RecurringChargesPage />}*/}
          {/*/>*/}
          <Route path={fmAppMainPages.PAYMENTS} element={<PaymentsPage />} />
          <Route
            path={fmAppMainPages.DIRECT_DEBIT}
            element={<DirectDebitPage />}
          />

          <Route path={fmAppMainPages.PROFILE} element={<ProfilePage />}>
            <Route index={true} element={<FamilyMemberProfileDetails />} />
          </Route>

          <Route
            path={fmAppMainPages.HOME}
            element={<Navigate to={fmAppMainPages.DASHBOARD} replace />}
          />
          <Route
            path="*"
            element={
              <PageNotFound
                handleGoHomeClick={() => navigate(fmAppMainPages.HOME)}
              />
            }
          />
        </Route>
      </SentryRoutes>
    </Suspense>
  );
}

const useSetupSentryScopeWithSelectedResident = () => {
  const { selectedResidentId } = useFMContext();
  const { user } = useAuthentication();

  useEffect(() => {
    if (!selectedResidentId || !user) {
      return;
    }

    Sentry.configureScope((scope) => {
      scope.setUser(
        user
          ? {
              id: user?.userId,
            }
          : null
      );
      scope.setTags({
        residentId: selectedResidentId,
      });
    });
  }, [selectedResidentId, user]);
};

const ResidentNursingHomeHelpscoutSync = () => {
  const { selectedResident } = useInitializeFM();
  const { user } = useAuthentication();

  const api = useUserProfileApi(true);

  const { data: userProfile } = useFamilyMemberProfile({
    enabled: !!api,
    refetchOnWindowFocus: false,
  });

  // eslint-disable-next-line complexity
  useEffect(() => {
    // @ts-expect-error Beacon is untyped
    if (!window.Beacon || !selectedResident || !user || !userProfile) {
      return;
    }

    // @ts-expect-error Beacon is untyped
    window.Beacon('identify', {
      name: `${user.firstName} ${user.lastName}`,
      email: userProfile?.user.eMail,
      resident: selectedResident.residentId,
      tenant: 'family_member',
      'user-id': user.userId,
      environment: window.location.hostname.startsWith('localhost')
        ? 'local'
        : window.location.hostname.includes('.staging.')
        ? 'staging'
        : 'production',
      application: 'Family member web',
      'nursing-home-id': selectedResident.nursingHomeId,
      'nursing-home-name': selectedResident.nursingHomeName,
      'nursing-home-tenant': selectedResident.tenant,
    });
  }, [selectedResident, user, userProfile]);

  return null;
};

const LoadingContainer = styled(Box)(
  sx({
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  })
);
