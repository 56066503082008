import type { FC } from 'react';
import { useMemo } from 'react';
import { Card, Stack, styled, Typography } from '@mui/material';
import type { LabelColor } from '@pflegenavi/web-components';
import { Label } from '@pflegenavi/web-components';
import { DirectDebitStatus } from '@pflegenavi/shared/api';
import { experimental_sx as sx } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { renderDirectDebitStatus } from '../dashboard/ResidentCard';

interface DirectDebitStatusCardProps {
  status: DirectDebitStatus;
}

export const DirectDebitStatusCard: FC<DirectDebitStatusCardProps> = ({
  status,
}) => {
  const { t } = useTranslation();

  const labelColor: LabelColor = useMemo(() => {
    if (status === DirectDebitStatus.Active) {
      return 'success';
    }
    if (status === DirectDebitStatus.Pending) {
      return 'warning';
    }
    return 'error';
  }, [status]);

  return (
    <RootContainer>
      <InnerContainer direction="row" alignItems="center" spacing={2}>
        <Typography fontWeight={600}>
          {t('mobile.family-member.current-direct-debit-status')}
        </Typography>
        <Label
          variant="filled"
          color={labelColor}
          sx={{
            color:
              status === DirectDebitStatus.Pending ? 'primary.main' : 'white',
          }}
        >
          {t(renderDirectDebitStatus(status))}
        </Label>
      </InnerContainer>
    </RootContainer>
  );
};
const RootContainer = styled(Card)(
  sx({
    p: 1,
    width: '100%',
    borderRadius: 3,
    mb: 2,
  })
);

const InnerContainer = styled(Stack)(
  sx({
    bgcolor: 'grey.200',
    paddingX: 2,
    paddingY: 3,
    borderRadius: 3,
  })
);
