import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { TransactionSourceType } from '@pflegenavi/shared/api';
import { useTransactionApi } from '@pflegenavi/frontend/api-nursing-home';
import { useCallback } from 'react';
import { isDefined } from '@pflegenavi/shared/utils';
import * as Sentry from '@sentry/react';

export interface FileInfo {
  uri: string;
  data: Promise<Blob>;
  fileType: string | null;
  isPdf: boolean;
  isImage: boolean;
}

interface Transaction {
  id: string;
  amount: number;
  receiptImageIds?: string[];
  receiptDate?: Date;
  receiptType?: string;
  serviceProvider?: string;
  date: Date;
  sourceType: TransactionSourceType;
  cancelled: boolean;
  sequenceNumber: number;
}

export interface ReceiptAttachmentTransactionData {
  id: string;
  amount: number;
  receiptDate?: Date;
  receiptType?: string;
  serviceProvider?: string;
  cancelled: boolean; // Is this transaction itself cancelled?
  cancellation: boolean; // Is this transaction the cancellation of (=reversing) another transaction?
  cancellationDate?: Date;
}

export interface ReceiptAttachment {
  image: FileInfo;
  receiptNumber: number;
  count: number;
  sequenceNumber: number;
  receiptImagesLength: number;
  transactionData: ReceiptAttachmentTransactionData;
}

async function fetchFile(fileUrl: string): Promise<FileInfo> {
  const response = await fetch(fileUrl);
  const extractedFileType = response.headers.get('content-type');

  const isPdf = extractedFileType === 'application/pdf';
  const isImage = extractedFileType?.startsWith('image/') ?? false;

  if (!isPdf && !isImage) {
    // eslint-disable-next-line no-console
    console.warn(
      `Fetched file with content-type ${extractedFileType} is not supported: ${fileUrl}`
    );
    Sentry.captureMessage(
      `Fetched file with content-type ${extractedFileType} is not supported: ${fileUrl}`,
      'warning'
    );
  }

  return {
    uri: fileUrl,
    data: response.blob(),
    fileType: extractedFileType,
    isPdf: isPdf,
    isImage: isImage,
  };
}

export async function getImages(
  transactions: Transaction[],
  getUris: (
    imageIds: string[]
  ) => Promise<Record<string, string | undefined> | undefined>,
  type: 'pdf' | 'image',
  fetchFile: (fileUrl: string) => Promise<FileInfo>
): Promise<ReceiptAttachment[]> {
  // Retrieve all receipt ids
  const receiptIds = transactions
    .flatMap((transaction) => transaction.receiptImageIds)
    .filter(isDefined);

  // Get map between receipt id and receipt url
  const receiptImagesObject = await getUris(receiptIds);

  // Remove all entries from the map that are pdfs
  const asEntries = await Promise.all(
    Object.entries(receiptImagesObject ?? {}).map<
      Promise<[string, FileInfo | undefined]>
      // eslint-disable-next-line complexity
    >(async ([key, value]) => {
      if (!value) {
        return [key, undefined];
      }
      const fileInfo: FileInfo = await fetchFile(value);
      if (type === 'pdf' && fileInfo.isPdf) {
        return [key, fileInfo];
      } else if (type === 'image' && fileInfo.isImage) {
        return [key, fileInfo];
      } else {
        return [key, undefined];
      }
    })
  );

  // And recreate the map
  const receiptIdToFileInfo = Object.fromEntries(asEntries);

  // Compute final data
  return transactions.flatMap((transaction, index) => {
    const receiptNumber = index + 1;

    const fileInfos = (transaction.receiptImageIds ?? [])
      .map((receiptImageId) => {
        return receiptIdToFileInfo[receiptImageId];
      })
      .filter(isDefined);

    const receiptImagesLength = fileInfos.length ?? 0;
    return fileInfos.map((fileInfo, count) => {
      const isCancellation =
        transaction.sourceType === TransactionSourceType.CancelledReceipt;
      return {
        image: fileInfo,
        receiptNumber,
        count: count + 1,
        sequenceNumber: transaction.sequenceNumber,
        receiptImagesLength,
        transactionData: {
          id: transaction.id,
          amount: transaction.amount,
          receiptDate: transaction.receiptDate,
          serviceProvider: transaction.serviceProvider,
          receiptType: transaction.receiptType,
          cancelled: transaction.cancelled,
          cancellation: isCancellation,
          cancellationDate: isCancellation ? transaction.date : undefined,
        },
      };
    });
  });
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useReceiptImagesForPdf = () => {
  const api = useTransactionApi();

  const getImagesFromReceiptIds = useCallback(
    async (
      transactionsResident: Array<
        GetTransactionsWithReceiptDto & { sequenceNumber: number }
      >
    ) => {
      return await getImages(
        transactionsResident,
        api.getReceiptImageUrls.bind(api),
        'image',
        fetchFile
      );
    },
    [api]
  );

  const getPdfsFromReceiptIds = useCallback(
    async (
      transactionsResident: Array<
        GetTransactionsWithReceiptDto & { sequenceNumber: number }
      >
    ) => {
      return await getImages(
        transactionsResident,
        api.getReceiptImageUrls.bind(api),
        'pdf',
        fetchFile
      );
    },
    [api]
  );

  return {
    getImagesFromReceiptIds,
    getPdfsFromReceiptIds,
  };
};
