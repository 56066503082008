import type { FC } from 'react';
import {
  useChangeLocale,
  useLocale,
  useToggleLocale,
} from '@pflegenavi/frontend/localization';
import {
  useUpdateUserProfile,
  useUserProfileWithContext,
} from '@pflegenavi/frontend/api-nursing-home';
import { MenuItem } from '@mui/material';
import { useCallback } from 'react';
import { useFeatureFlag } from '@pflegenavi/frontend/feature-flags';
import { FeatureFlag, FeatureFlagStatus } from '@pflegenavi/shared/api';

export const renderSwitchLanguageMenuItem = ({
  handleClose,
}: {
  handleClose: () => void;
}): React.ReactElement => {
  return <SwitchLanguageMenuItem handleClose={handleClose} />;
};

const SwitchLanguageMenuItem: FC<{
  handleClose: () => void;
}> = ({ handleClose }) => {
  const locale = useLocale();
  const toggleLocale = useToggleLocale();
  const changeLocale = useChangeLocale();
  const { mutateAsync: updateLocale } = useUpdateUserProfile();

  const devToolsEnabled =
    useFeatureFlag(FeatureFlag.DevTools) === FeatureFlagStatus.Enabled;

  const { data: userProfile } = useUserProfileWithContext({
    refetchOnWindowFocus: false,
  });

  const switchLanguage = async () => {
    toggleLocale();
    await updateLocale({
      id: userProfile?.user.id ?? '',
      locale: locale === 'de-AT' ? 'en' : 'de',
    });
    handleClose();
  };

  const showTranslationKeys = useCallback(() => {
    changeLocale('cimode');
  }, [changeLocale]);

  const label =
    locale === 'de-AT' ? 'Zu Englisch wechseln' : 'Switch to German';

  if (devToolsEnabled) {
    return (
      <>
        <MenuItem onClick={switchLanguage}>{label}</MenuItem>
        <MenuItem onClick={showTranslationKeys}>Show translation keys</MenuItem>
      </>
    );
  }
  return <MenuItem onClick={switchLanguage}>{label}</MenuItem>;
};
