import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Iconify,
  InputAdornment,
  Stack,
  TextField,
} from '@pflegenavi/web-components';

interface FmTransactionsToolbarProps {
  filterTerms: string;
  onFilterTerms: (value: string) => void;
}

export const FmTransactionsToolbar: FC<FmTransactionsToolbarProps> = ({
  filterTerms,
  onFilterTerms,
}) => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" alignItems="center" sx={{ py: 2.5, px: 3, gap: 1 }}>
      <TextField
        sx={{ flex: 1 }}
        value={filterTerms}
        onChange={(event) => onFilterTerms(event.target.value)}
        placeholder={t('transactions.search')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify
                icon={'eva:search-fill'}
                sx={{ color: 'text.disabled', width: 20, height: 20 }}
              />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
