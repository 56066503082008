import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Skeleton,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import {
  Iconify,
  Label,
  TableFillerTextStyled,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import type {
  GetTransactionsWithReceiptDto,
  PaymentInfo,
  TransactionType,
} from '@pflegenavi/shared/api';
import {
  PaymentInitiatedBy,
  PendingChargePaymentStatus,
  TransactionSourceType,
} from '@pflegenavi/shared/api';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import { YEAR_MONTH_SHORTDAY_FORMAT } from '@pflegenavi/shared/constants';
import { useFormatting, useLocale } from '@pflegenavi/frontend/localization';

import NoDataImage from '../../assets/undraw_no_records.svg';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { useNavigate } from 'react-router-dom';
import { range } from 'lodash';

// ----------------------------------------------------------------------

interface LatestFMPaymentsProps {
  isLoading: boolean;
  data: GetTransactionsWithReceiptDto[];
  paymentInfo: PaymentInfo | undefined;
}

interface PaymentRowFields {
  id: string;
  type: TransactionType | undefined;
  amount: number;
  date: Date;
  pending: boolean;
  sourceType: TransactionSourceType;
  disputed: boolean;
  initiatedBy: 'familyMember' | string;
  cancelled?: boolean;
}

// eslint-disable-next-line complexity
export const LatestFMPayments: FC<LatestFMPaymentsProps> = ({
  isLoading,
  data,
  paymentInfo,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const totalPayments = useMemo(() => {
    let normalizedPayments: PaymentRowFields[] = data
      .map((payment) => ({
        id: payment.id,
        type: payment.type,
        amount: payment.amount,
        date: payment.date,
        pending: false,
        sourceType: payment.sourceType,
        disputed: payment.disputed,
        initiatedBy: payment.initiatedBy,
        cancelled: payment.cancelled,
      }))
      .sort((a, b) => {
        if (new Date(b.date) === new Date(a.date)) {
          return 0;
        }
        return new Date(b.date) > new Date(a.date) ? 1 : -1;
      });

    if (paymentInfo?.status?.status === PendingChargePaymentStatus.Pending) {
      const normalizedPendingPayment = {
        id: paymentInfo.status.resident.id,
        type: undefined,
        amount: paymentInfo.status.chargeAmount / 100,
        date: paymentInfo.status.chargeDate,
        pending: true,
        sourceType: TransactionSourceType.DirectDebit,
        disputed: false,
        initiatedBy: paymentInfo.initiatedBy,
      };
      normalizedPayments = [normalizedPendingPayment, ...normalizedPayments];
    }
    return normalizedPayments;
  }, [data, paymentInfo]);

  return (
    <PaymentsContainer raised>
      <CardHeader
        title={t('mobile.family-member.latest-payments')}
        sx={{ mb: 2 }}
        titleTypographyProps={{
          sx: { fontWeight: 600 },
        }}
      />

      <Timeline>
        {isLoading &&
          range(tableMaxRows).map((row: number, index) => (
            <PaymentRowSkeleton
              key={`row- ${row}`}
              isLast={index === tableMaxRows - 1}
            />
          ))}

        {!isLoading && data?.length === 0 && (
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              p: 3,
            }}
          >
            <img
              alt="empty content"
              src={NoDataImage}
              style={{ marginBottom: 25 }}
              height={160}
              width={200}
            />
            <TableFillerTextStyled>{t('table.no-data')}</TableFillerTextStyled>
          </Stack>
        )}

        {!isLoading &&
          totalPayments
            ?.slice(0, tableMaxRows)
            .map((payment, index) => (
              <PaymentRow
                key={payment.id}
                payment={payment}
                isLast={index === totalPayments?.length - 1}
              />
            ))}
      </Timeline>

      {data?.length > 0 && (
        <Box sx={{ p: 1, textAlign: 'right', mt: 'auto' }}>
          <Button
            size="small"
            color="inherit"
            endIcon={<Iconify icon={'eva:arrow-ios-forward-fill'} />}
            onClick={() => navigate(fmAppMainPages.PAYMENTS)}
          >
            {t('dashboard.label.viewAll')}
          </Button>
        </Box>
      )}
    </PaymentsContainer>
  );
};

interface PaymentRowProps {
  payment: PaymentRowFields;
  isLast: boolean;
}

const PaymentRow: FC<PaymentRowProps> = ({ payment, isLast }) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { fCurrency } = useFormatting();

  const {
    date,
    amount,
    pending,
    sourceType,
    disputed,
    initiatedBy,
    cancelled,
  } = payment;

  // eslint-disable-next-line complexity
  const paymentTypeText = useMemo(() => {
    switch (sourceType) {
      case TransactionSourceType.InitialDeposit:
        return t('mobile.family-member.initialAccountBalance');
      case TransactionSourceType.ManualDeposit:
        return t('mobile.family-member.manualTopup');
      case TransactionSourceType.CancelledManualDeposit:
        return t('mobile.family-member.manualTopup');
      case TransactionSourceType.DirectDebit:
        if (initiatedBy === PaymentInitiatedBy.FamilyMember) {
          return t('mobile.family-member.manualDirectDebitType');
        }
        return t('mobile.family-member.directDebitType');
      case TransactionSourceType.Dispute:
        if (initiatedBy === PaymentInitiatedBy.FamilyMember) {
          return t('mobile.family-member.manualDirectDebitType');
        }
        return t('mobile.family-member.directDebitType');
      case TransactionSourceType.FinalSettlement:
        return t('mobile.family-member.finalSettlementType');
    }
    return t('mobile.family-member.directDebitType');
  }, [initiatedBy, sourceType, t]);

  // eslint-disable-next-line complexity
  const rowColor = useMemo(() => {
    if (pending) {
      return 'warning';
    }
    if (disputed || cancelled) {
      return 'error';
    }
    if (
      sourceType === TransactionSourceType.Dispute ||
      sourceType === TransactionSourceType.CancelledManualDeposit
    ) {
      return 'info';
    }
    if (sourceType === TransactionSourceType.FinalSettlement) {
      if (amount < 0) {
        return 'error';
      } else {
        return 'success';
      }
    }
    if (sourceType === TransactionSourceType.InitialDeposit) {
      if (amount < 0) {
        return 'error';
      } else {
        return 'success';
      }
    }
    return 'success';
  }, [amount, cancelled, disputed, pending, sourceType]);

  // eslint-disable-next-line complexity
  const additionalPaymentText = useMemo(() => {
    if (pending) {
      return `(${t('mobile.family-member.pending')})`;
    }
    if (disputed) {
      return `(${t('transaction-type.disputed')})`;
    }
    if (cancelled) {
      return `(${t('transaction-type.cancelled')})`;
    }
    if (
      sourceType === TransactionSourceType.Dispute ||
      sourceType === TransactionSourceType.CancelledManualDeposit
    ) {
      return `(${t('transaction-type.reversed')})`;
    }

    return undefined;
  }, [cancelled, disputed, pending, sourceType, t]);

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color={rowColor} />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ pr: 0 }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack>
            <Stack direction="row" alignItems="center" gap={0.5}>
              <Typography variant="body1" sx={{ fontWeight: 600 }}>
                {paymentTypeText}
              </Typography>
              {additionalPaymentText && (
                <Typography variant="body1" sx={{ color: 'grey.500' }}>
                  {additionalPaymentText}
                </Typography>
              )}
            </Stack>

            <Typography
              variant="subtitle2"
              color="grey.500"
              sx={{ fontWeight: 500, fontSize: 14, lineHeight: 1.1 }}
            >
              {new Date(date).toLocaleDateString(
                locale,
                YEAR_MONTH_SHORTDAY_FORMAT
              )}
            </Typography>
          </Stack>
          <Label variant="ghost" color={rowColor} sx={{ mt: 0.4 }}>
            {fCurrency(amount)}
          </Label>
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

const PaymentRowSkeleton: FC<{ isLast: boolean }> = ({ isLast }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color="grey" />
        {isLast ? null : <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack>
            <Typography variant="body1">
              <Skeleton width={200} />
            </Typography>

            <Typography
              variant="subtitle2"
              color="grey.500"
              sx={{ lineHeight: 1.1 }}
            >
              <Skeleton />
            </Typography>
          </Stack>
          <Skeleton width={40} />
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

const tableMaxRows = 5;

const PaymentsContainer = styled(Card)(
  sx({
    maxHeight: '490px',
    '& .MuiTimelineItem-missingOppositeContent:before': {
      display: 'none',
    },
  })
);
