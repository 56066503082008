import type { FC } from 'react';
import React, { useState } from 'react';
import type { HeadCell, UseTableProps } from '@pflegenavi/web-components';
import {
  CustomTablePagination,
  emptyRows,
  Table,
  TableBody,
  TableContainer,
  TableEmptyRows,
  TableHeadCustom,
  TableSkeleton,
  useFileUrlToTypeMapping,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { FmTransactionColumn } from '../../hooks/useFmTransactionsTable';
import { FmTransactionTableRow } from './FmTransactionTableRow';
import { EnlargeImageGallery } from '@pflegenavi/transaction-components';
import { NoFmTransactionsData } from './NoFmTransactionsData';
import { useUrlsToImages } from '@pflegenavi/shared-frontend/platform';
import { useReceiptImages } from '@pflegenavi/frontend/api-nursing-home';
import { TransactionSourceType } from '@pflegenavi/shared/api';

interface FmTransactionsTableProps
  extends UseTableProps<FmTransactionColumn, GetTransactionsWithReceiptDto> {
  isLoading: boolean;
  transactions: GetTransactionsWithReceiptDto[] | undefined;
  isError: boolean;
  isFiltered: boolean;
  dataAvailableBeforeFilter: boolean;
}

// eslint-disable-next-line complexity
export const FmTransactionsTable: FC<FmTransactionsTableProps> = ({
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  order,
  orderBy,
  onSort,
  isLoading,
  isError,
  isFiltered,
  transactions,
  dataAvailableBeforeFilter,
}) => {
  const { t } = useTranslation();

  const isNotFound = !transactions?.length && !isFiltered;
  const isNotFoundOnSearch = !transactions?.length && isFiltered;

  const _transactionsOnPage = () =>
    transactions?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) ||
    [];

  const [receiptImageIds, setReceiptImageIds] = useState<string[] | undefined>(
    undefined
  );

  const { receiptImages: receiptImageUrls, ImageUrlsLoading } = useUrlsToImages(
    {
      receiptImageIds,
      useReceiptImages,
    }
  );

  const imageUrlToTypeMap = useFileUrlToTypeMapping(receiptImageUrls);

  const TABLE_HEAD: Array<HeadCell<FmTransactionColumn>> = [
    {
      id: FmTransactionColumn.SequenceID,
      label: 'ID',
      align: 'left',
    },
    {
      id: FmTransactionColumn.ReceiptType,
      label: t('receipts.type'),
      align: 'left',
    },
    {
      id: FmTransactionColumn.ServiceProvider,
      label: t('receipts.serviceProvider'),
      align: 'left',
    },
    {
      id: FmTransactionColumn.Type,
      label: t('common.type'),
      align: 'left',
    },
    {
      id: FmTransactionColumn.Amount,
      label: t('common.amount'),
      align: 'right',
      width: 2,
    },
    {
      id: FmTransactionColumn.Empty2,
      label: '',
      align: 'left',
    },
    {
      id: FmTransactionColumn.Date,
      label: t('common.date'),
      align: 'left',
    },
    {
      id: FmTransactionColumn.Receipt,
      label: t('resident.transactions.table.receipt'),
      align: 'center',
    },
  ];

  return (
    <>
      <TableContainer sx={{ position: 'relative' }}>
        <Table size="medium">
          <TableHeadCustom
            sx={{ width: 200 }}
            order={order}
            orderBy={orderBy}
            headLabels={TABLE_HEAD}
            rowCount={transactions?.length}
            onSort={onSort}
          />

          <TableBody>
            {isLoading &&
              Array.from(Array(rowsPerPage).keys()).map((row: number) => (
                <TableSkeleton
                  key={`row- ${row}`}
                  colSpan={TABLE_HEAD.length}
                />
              ))}
          </TableBody>

          <TableBody
            sx={{
              '& .MuiTableRow-root:last-child': {
                borderBottomWidth: 0,
              },
            }}
          >
            {_transactionsOnPage().map((transaction) => (
              <FmTransactionTableRow
                key={transaction.id}
                row={transaction}
                handleShowReceiptImage={() => {
                  if (
                    transaction?.receiptImageIds &&
                    transaction.receiptImageIds.length > 0 &&
                    !transaction.cancelled &&
                    transaction.sourceType !==
                      TransactionSourceType.CancelledReceipt
                  ) {
                    setReceiptImageIds(transaction.receiptImageIds);
                  }
                }}
              />
            ))}

            {!isLoading && (
              <EnlargeImageGallery
                loading={ImageUrlsLoading}
                open={Boolean(receiptImageIds)}
                handleClose={() => setReceiptImageIds(undefined)}
                initialSelectedIndex={0}
                imageUrls={receiptImageUrls || []}
                imageUrlToTypeMap={imageUrlToTypeMap}
              />
            )}

            {transactions && !isNotFoundOnSearch && !isNotFound && !isError && (
              <TableEmptyRows
                height={72}
                emptyRows={emptyRows(
                  page,
                  rowsPerPage,
                  transactions?.length || 0
                )}
              />
            )}

            {!isLoading && (
              <NoFmTransactionsData
                transactionsAvailableBeforeFilter={dataAvailableBeforeFilter}
                transactions={transactions}
                isError={isError}
                isFiltered={isFiltered}
                colSpan={TABLE_HEAD.length}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <CustomTablePagination
        page={page}
        onChangePage={onChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        items={transactions}
        tableKey="familyMemberTransactions"
      />
    </>
  );
};
