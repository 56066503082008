import { useMemo } from 'react';
import * as Yup from 'yup';

import type { ResidentBalanceInfo } from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import type { FamilyMemberResponse } from '@pflegenavi/frontend/family-member-context';
import { useSetupDirectDebitValidationSchema } from '@pflegenavi/shared/frontend';

export interface IManualPaymentFormValues {
  amount: number;
  balance: number;
  name: string;
  email: string;
  iban: string;
}

interface UseManualPaymentValidationSchemaProps {
  familyMember: FamilyMemberResponse | undefined;
  balanceInfo: ResidentBalanceInfo | undefined;
  residentBalance: number | undefined;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useManualPaymentValidationSchema = ({
  balanceInfo,
  familyMember,
  residentBalance,
}: UseManualPaymentValidationSchemaProps) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();

  const topUpAmount = balanceInfo?.residentConfiguration?.topUpAmount;

  const minAmount = balanceInfo
    ? balanceInfo.nursingHomeConfiguration.minPaymentAmount / 100
    : 50;
  const maxAmount = balanceInfo
    ? balanceInfo.nursingHomeConfiguration.maxPaymentAmount / 100
    : 500;
  const initialAmount =
    topUpAmount !== undefined && residentBalance !== undefined
      ? Math.min(
          maxAmount,
          Math.max(minAmount, topUpAmount - residentBalance / 100)
        )
      : minAmount;

  const initialValues: IManualPaymentFormValues = useMemo(() => {
    if (familyMember) {
      return {
        name: `${familyMember.given_name} ${familyMember.family_name}`,
        email: familyMember.email,
        amount: initialAmount,
        balance: residentBalance ? residentBalance / 100 : 0,
        iban: '',
      };
    }
    return {
      name: '',
      email: '',
      amount: initialAmount,
      balance: 0,
      iban: '',
    };
  }, [familyMember, initialAmount, residentBalance]);

  const validationSchema = Yup.object(
    useSetupDirectDebitValidationSchema()
  ).concat(
    Yup.object().shape({
      amount: Yup.number()
        .required()
        .min(
          minAmount,
          t('fm-triggered-payments.amount-screen.error-minimum-amount', {
            amount: fCurrency(minAmount),
          })
        )
        .max(
          maxAmount,
          t('fm-triggered-payments.amount-screen.error-maximum-amount', {
            amount: fCurrency(maxAmount),
          })
        ),
    })
  );

  return {
    initialValues,
    validationSchema,
  };
};
