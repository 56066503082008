import type { FC } from 'react';
import React, { useMemo } from 'react';
import {
  CircularProgress,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';

import { Button, Iconify, Label } from '@pflegenavi/web-components';

import Logo from '../../assets/pn-logo-with-name-colored.svg';
import { usePaymentInfoByResidentIdAsFamilyMember } from '@pflegenavi/frontend/api-nursing-home';
import type { IResidentFull, PaymentInfo } from '@pflegenavi/shared/api';
import {
  DirectDebitStatus,
  PaymentInitiatedBy,
  PendingChargePaymentStatus,
  ResidentState,
} from '@pflegenavi/shared/api';

import { useTranslation } from 'react-i18next';

import SetupDirectDebitImage from '../../assets/undraw-setup-up-direct-debit.svg';
import PaymentPendingImage from '../../assets/undraw-setup-up-direct-debit.svg';
import AllSetImage from '../../assets/undraw-setup-up-direct-debit.svg';
import SettlementImage from '../../assets/undraw_settlement.svg';
import SettlementDirectDebitImage from '../../assets/undraw_settlement_direct_debit.svg';
import PaymentFailedImage from '../../assets/undraw_payment_failed.svg';
import { useNavigate } from 'react-router-dom';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { InactiveDirectDebitInfo } from './InactiveDirectDebitInfo';
import { useUpcomingPaymentDays } from '@pflegenavi/shared/frontend';
import { SettlementDirectDebitActiveInfo } from './SettlementDirectDebitActiveInfo';
import { FirstSettlementInfo } from './FirstSettlementInfo';
import { FinalSettlementInfo } from './FinalSettlementInfo';
import { YEAR_MONTH_DAY_FORMAT } from '@pflegenavi/shared/constants';

export interface InfoBoxProps {
  resident: IResidentFull | undefined;
  isResidentLoading: boolean;
  handleManualPayment: () => void;
  paymentByFamilyMember: boolean;
}

export const InfoBox: FC<InfoBoxProps> = (props) => {
  const { data: paymentInfo, isLoading: isPaymentInfoLoading } =
    usePaymentInfoByResidentIdAsFamilyMember(props.resident?.id ?? '');

  return (
    <InfoBoxView
      {...props}
      paymentInfo={paymentInfo}
      isPaymentInfoLoading={isPaymentInfoLoading}
    />
  );
};

export interface InfoBoxViewProps extends InfoBoxProps {
  isPaymentInfoLoading: boolean;
  paymentInfo: PaymentInfo | undefined;
}

// eslint-disable-next-line complexity
export const InfoBoxView: FC<InfoBoxViewProps> = ({
  resident,
  isResidentLoading,
  handleManualPayment,
  paymentByFamilyMember,
  isPaymentInfoLoading,
  paymentInfo,
}) => {
  const directDebit = useMemo(() => {
    if (isPaymentInfoLoading) {
      return undefined;
    }
    if (paymentInfo?.initiatedBy === PaymentInitiatedBy.System) {
      return paymentInfo?.directDebit;
    }
    return DirectDebitStatus.Inactive;
  }, [isPaymentInfoLoading, paymentInfo]);

  const isResidentInactive = resident?.state === ResidentState.archived;
  const isResidentFirstSettlement = resident?.state === ResidentState.exited;
  const isResidentFinalSettlement =
    resident?.state === ResidentState.awaitingSettlement;

  const paymentStatus = paymentInfo?.status;

  const isPaymentPending =
    paymentStatus?.status === PendingChargePaymentStatus.Pending;

  const isPaymentFailed =
    paymentStatus?.status === PendingChargePaymentStatus.Failed ||
    paymentStatus?.status === PendingChargePaymentStatus.Frozen;
  const directDebitInactive = directDebit === DirectDebitStatus.Inactive;

  const isLoading = isResidentLoading || isPaymentInfoLoading;

  const isPaymentDisabled =
    paymentStatus?.status === PendingChargePaymentStatus.Disabled;

  const paymentsDisabledForNursingHome =
    paymentStatus?.status === PendingChargePaymentStatus.DisabledForNursingHome
      ? paymentStatus
      : undefined;

  const pendingPayment =
    paymentStatus?.status === PendingChargePaymentStatus.Pending
      ? paymentStatus
      : undefined;
  const upcomingPayment =
    paymentStatus?.status === PendingChargePaymentStatus.Upcoming
      ? paymentStatus
      : undefined;

  if (isLoading) {
    return <LoadingInfoBox />;
  }

  if (isPaymentDisabled) {
    return <DisabledPaymentInfoBox />;
  }

  if (isResidentInactive) {
    return <InactiveInfoBox />;
  }

  if (isResidentFinalSettlement) {
    return <FinalSettlementInfoBox phone={resident?.nursingHomePhone} />;
  }

  if (isResidentFirstSettlement && !isPaymentPending) {
    return <FirstSettlementInfoBox phone={resident?.nursingHomePhone} />;
  }

  if (isResidentFirstSettlement && pendingPayment) {
    return (
      <SettlementDirectDebitActiveInfoBox phone={resident?.nursingHomePhone} />
    );
  }

  if (pendingPayment) {
    return (
      <PendingInfoBox
        chargeAmount={pendingPayment?.chargeAmount}
        balance={resident?.balance ?? 0}
      />
    );
  }

  if (upcomingPayment) {
    return (
      <UpcomingInfoBox
        chargeAmount={upcomingPayment?.chargeAmount}
        balance={resident?.balance ?? 0}
        chargeDate={upcomingPayment?.chargeDate}
      />
    );
  }

  if (isPaymentFailed) {
    return <FailedInfoBox phone={resident?.nursingHomePhone ?? ''} />;
  }

  if (paymentByFamilyMember) {
    if (paymentsDisabledForNursingHome) {
      return (
        <DisabledPaymentForNursingHomeInfoBox
          until={paymentsDisabledForNursingHome?.until}
        />
      );
    }

    if (resident?.belowThreshold) {
      return (
        <LowBalanceManualPaymentInfoBox
          handleClickButton={handleManualPayment}
        />
      );
    }
  } else if (directDebitInactive) {
    return <DirectDebitInactiveInfoBox />;
  }

  return (
    <AllSetInfoBox
      paymentsDisabledForNursingHome={
        paymentsDisabledForNursingHome !== undefined
      }
      paymentsDisabledUntil={paymentsDisabledForNursingHome?.until}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LoadingInfoBoxProps {}

export const LoadingInfoBox: FC<LoadingInfoBoxProps> = () => {
  return (
    <InfoBoxContainer
      showWelcomeMessage
      image={
        <img src={AllSetImage} alt="" width={210} style={{ marginTop: -30 }} />
      }
      content={
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <CircularProgress color="primary" sx={{ mt: 3 }} />
        </Stack>
      }
    />
  );
};

const WelcomeMessage: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack direction="row" sx={{ mb: 1.5 }} alignItems="flex-start">
      <Typography variant="h4" sx={{ mr: 1 }}>
        {t('welcome-to')}
      </Typography>
      <img src={Logo} alt="" height={31} />
    </Stack>
  );
};

const DirectDebitInactiveContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        {t('mobile.family-member.set-up-now-description')}
      </Typography>

      <Button
        variant="contained"
        onClick={() => navigate(fmAppMainPages.DIRECT_DEBIT)}
      >
        {t('mobile.family-member.set-up-now')}
      </Button>
    </>
  );
};

interface PendingContentProps {
  chargeAmount: number;
  balance: number;
}

const PendingContent: FC<PendingContentProps> = ({ chargeAmount, balance }) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();
  return (
    <>
      <Label
        sx={{
          mb: 2,
          p: 1.8,
          bgcolor: 'secondary.dark',
        }}
        variant="filled"
        color="secondary"
      >
        {t('mobile.family-member.pending-payment')}
      </Label>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 700,
        }}
      >
        {t('mobile.family-member.payment-in-progress')}
      </Typography>
      <Typography variant="subtitle1">
        {' '}
        {t('mobile.family-member.payment-amount')}:{' '}
        {fCurrency(chargeAmount / 100)}
      </Typography>
      <Typography variant="subtitle1">
        {t('mobile.family-member.new-account-balance')}:{' '}
        {fCurrency((balance + chargeAmount) / 100)}
      </Typography>
    </>
  );
};

interface UpcomingContentProps {
  chargeAmount: number;
  balance: number;
  chargeDate: Date | undefined;
}

const UpcomingContent: FC<UpcomingContentProps> = ({
  chargeAmount,
  balance,
  chargeDate,
}) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();

  const { daysLeftToPayment } = useUpcomingPaymentDays({
    chargeDate,
  });

  return (
    <>
      <Label
        sx={{
          mb: 2,
          p: 1.8,
          bgcolor: 'secondary.dark',
        }}
        variant="filled"
        color="secondary"
      >
        {t('mobile.family-member.upcoming-payment')}
      </Label>
      <Typography
        variant="h6"
        sx={{
          mb: 2,
          fontWeight: 700,
        }}
      >
        {`${
          daysLeftToPayment > 0
            ? t('payments.upcoming-payment', { count: daysLeftToPayment })
            : t('payments.upcoming-payment_zero')
        }`}
      </Typography>
      <Typography variant="subtitle1">
        {' '}
        {t('mobile.family-member.payment-amount')}:{' '}
        {fCurrency(chargeAmount / 100)}
      </Typography>
      <Typography variant="subtitle1">
        {t('mobile.family-member.new-account-balance')}:{' '}
        {fCurrency((balance + chargeAmount) / 100)}
      </Typography>
    </>
  );
};

interface FailedContentProps {
  phone: string;
}

const FailedContent: FC<FailedContentProps> = ({ phone }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Label
        sx={{
          mb: 2,
          p: 1.8,
        }}
        variant="filled"
        color="error"
      >
        {t('mobile.family-member.failed-payment')}
      </Label>
      <Stack direction="row" justifyContent="center">
        <Iconify
          icon="bxs:error"
          color={theme.palette.error.main}
          fontSize={25}
        />
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 700,
            ml: 0.7,
          }}
        >
          {t('mobile.family-member.failed-payment-text')}
        </Typography>
      </Stack>

      <Typography variant="subtitle1">
        {t('mobile.family-member.failed-payment-contact-nursing-home', {
          phone: phone?.replace(/\s/g, '\xa0'),
        })}
      </Typography>
    </>
  );
};

const DisabledPaymentContent: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <>
      <Label
        sx={{
          mb: 2,
          p: 1.8,
        }}
        variant="filled"
        color="error"
      >
        {t('mobile.family-member.disabled-payment')}
      </Label>
      <Stack direction="row" justifyContent="center">
        <Iconify
          icon="bxs:error"
          color={theme.palette.error.main}
          fontSize={25}
        />
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 700,
            ml: 0.7,
          }}
        >
          {t('mobile.family-member.disabled-payment')}
        </Typography>
      </Stack>

      <Typography variant="subtitle1">
        {t('mobile.family-member.disabled-payment-contact-nursing-home')}
      </Typography>
    </>
  );
};

const DisabledPaymentForNursingHomeContent: FC<{ until?: Date }> = ({
  until,
}) => {
  const { t } = useTranslation();
  const fDate = useFormatDate();

  return (
    <>
      <Label
        sx={{
          mb: 2,
          p: 1.8,
        }}
        variant="filled"
        color="info"
      >
        {t('mobile.family-member.disabled-payment')}
      </Label>
      <Stack direction="row" justifyContent="center">
        <Typography
          variant="h6"
          sx={{
            mb: 2,
            fontWeight: 700,
          }}
        >
          {t('mobile.family-member.disabled-payment')}
        </Typography>
      </Stack>

      <Typography variant="subtitle1">
        {until
          ? t('mobile.family-member.payments-disabled-for-nursing-home-until', {
              date: fDate(until, YEAR_MONTH_DAY_FORMAT),
            })
          : t('mobile.family-member.payments-disabled-for-nursing-home')}
      </Typography>
    </>
  );
};

interface AllSetContentProps {
  paymentsDisabledForNursingHome?: boolean;
  paymentsDisabledUntil?: Date;
}

const AllSetContent: FC<AllSetContentProps> = ({
  paymentsDisabledForNursingHome,
  paymentsDisabledUntil,
}) => {
  const { t } = useTranslation();
  const fDate = useFormatDate();

  return (
    <>
      <Label
        sx={{
          mb: 2,
          p: 1.8,
        }}
        variant="filled"
        color="secondary"
      >
        Infoboard
      </Label>
      {!paymentsDisabledForNursingHome && (
        <Typography variant="h6" sx={{ fontWeight: 700 }}>
          {t('mobile.family-member.all-set')}
        </Typography>
      )}
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        {paymentsDisabledForNursingHome
          ? paymentsDisabledUntil
            ? t(
                'mobile.family-member.payments-disabled-for-nursing-home-until',
                { date: fDate(paymentsDisabledUntil, YEAR_MONTH_DAY_FORMAT) }
              )
            : t('mobile.family-member.payments-disabled-for-nursing-home')
          : t('mobile.family-member.no-payment-scheduled')}
      </Typography>
    </>
  );
};

interface LowBalanceManualPaymentInfoBoxProps {
  handleClickButton: () => void;
}

export const LowBalanceManualPaymentInfoBox: FC<
  LowBalanceManualPaymentInfoBoxProps
> = ({ handleClickButton }) => {
  const { t } = useTranslation();
  return (
    <InfoBoxContainer
      image={
        <img
          src={SetupDirectDebitImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={
        <Stack
          justifyContent="center"
          alignItems="flex-start"
          sx={{ height: '100%' }}
        >
          <Label
            sx={{
              mb: 3,
              p: 1.8,
              bgcolor: 'warning.main',
              color: 'white',
            }}
            variant="filled"
          >
            {t('fm-triggered-payments.dashboard-cards.low-balance.label')}
          </Label>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            {t('fm-triggered-payments.dashboard-cards.low-balance.description')}
          </Typography>

          <Button variant="contained" onClick={handleClickButton}>
            {t(
              'fm-triggered-payments.dashboard-cards.low-balance.proceed-with-payment'
            )}
          </Button>
        </Stack>
      }
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DirectDebitInactiveInfoBoxProps {}

export const DirectDebitInactiveInfoBox: FC<
  DirectDebitInactiveInfoBoxProps
> = () => {
  return (
    <InfoBoxContainer
      showWelcomeMessage
      image={
        <img
          src={SetupDirectDebitImage}
          alt=""
          width={210}
          style={{ marginTop: -30 }}
        />
      }
      content={
        <>
          <DirectDebitInactiveContent />
        </>
      }
    />
  );
};

type UpcomingInfoBoxProps = UpcomingContentProps;

export const UpcomingInfoBox: FC<UpcomingInfoBoxProps> = (props) => {
  return (
    <InfoBoxContainer
      image={
        <img src={AllSetImage} alt="" width={210} style={{ marginTop: 30 }} />
      }
      content={<UpcomingContent {...props} />}
    />
  );
};

type PendingInfoBoxProps = PendingContentProps;

export const PendingInfoBox: FC<PendingInfoBoxProps> = (props) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={PaymentPendingImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={<PendingContent {...props} />}
    />
  );
};

interface FirstSettlementInfoProps {
  phone?: string;
}

export const FirstSettlementInfoBox: FC<FirstSettlementInfoProps> = ({
  phone,
}) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={SettlementImage}
          alt=""
          width={150}
          height={150}
          style={{ marginTop: 30 }}
        />
      }
      content={<FirstSettlementInfo nursingHomePhone={phone ?? ''} />}
    />
  );
};

interface FinalSettlementInfoProps {
  phone?: string;
}

export const FinalSettlementInfoBox: FC<FinalSettlementInfoProps> = ({
  phone,
}) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={SettlementDirectDebitImage}
          alt=""
          width={150}
          height={150}
          style={{ marginTop: 30 }}
        />
      }
      content={<FinalSettlementInfo nursingHomePhone={phone ?? ''} />}
    />
  );
};

interface SettlementDirectDebitActiveInfoBoxProps {
  phone?: string;
}

export const SettlementDirectDebitActiveInfoBox: FC<
  SettlementDirectDebitActiveInfoBoxProps
> = ({ phone }) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={SettlementDirectDebitImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={
        <SettlementDirectDebitActiveInfo nursingHomePhone={phone ?? ''} />
      }
    />
  );
};

interface InactiveInfoBoxProps {
  phone?: string;
}

export const InactiveInfoBox: FC<InactiveInfoBoxProps> = ({ phone }) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={SettlementDirectDebitImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={<InactiveDirectDebitInfo nursingHomePhone={phone ?? ''} />}
    />
  );
};

export const DisabledPaymentInfoBox: FC = () => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={PaymentFailedImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={<DisabledPaymentContent />}
    />
  );
};

export const DisabledPaymentForNursingHomeInfoBox: FC<{ until?: Date }> = ({
  until,
}) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={PaymentFailedImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={<DisabledPaymentForNursingHomeContent until={until} />}
    />
  );
};

interface FailedInfoBoxProps {
  phone: string;
}

export const FailedInfoBox: FC<FailedInfoBoxProps> = ({ phone }) => {
  return (
    <InfoBoxContainer
      image={
        <img
          src={PaymentFailedImage}
          alt=""
          width={210}
          style={{ marginTop: 30 }}
        />
      }
      content={<FailedContent phone={phone ?? ''} />}
    />
  );
};

interface AllSetInfoBoxProps {
  paymentsDisabledForNursingHome?: boolean;
  paymentsDisabledUntil?: Date;
}

export const AllSetInfoBox: FC<AllSetInfoBoxProps> = ({
  paymentsDisabledForNursingHome,
  paymentsDisabledUntil,
}) => {
  return (
    <InfoBoxContainer
      showWelcomeMessage
      image={
        <img src={AllSetImage} alt="" width={210} style={{ marginTop: -30 }} />
      }
      content={
        <>
          <AllSetContent
            paymentsDisabledForNursingHome={paymentsDisabledForNursingHome}
            paymentsDisabledUntil={paymentsDisabledUntil}
          />
        </>
      }
    />
  );
};

interface InfoBoxContainerProps {
  image: React.ReactNode;
  content: React.ReactNode;
  showWelcomeMessage?: boolean;
}

export const InfoBoxContainer: FC<InfoBoxContainerProps> = ({
  image,
  content,
  showWelcomeMessage,
}) => {
  const largeScreens = useMediaQuery('(min-width:750px)');
  return (
    <BoxContainer direction="column" justifyContent="space-between" gap={2}>
      {showWelcomeMessage && <WelcomeMessage />}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack direction="column" alignItems="flex-start" sx={{ flexGrow: 1 }}>
          {content}
        </Stack>
        {largeScreens && image}
      </Stack>
    </BoxContainer>
  );
};

const BoxContainer = styled(Stack)(
  sx({
    bgcolor: 'primary.lighter',
    minHeight: 255,
    height: '100%',
    borderRadius: 4,
    paddingX: 3,
    paddingY: 3,
    boxShadow: '-2px 5px 20px -5px rgba(150,170,180,0.5)',
  })
);
