import 'reflect-metadata';
import './sentry';
import {
  ScrollToTop,
  SnackbarProvider,
  ThemeProvider,
} from '@pflegenavi/web-components';
import React, { type FC, type PropsWithChildren, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './app/App';
import { initI18N, LocaleProvider } from '@pflegenavi/frontend/localization';
import {
  localeStorage,
  LocalizationProvider,
  ThemeLocalization,
} from '@pflegenavi/frontend/localization-web-app';
import { AuthenticationProvider } from '@pflegenavi/frontend/authentication';
import ErrorBoundary from './components/general/ErrorBoundry';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { keycloak, login } from './keycloak';

// this is a virtual font file from vite pluging see: https://github.com/stafyniaksacha/vite-plugin-fonts
import 'virtual:fonts.css';
import { TenantContextProvider } from '@pflegenavi/frontend/tenant';
import {
  AnalyticsApiProvider,
  FamilyMemberApiProvider,
  ImageApiProvider,
  PaymentApiProvider,
  ProfileTypeContext,
  ResidentApiProvider,
  TransactionApiProvider,
  UserProfileApiProvider,
} from '@pflegenavi/frontend/api-nursing-home';
import { FMContextProvider } from '@pflegenavi/frontend/family-member-context';
import { createRoot } from 'react-dom/client';
import {
  getMobileOSWithSizeHeuristic,
  getTokenPayloadFromToken,
  getWebWelcomeToken,
  MobileOS,
} from '@pflegenavi/shared/utils';
import { DownloadAppFromStore } from './components/general/DownloadAppFromStore';
import { EmptyNursingHomeProvider } from '@pflegenavi/frontend/nursing-home-context';
import { SnowplowTracking } from '@pflegenavi/frontend/tracking/web';
import { TrackingProvider } from '@pflegenavi/frontend/tracking';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { ClientStoredResourcesProvider } from '@pflegenavi/frontend/client-stored-resources-context';
import {
  PflegenaviAuthenticationProvider,
  shouldUseNewLogin,
  useAuthentication,
} from '@pflegenavi/shared-frontend/authentication';
import { getPhoenixApiBaseUrl } from '@pflegenavi/shared-frontend/platform';

const tracker = new SnowplowTracking();
tracker.initialize({
  appId: 'family-member-web',
});

window.global ||= window;
// @ts-expect-error // pflegenaviApplication is untyped
window.global.pflegenaviApplication = 'family-member-web';
// @ts-expect-error // pflegenaviApplicationVersion is untyped
window.global.pflegenaviApplicationVersion =
  import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release;

await initI18N(localeStorage);

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container!);

function redirectIfNecessary(url: string) {
  const hostname = new URL(url).hostname;

  if (hostname !== window.location.hostname) {
    window.location.replace(url);
    return 'redirected';
  }
  return undefined;
}

const AuthWrapper: FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuthentication(shouldUseNewLogin());

  return (
    <AuthenticationProvider
      // @ts-expect-error // keycloak type mismatch
      keycloak={auth ?? keycloak}
      login={login}
      redirectUri={`${window.location.origin}/${fmAppMainPages.DASHBOARD}`}
      isNewAuth={Boolean(auth)}
    >
      {children}
    </AuthenticationProvider>
  );
};

function renderApp() {
  const mobileOs = getMobileOSWithSizeHeuristic();
  if (mobileOs === MobileOS.Android || mobileOs === MobileOS.iOS) {
    root.render(
      <ThemeProvider>
        <LocaleProvider localeStorage={localeStorage}>
          <LocalizationProvider>
            <ThemeLocalization>
              <DownloadAppFromStore platform={mobileOs} />
            </ThemeLocalization>
          </LocalizationProvider>
        </LocaleProvider>
      </ThemeProvider>
    );
  } else {
    root.render(
      <ClientStoredResourcesProvider storage={localStorage}>
        <PflegenaviAuthenticationProvider
          storage={localStorage}
          getWelcomeToken={getWebWelcomeToken}
          shouldUseNewLogin={shouldUseNewLogin()}
          apiUrl={getPhoenixApiBaseUrl(undefined)}
          redirectIfNecessary={redirectIfNecessary}
          onLogout={() => queryClient.clear()}
        >
          <AuthWrapper>
            <StrictMode>
              <BrowserRouter>
                <SnackbarProvider>
                  <QueryClientProvider client={queryClient}>
                    <TenantContextProvider>
                      <EmptyNursingHomeProvider>
                        <PaymentApiProvider>
                          <AnalyticsApiProvider>
                            <TransactionApiProvider>
                              <ResidentApiProvider>
                                <ImageApiProvider>
                                  <ProfileTypeContext.Provider value="familyMember">
                                    <UserProfileApiProvider>
                                      <FamilyMemberApiProvider>
                                        <FMContextProvider>
                                          <ThemeProvider>
                                            <LocaleProvider
                                              localeStorage={localeStorage}
                                            >
                                              <LocalizationProvider>
                                                <ThemeLocalization>
                                                  <DndProvider
                                                    backend={HTML5Backend}
                                                  >
                                                    <ScrollToTop />
                                                    <ErrorBoundary>
                                                      <TrackingProvider
                                                        tracker={tracker}
                                                      >
                                                        <App />
                                                      </TrackingProvider>
                                                    </ErrorBoundary>
                                                  </DndProvider>
                                                </ThemeLocalization>
                                              </LocalizationProvider>
                                            </LocaleProvider>
                                          </ThemeProvider>
                                        </FMContextProvider>
                                      </FamilyMemberApiProvider>
                                      <ReactQueryDevtools
                                        initialIsOpen={false}
                                      />
                                    </UserProfileApiProvider>
                                  </ProfileTypeContext.Provider>
                                </ImageApiProvider>
                              </ResidentApiProvider>
                            </TransactionApiProvider>
                          </AnalyticsApiProvider>
                        </PaymentApiProvider>
                      </EmptyNursingHomeProvider>
                    </TenantContextProvider>
                  </QueryClientProvider>
                </SnackbarProvider>
              </BrowserRouter>
            </StrictMode>
          </AuthWrapper>
        </PflegenaviAuthenticationProvider>
      </ClientStoredResourcesProvider>
    );
  }
}

function redirectWelcome() {
  const queryParams = new URLSearchParams(window.location.search);
  const key = queryParams.get('key');
  let basePath = 'https://auth.pflegenavi.at';

  if (window.location.host === 'localhost:3001') {
    basePath = 'http://localhost:5051';
  } else if (
    window.location.hostname === 'family-member.staging.pflegenavi.at'
  ) {
    basePath = 'https://auth-staging.pflegenavi.at';
  }

  window.location.href = `${basePath}/auth/realms/family_member/login-actions/action-token?key=${key}`;
}

if (window.location.pathname !== '/welcome') {
  renderApp();
} else {
  const search = new URLSearchParams(window.location.search);
  const token = search.get('key');
  const payload = getTokenPayloadFromToken(token);
  if (token && payload?.v !== 'pn0.1') {
    redirectWelcome();
  } else {
    renderApp();
  }
}
