import {
  usePaymentInfoByResidentIdAsFamilyMember,
  useResidentBalanceInfo,
} from '@pflegenavi/frontend/api-nursing-home';
import { Typography, Stack } from '@mui/material';
import { PaymentInitiatedBy, PaymentSchedule } from '@pflegenavi/shared/api';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';
import { getDiffInDaysUntilDayOfMonth } from '@pflegenavi/shared/utils';
import { YEAR_MONTH_SHORTDAY_FORMAT } from '@pflegenavi/shared/constants';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { LoadingContainer } from '@pflegenavi/web-components';

type PaymentInitiationInfoViewProps =
  | InitiatedMonthly
  | InitiatedThreshold
  | NotInitiatedAutomatically;

interface NotInitiatedAutomatically {
  initiatedBy: PaymentInitiatedBy.FamilyMember | PaymentInitiatedBy.Disabled;
}

interface InitiatedMonthly {
  initiatedBy: PaymentInitiatedBy.System;
  schedule: PaymentSchedule.Monthly;
  dayOfMonth: number;
  maxAmount: number;
  topUpAmount: number;
  minimumPaymentAmount: number;
}

interface InitiatedThreshold {
  initiatedBy: PaymentInitiatedBy.System;
  schedule: PaymentSchedule.Threshold;
  threshold: number;
  topUpAmount: number;
}

export const PaymentInitiationInfoView = (
  props: PaymentInitiationInfoViewProps
): JSX.Element | null => {
  if (props.initiatedBy !== PaymentInitiatedBy.System) {
    return null;
  }

  if (props.schedule === PaymentSchedule.Monthly) {
    return <PaymentInitiationMonthly {...props} />;
  }
  return <PaymentInitiationThreshold {...props} />;
};

const PaymentInitiationMonthly = (props: InitiatedMonthly) => {
  const fDate = useFormatDate();
  const { t } = useTranslation();
  const { fCurrencyCents } = useFormatting();
  return (
    <Stack gap={1}>
      <Stack>
        <Typography variant="body1">
          {t('resident.form.payment-type.payment-day')}:{' '}
          <Typography variant="body1" fontWeight={700} display="inline-flex">
            {props.dayOfMonth}.{' '}
            {t('resident.form.payment-type.payment-day-of-month')}
          </Typography>
        </Typography>
        <Typography variant="body1">
          {t(
            'resident.form.payment-type.payment-schedule-next-payment-short-label'
          )}
          :{' '}
          <Typography variant="body1" fontWeight={700} display="inline-flex">
            {fDate(
              addDays(
                new Date(),
                getDiffInDaysUntilDayOfMonth(new Date(), props.dayOfMonth)
              ),
              YEAR_MONTH_SHORTDAY_FORMAT
            )}
          </Typography>
        </Typography>
        <Typography variant="body1">
          {t('resident.top-up-thresholds.top-up-amount')}:{' '}
          <Typography variant="body1" fontWeight={700} display="inline-flex">
            {fCurrencyCents(props.topUpAmount)}
          </Typography>
        </Typography>
        <Typography variant="body1">
          {t('resident.form.payment-type.max-amount')}:{' '}
          <Typography variant="body1" fontWeight={700} display="inline-flex">
            {fCurrencyCents(props.maxAmount)}
          </Typography>
        </Typography>
      </Stack>
      <Typography variant="subtitle2">
        {t('mobile.family-member.direct-debit-monthly-info', {
          dayOfMonth: props.dayOfMonth,
          topUpAmount: fCurrencyCents(props.topUpAmount),
          maxAmount: fCurrencyCents(props.maxAmount),
          minimumPaymentAmount: fCurrencyCents(props.minimumPaymentAmount),
        })}
      </Typography>
      <Typography variant="subtitle2">
        {t(
          'mobile.family-member.direct-debit-monthly-info-nursing-home-changes'
        )}
      </Typography>
      <Typography variant="subtitle2">
        {t('mobile.family-member.direct-debit-upcoming-notification-info')}{' '}
        {t('mobile.family-member.direct-debit-updates')}
      </Typography>
    </Stack>
  );
};

const PaymentInitiationThreshold = (props: InitiatedThreshold) => {
  const { fCurrencyCents } = useFormatting();
  const { t } = useTranslation();
  return (
    <Stack gap={1}>
      <Stack>
        <Typography variant="body1">
          {t('resident.top-up-thresholds.top-up-amount')}:{' '}
          <Typography variant="body1" fontWeight={700} display="inline-flex">
            {fCurrencyCents(props.topUpAmount)}
          </Typography>
        </Typography>
        <Typography variant="body1">
          {t('resident.top-up-thresholds.threshold')}:{' '}
          <Typography variant="body1" fontWeight={700} display="inline-flex">
            {fCurrencyCents(props.threshold)}
          </Typography>
        </Typography>
      </Stack>
      <Typography variant="subtitle2">
        {t('mobile.family-member.direct-debit-threshold-info', {
          topUpAmount: fCurrencyCents(props.topUpAmount),
          threshold: fCurrencyCents(props.threshold),
        })}
      </Typography>
      <Typography variant="subtitle2">
        {t(
          'mobile.family-member.direct-debit-threshold-info-nursing-home-changes'
        )}
      </Typography>
      <Typography variant="subtitle2">
        {t('mobile.family-member.direct-debit-upcoming-notification-info')}{' '}
        {t('mobile.family-member.direct-debit-updates')}
      </Typography>
    </Stack>
  );
};

// eslint-disable-next-line complexity
export const PaymentInitiationInfo = (): JSX.Element | null => {
  const { selectedResidentId } = useFMContext();
  const { data, isLoading } = useResidentBalanceInfo({
    residentId: selectedResidentId,
  });
  const { data: paymentInfo, isLoading: isPaymentInfoLoading } =
    usePaymentInfoByResidentIdAsFamilyMember(selectedResidentId);

  if (isLoading || isPaymentInfoLoading || !data || !paymentInfo) {
    return <LoadingContainer />;
  }

  // If FM initiated payments are enabled and the user wants to set up direct debit,
  // they will change to payment initiated by system with threshold schedule. We need to
  // overwrite the paymentInfo here to display the correct information.
  if (paymentInfo.initiatedBy === PaymentInitiatedBy.FamilyMember) {
    // @ts-expect-error // we are overwriting this property
    paymentInfo.initiatedBy = PaymentInitiatedBy.System;
    // @ts-expect-error // we are overwriting this property
    paymentInfo.schedule = { schedule: PaymentSchedule.Threshold };
  }

  if (paymentInfo.initiatedBy === PaymentInitiatedBy.System) {
    if (paymentInfo.schedule?.schedule === PaymentSchedule.Monthly) {
      const maxAmount = !paymentInfo.schedule.maxAmount
        ? data.nursingHomeConfiguration.maxPaymentAmount
        : paymentInfo.schedule.maxAmount;
      return (
        <PaymentInitiationInfoView
          initiatedBy={paymentInfo.initiatedBy}
          schedule={paymentInfo.schedule.schedule}
          dayOfMonth={paymentInfo.schedule.dayOfMonth!}
          maxAmount={maxAmount}
          topUpAmount={Math.round(data.residentConfiguration.topUpAmount * 100)}
          minimumPaymentAmount={data.nursingHomeConfiguration.minPaymentAmount}
        />
      );
    } else if (paymentInfo.schedule?.schedule === PaymentSchedule.Threshold) {
      return (
        <PaymentInitiationInfoView
          initiatedBy={paymentInfo.initiatedBy}
          schedule={paymentInfo.schedule!.schedule}
          threshold={Math.round(data.residentConfiguration.threshold * 100)}
          topUpAmount={Math.round(data.residentConfiguration.topUpAmount * 100)}
        />
      );
    }
  } else {
    return <PaymentInitiationInfoView initiatedBy={paymentInfo?.initiatedBy} />;
  }

  return null;
};
