import React, { useMemo } from 'react';

import type { LabelColor } from '@pflegenavi/web-components';
import {
  Label,
  TableCell,
  TableRow,
  Typography,
} from '@pflegenavi/web-components';

import { TransactionSourceType } from '@pflegenavi/shared/api';
import { useFormatting, useLocale } from '@pflegenavi/frontend/localization';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import { TransactionTypeLogo } from '../general/TransactionTypeLogo';
import { Stack } from '@mui/material';
import type { SearchablePayment } from '../../pages/PaymentsPage';
import CancelLogo from '../../assets/transaction-icons/cancel.svg';
import RefundBlueLogo from '../../assets/transaction-icons/refund-blue.svg';

interface Props {
  row: SearchablePayment;
}

export function FmPaymentTableRow({ row }: Props): JSX.Element {
  const locale = useLocale();
  const { fCurrency } = useFormatting();

  const {
    amount,
    date,
    sourceType,
    disputed,
    cancelled,
    sourceTypeString,
    paymentStateString,
    ibanLast4,
    sequenceID,
  } = row;

  const { additionalSubIcon } = useMemo(() => {
    if (disputed || cancelled) {
      return {
        additionalSubIcon: CancelLogo,
      };
    }
    if (
      sourceType === TransactionSourceType.Dispute ||
      sourceType === TransactionSourceType.CancelledManualDeposit
    ) {
      return {
        additionalSubIcon: RefundBlueLogo,
      };
    }

    return {
      additionalSubIcon: undefined,
    };
  }, [cancelled, disputed, sourceType]);

  // eslint-disable-next-line complexity
  const amountLabelColor = useMemo(() => {
    if (sourceType === TransactionSourceType.FinalSettlement) {
      if (amount < 0) {
        return 'error';
      } else {
        return 'green';
      }
    }
    if (sourceType === TransactionSourceType.InitialDeposit) {
      if (amount < 0) {
        return 'error';
      } else {
        return 'success';
      }
    }
    if (disputed || cancelled) {
      return 'error';
    }
    if (
      sourceType === TransactionSourceType.Dispute ||
      sourceType === TransactionSourceType.CancelledManualDeposit
    ) {
      return 'info';
    }
    return 'success';
  }, [amount, cancelled, disputed, sourceType]);

  return (
    <TableRow hover>
      <TableCell
        align="left"
        sx={{
          color: 'text.secondary',
          fontSize: 13,
        }}
      >
        {sequenceID ?? '--'}
      </TableCell>
      <TableCell align="left" sx={{ width: 80 }}>
        <Stack direction="row" gap={1}>
          <TransactionTypeLogo
            size={40}
            logoType={sourceType}
            additionalSubIcon={additionalSubIcon}
          />

          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }} noWrap>
              {sourceTypeString}
            </Typography>
            {paymentStateString && (
              <Typography variant="subtitle2" sx={{ color: 'grey.500' }}>
                {paymentStateString}
              </Typography>
            )}
          </Stack>
        </Stack>
      </TableCell>

      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
        {ibanLast4 ? `**** ${ibanLast4}` : '--'}
      </TableCell>

      <TableCell align="left">
        {date.toLocaleDateString(locale, YEAR_MONTH_DAY_SHORT_FORMAT)}
      </TableCell>

      <TableCell align="right">
        <Label variant="ghost" color={amountLabelColor as LabelColor}>
          {fCurrency(amount)}
        </Label>
      </TableCell>
    </TableRow>
  );
}
