import { TransactionSourceType } from '@pflegenavi/shared/api';
import type { TFunction } from 'react-i18next';

interface opts {
  t: TFunction<'translation', undefined>;
}

// eslint-disable-next-line complexity
export const getTypeString = (
  sourceType: TransactionSourceType,
  receiptType: string | undefined,
  receiptDate: string | undefined,
  opts: opts
): string => {
  let typeString = '';
  switch (sourceType) {
    case TransactionSourceType.InitialDeposit:
      typeString = opts.t('transaction-type.initial-deposit');
      break;
    case TransactionSourceType.ManualDeposit:
      typeString = opts.t('transaction-type.manual-deposit');
      break;
    case TransactionSourceType.CancelledManualDeposit:
      typeString = opts.t('transaction-type.cancelled-deposit');
      break;
    case TransactionSourceType.DirectDebit:
      typeString = opts.t('transaction-type.direct-debit');
      break;
    case TransactionSourceType.RecurringItem:
      typeString = opts.t('transaction-type.recurring-item');
      break;
    case TransactionSourceType.Receipt:
      if (receiptType === 'Barauslage') {
        typeString = receiptDate
          ? opts.t('transaction-type.withdrawal-with-date', {
              date: receiptDate,
            })
          : opts.t('transaction-type.withdrawal');
      } else {
        typeString = receiptDate
          ? opts.t('transaction-type.receipt-with-date', { date: receiptDate })
          : opts.t('transaction-type.receipt');
      }

      break;
    case TransactionSourceType.CancelledReceipt:
      if (receiptType === 'Barauslage') {
        typeString = opts.t('transaction-type.cancelled-withdrawal');
        typeString = receiptDate
          ? opts.t('transaction-type.cancelled-withdrawal-with-date', {
              date: receiptDate,
            })
          : opts.t('transaction-type.cancelled-withdrawal');
      } else {
        typeString = receiptDate
          ? opts.t('transaction-type.cancelled-receipt-with-date', {
              date: receiptDate,
            })
          : opts.t('transaction-type.cancelled-receipt');
      }

      break;
    case TransactionSourceType.Dispute:
      typeString = opts.t('transaction-type.dispute');
      break;
    case TransactionSourceType.FinalSettlement:
      typeString = opts.t('transaction-type.final-settlement');
      break;
  }
  return typeString;
};
