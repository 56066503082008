import type { FC } from 'react';
import React from 'react';
import type { HeadCell, UseTableProps } from '@pflegenavi/web-components';
import {
  CustomTablePagination,
  emptyRows,
  Table,
  TableBody,
  TableContainer,
  TableEmptyRows,
  TableHeadCustom,
  TableSkeleton,
} from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { FmPaymentTableRow } from './FmPaymentTableRow';

import { NoFmPaymentsData } from './NoFmPaymentsData';
import { FmPaymentColumn } from '../../hooks/useFmPaymentsTable';
import type { SearchablePayment } from '../../pages/PaymentsPage';

interface FmTransactionsTableProps
  extends UseTableProps<FmPaymentColumn, GetTransactionsWithReceiptDto> {
  isLoading: boolean;
  payments: SearchablePayment[] | undefined;
  isError: boolean;
  isFiltered: boolean;
  dataAvailableBeforeFilter: boolean;
}

// eslint-disable-next-line complexity
export const FmPaymentsTable: FC<FmTransactionsTableProps> = ({
  page,
  rowsPerPage,
  onChangePage,
  onChangeRowsPerPage,
  order,
  orderBy,
  onSort,
  isLoading,
  isError,
  isFiltered,
  payments,
  dataAvailableBeforeFilter,
}) => {
  const { t } = useTranslation();

  const isNotFound = !payments?.length && !isFiltered;
  const isNotFoundOnSearch = !payments?.length && isFiltered;

  const _paymentsOnPage = () =>
    payments?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || [];

  const TABLE_HEAD: Array<HeadCell<FmPaymentColumn>> = [
    {
      id: FmPaymentColumn.SequenceID,
      label: 'ID',
      align: 'left',
    },
    {
      id: FmPaymentColumn.Type,
      label: t('common.type'),
      align: 'left',
      width: 250,
    },
    {
      id: FmPaymentColumn.Iban,
      label: t('common.iban'),
      align: 'left',
      width: 200,
    },
    {
      id: FmPaymentColumn.DateOfReceipt,
      label: t('common.date-of-receipt'),
      align: 'left',
    },
    {
      id: FmPaymentColumn.Amount,
      label: t('common.amount'),
      align: 'right',
    },
  ];

  return (
    <>
      <TableContainer sx={{ position: 'relative' }}>
        <Table>
          <TableHeadCustom
            order={order}
            orderBy={orderBy}
            headLabels={TABLE_HEAD}
            rowCount={payments?.length}
            onSort={onSort}
          />

          <TableBody>
            {isLoading &&
              Array.from(Array(rowsPerPage).keys()).map((row: number) => (
                <TableSkeleton
                  key={`row- ${row}`}
                  colSpan={TABLE_HEAD.length}
                />
              ))}
          </TableBody>

          <TableBody>
            {_paymentsOnPage().map((payment) => (
              <FmPaymentTableRow key={payment.id} row={payment} />
            ))}

            {payments && !isNotFoundOnSearch && !isNotFound && !isError && (
              <TableEmptyRows
                height={72}
                emptyRows={emptyRows(page, rowsPerPage, payments?.length || 0)}
              />
            )}

            {!isLoading && (
              <NoFmPaymentsData
                transactionsAvailableBeforeFilter={dataAvailableBeforeFilter}
                transactions={payments}
                isError={isError}
                isFiltered={isFiltered}
                colSpan={TABLE_HEAD.length}
              />
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <CustomTablePagination
        page={page}
        onChangePage={onChangePage}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        items={payments}
        tableKey="familyMemberPayments"
      />
    </>
  );
};
