import type { FC } from 'react';
import { Box, styled } from '@mui/material';
import { logosMapping } from '@pflegenavi/frontend/family-member-context';
import { experimental_sx as sx } from '@mui/system';

interface TransactionTypeLogoProps {
  size?: number;
  shortName?: string;
  logoType?: string;
  additionalSubIcon?: string;
}

export const TransactionTypeLogo: FC<TransactionTypeLogoProps> = ({
  logoType,
  size = 40,
  additionalSubIcon,
}) => {
  const SelectedIcon =
    logosMapping.get(logoType ?? '_unknown') ?? logosMapping.get('_unknown');
  return (
    <LogoContainer
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: `${size / 2}px`,
      }}
    >
      <img alt="" src={SelectedIcon} width={size / 2} height={size / 2} />
      {additionalSubIcon && (
        <AdditionalIcon alt="" src={additionalSubIcon} width={13} height={13} />
      )}
    </LogoContainer>
  );
};

const LogoContainer = styled(Box)(
  sx({
    bgcolor: 'grey.300',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: 1,
    position: 'relative',
  })
);

const AdditionalIcon = styled('img')(
  sx({
    right: -5,
    bottom: 1,
    position: 'absolute',
  })
);
