import type { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import PflegenaviLogoSvg from '../../assets/pn-logo-with-name-vertical.svg';
import {
  FAMILY_MEMBER_APP_STORE_LINK,
  FAMILY_MEMBER_PLAY_STORE_LINK,
} from '@pflegenavi/shared/constants';
import DownloadFromPlayStoreSvg from '../../assets/download-from-play-store.svg';
import DownloadFromAppStoreSvg from '../../assets/download-on-the-app-store.svg';
import DownloadFromPlayStoreGermanPng from '../../assets/download-from-play-store-german.png';
import DownloadFromAppStoreGermanSvg from '../../assets/download-on-the-app-store-german.svg';
import { useTranslation } from 'react-i18next';
import type { MobileOS } from '@pflegenavi/shared/utils';
import type { SupportedLocales } from '@pflegenavi/frontend/localization';
import { useLocale } from '@pflegenavi/frontend/localization';

interface DownloadAppFromStoreProps {
  platform: MobileOS;
}

const links: Record<MobileOS, string> = {
  Other: FAMILY_MEMBER_PLAY_STORE_LINK,
  iPad: FAMILY_MEMBER_APP_STORE_LINK,
  Android: FAMILY_MEMBER_PLAY_STORE_LINK,
  iOS: FAMILY_MEMBER_APP_STORE_LINK,
};

const androidSvg: Record<SupportedLocales, string> = {
  'de-AT': DownloadFromPlayStoreGermanPng,
  'en-GB': DownloadFromPlayStoreSvg,
  cimode: DownloadFromPlayStoreGermanPng,
};

const iosSvg: Record<SupportedLocales, string> = {
  'de-AT': DownloadFromAppStoreGermanSvg,
  'en-GB': DownloadFromAppStoreSvg,
  cimode: DownloadFromAppStoreGermanSvg,
};

const altTexts: Record<MobileOS, string> = {
  Other: 'download from play store',
  Android: 'download from play store',
  iOS: 'download from app store',
  iPad: 'download from app store',
};

const svg: Record<MobileOS, Record<SupportedLocales, string>> = {
  Other: androidSvg,
  Android: androidSvg,
  iOS: iosSvg,
  iPad: iosSvg,
};

const renderAppStoreButton = (platform: MobileOS, locale: SupportedLocales) => {
  const link = links[platform];
  const imgSrc = svg[platform][locale];
  const altText = altTexts[platform];

  return (
    <a href={link}>
      <img height={80} src={imgSrc} alt={altText} />
    </a>
  );
};

export const DownloadAppFromStore: FC<DownloadAppFromStoreProps> = ({
  platform,
}) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{ width: '100%', height: '100%', bgcolor: '#FBE3DE', p: 3 }}
    >
      <img width={200} src={PflegenaviLogoSvg} alt="Pflegenavi" />
      <Typography sx={{ textAlign: 'center' }}>
        {t('use-fm-app-mobile-download')}
      </Typography>
      {renderAppStoreButton(platform, locale)}
    </Stack>
  );
};
