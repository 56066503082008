import type { FC } from 'react';
import * as React from 'react';
import { CircularProgress, Grid, Stack } from '@mui/material';
import { AccountStatusCard } from './AccountStatusCard';
import { FamilyMemberProfileInfo } from './FamilyMemberProfileForm';
import { useFamilyMemberProfile } from '@pflegenavi/frontend/api-nursing-home';

export const FamilyMemberProfileDetails: FC = () => {
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFamilyMemberProfile({});

  if (isUserProfileLoading) {
    return (
      <Stack alignItems="center">
        <CircularProgress
          color="primary"
          variant="indeterminate"
          sx={{ mt: 8 }}
        />
      </Stack>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={8}>
        <FamilyMemberProfileInfo
          firstName={userProfile?.user?.firstName ?? ''}
          lastName={userProfile?.user?.lastName ?? ''}
          email={userProfile?.user?.eMail ?? ''}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AccountStatusCard
          active={true}
          activeSince={userProfile?.user.createdAt}
        />
      </Grid>
    </Grid>
  );
};
