import JsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { TransactionSourceType, TransactionType } from '@pflegenavi/shared/api';
import {
  useFormatDate,
  useFormatting,
} from '@pflegenavi/frontend/localization';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import type { ReceiptAttachment } from './utils/getImagesFromReceiptIds';

import { calculateImagePlacement } from '@pflegenavi/shared/pdf-generation';
import { formatResidentPdfTableRowModel } from './utils/formatResidentPdfTableRowModel';
import type { PdfResident } from './interfaces/PdfResident';
import { format } from 'date-fns';
import { makeSafeFilename } from '@pflegenavi/shared/utils';

import imageCompression from 'browser-image-compression';
import { getTypeString } from './utils/getTypeString';

// colors used
const DIVIDER_GREY = '#F2F2F2';
const PINK = '#FBE3DE';
const TITLE_GREY = '#919EAB';
const ROWS_TEXT_GREY = '#637381';

// baseline margins applied to all elements rendered to be properly aligned and do not overlap with the header/footer, except the header and footer which only have the left and right baseline margins
export const BASELINE_TOP_MARGIN = 40;
const BASELINE_BOTTOM_MARGIN = 30;
const BASELINE_LEFT_MARGIN = 10;
const BASELINE_RIGHT_MARGIN = 10;

const rowZeroHeight = 0.5;

interface HandlePrintProps {
  dateFrom: Date;
  dateTo: Date;
  resident: PdfResident;
  amountOfDepositsCents: number;
  numberOfDeposits: number;
  numberOfDepositCancellations: number;
  amountOfWithdrawalsCents: number;
  numberOfWithdrawals: number;
  numberOfWithdrawalCancellations: number;
  receiptImages: ReceiptAttachment[];
  pdfFiles: ReceiptAttachment[];
  balanceEndCents: number; // Balance at the end of the period
  logo?: string;
  allEntries: GetTransactionsWithReceiptDto[];
}

export interface Context {
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  fCurrencyCents: ReturnType<typeof useFormatting>['fCurrencyCents'];
  fDate: ReturnType<typeof useFormatDate>;
  t: TFunction<'translation', undefined>;
}

interface FormatAccountStatementRowOptions {
  fDate: ReturnType<typeof useFormatDate>;
  fCurrency: ReturnType<typeof useFormatting>['fCurrency'];
  fCurrencyCents: ReturnType<typeof useFormatting>['fCurrencyCents'];
  t: TFunction<'translation', undefined>;
}

interface AccountStatementRowModel {
  sequenceNumber: string;
  date: string;
  content: string;
  typeOfEntry: string; // First (bold) line of the entry
  serviceProvider?: string; // Second line of the entry - only applicable for receipts (that are not barauslage)
  note?: string; // Applicable for all types, but may or may not be present
  moneyIn: string;
  moneyOut: string;
  balance: string;
}

// eslint-disable-next-line complexity
function formatAccountStatementRow(
  rowData: GetTransactionsWithReceiptDto,
  previousBalance: number,
  previousSequenceNumber: number,
  imageCount: number,
  pdfCount: number,
  options: FormatAccountStatementRowOptions,
  automaticTransactionNumberingEnabled: boolean
): AccountStatementRowModel {
  const note =
    rowData.sourceType === TransactionSourceType.RecurringItem
      ? rowData.recurringItem?.name
      : rowData.notes;

  const serviceProvider = rowData.serviceProvider
    ? `${options.t(`receiptType.${rowData.receiptType ?? 'Andere'}`)} - ${
        rowData.serviceProvider
      }`
    : undefined;

  const attachmentsCount =
    (imageCount > 0 &&
      pdfCount > 0 &&
      `${options.t('resident-pdf.receipt', {
        count: imageCount,
      })} + ${options.t('resident-pdf.pdf', { count: pdfCount })}`) ||
    (imageCount > 0 &&
      `${options.t('resident-pdf.receipt', { count: imageCount })}`) ||
    (pdfCount > 0 && `${options.t('resident-pdf.pdf', { count: pdfCount })}`) ||
    undefined;

  return {
    sequenceNumber: automaticTransactionNumberingEnabled
      ? rowData.sequenceID ?? '--'
      : String(previousSequenceNumber + 1),
    date: options.fDate(rowData.date, YEAR_MONTH_DAY_SHORT_FORMAT),
    typeOfEntry: getTypeString(
      rowData.sourceType,
      rowData.receiptType,
      rowData.receiptDate
        ? options.fDate(rowData.receiptDate, YEAR_MONTH_DAY_SHORT_FORMAT)
        : undefined,
      options
    ),
    serviceProvider,
    note,
    moneyIn:
      rowData.type === TransactionType.DEPOSIT
        ? options.fCurrencyCents(rowData.amountInCent)
        : '-- ',
    moneyOut:
      rowData.type === TransactionType.WITHDRAWAL
        ? options.fCurrencyCents(Math.abs(rowData.amountInCent))
        : '-- ',
    balance: options.fCurrencyCents(previousBalance + rowData.amountInCent),
    content: [
      serviceProvider,
      note,
      ...(attachmentsCount
        ? [`${options.t('common.attachments')}: ${attachmentsCount}`]
        : []),
    ]
      .filter((v) => Boolean(v))
      .join(`\n`)
      .trim(),
  };
}

// eslint-disable-next-line complexity
export function drawAccountStatement(
  context: Context,
  doc: JsPDF,
  entries: GetTransactionsWithReceiptDto[],
  balanceStart: number,
  receiptImages: ReceiptAttachment[],
  pdfFiles: ReceiptAttachment[],
  dateFrom: Date,
  automaticTransactionNumberingEnabled: boolean
): void {
  const { fCurrency, fCurrencyCents, fDate, t } = context;

  const countAttachmentsReducer = (
    acc: Map<string, number>,
    attachment: ReceiptAttachment
  ) => {
    const numberOfAttachments = acc.get(attachment.transactionData.id) ?? 0;
    acc.set(attachment.transactionData.id, numberOfAttachments + 1);
    return acc;
  };

  const receiptImageNumberMap = receiptImages.reduce(
    countAttachmentsReducer,
    new Map<string, number>()
  );
  const receiptPdfNumberMap = pdfFiles.reduce(
    countAttachmentsReducer,
    new Map<string, number>()
  );

  let previousBalance = balanceStart;
  let previousSequenceNumber = 0;
  const formattedEntries = entries.map((row) => {
    const imageCount = receiptImageNumberMap.get(row.id) ?? 0;
    const pdfCount = receiptPdfNumberMap.get(row.id) ?? 0;
    const result = formatAccountStatementRow(
      row,
      previousBalance,
      previousSequenceNumber,
      imageCount,
      pdfCount,
      {
        t,
        fCurrency,
        fCurrencyCents,
        fDate,
      },
      automaticTransactionNumberingEnabled
    );
    previousBalance += row.amountInCent;
    previousSequenceNumber++;
    return result;
  });

  if (formattedEntries.length > 0) {
    formattedEntries.unshift({
      sequenceNumber: automaticTransactionNumberingEnabled ? '--' : '0',
      serviceProvider: undefined,
      moneyIn: '-- ',
      moneyOut: '-- ',
      balance: fCurrencyCents(balanceStart),
      date: fDate(dateFrom, YEAR_MONTH_DAY_SHORT_FORMAT),
      typeOfEntry: `** ${t('resident-pdf.document.balance-start')} **`,
      content: t('resident-pdf.document.balance-start-info'),
      note: undefined,
    });
  }

  autoTable(doc, {
    head: [
      [
        {
          content: automaticTransactionNumberingEnabled
            ? 'ID'
            : `${t('common.number')}.`,
          styles: {
            halign: automaticTransactionNumberingEnabled ? 'left' : 'right',
            cellWidth: automaticTransactionNumberingEnabled ? 18 : undefined,
            cellPadding: {
              right: 4.8,
              top: 1.7,
            },
          },
        },
        t('common.date'),
        t('common.details'),
        {
          content: t('common.deposit'),
          styles: {
            halign: 'right',
            cellWidth: 27, // Enough for e.g. € 49.000,00
            cellPadding: {
              right: 0.5,
              top: 1.7,
            },
          },
        },
        {
          content: t('common.withdrawal'),
          styles: {
            halign: 'right',
            cellWidth: 27, // Enough for e.g. € 49.000,00
            cellPadding: {
              right: 0.5,
              top: 1.7,
            },
          },
        },
        {
          content: t('common.accountBalance'),
          styles: {
            halign: 'right',
            cellWidth: 27, // Enough for € 49.000,00
            cellPadding: {
              right: 2,
              top: 1.7,
            },
          },
        },
      ],
    ],
    body: [
      // fill array with duplicate data every row twice, we need that for styling purpose, the second row will be used as border bottom
      ...formattedEntries.reverse().map((row) => [
        row.sequenceNumber,
        row.date,
        // Add a new line to the content to leave room for the bold title added in didDrawCell
        row.content ? `\n${row.content}` : '',
        row.moneyIn,
        row.moneyOut,
        row.balance,
      ]),
    ],
    theme: 'plain',
    headStyles: {
      fontStyle: 'bold',
      fillColor: 'white',
      textColor: 'black',
      minCellHeight: 2,
    },
    styles: {
      lineWidth: {
        bottom: 0.3,
      },
      lineColor: DIVIDER_GREY,
    },
    rowPageBreak: 'avoid',
    columnStyles: {
      0: {
        cellWidth: automaticTransactionNumberingEnabled ? 18 : 12,
        halign: automaticTransactionNumberingEnabled ? 'left' : 'right',
        cellPadding: {
          right: 5,
          top: 1.7,
        },
      },
      1: {
        cellWidth: 22, // Wide enough for 25.06.2023 and 25/06/2023
      },
      2: {},
      3: {
        halign: 'right',
        cellPadding: {
          top: 2,
        },
      },
      4: {
        halign: 'right',
        cellPadding: {
          top: 2,
        },
      },
      5: {
        halign: 'right',
        cellPadding: {
          right: 1.4,
          top: 2,
        },
      },
    },
    // willDrawCell: function (data) {
    //   if (data.row.index % 2 === 0 && data.row.section === 'body') {
    //     // Check if it's the 3rd row
    //     data.row.height = rowZeroHeight; // Set the height of the row to zero
    //   }
    // },
    // didParseCell: function (data) {
    //   if (data.row.index % 2 === 0 && data.row.section === 'body') {
    //     // Check if it's the 3rd row
    //     data.cell.styles.fillColor = DIVIDER_GREY; // Set the background color for the cell
    //   } else {
    //     data.cell.styles.fillColor = 'white';
    //   }
    // },
    didDrawPage: function (data) {
      if (data.pageNumber === 1) {
        // Set Title Style
        doc.setFontSize(12);
        doc.setTextColor(TITLE_GREY);
        // @ts-expect-error it doesn't let me not specify the font family
        doc.setFont(undefined, 'bold');

        // Get Page Height
        const titlePos = data.settings.startY - 5;

        // Add Title
        doc.text(
          t('resident-pdf.document.transactions'),
          data.settings.margin.left + 1.5,
          titlePos
        );
      }
      // If there is no data to show, display a message
      if (entries.length === 0) {
        drawNoDataTableText(
          doc,
          context.t('resident-pdf.document.no-data-table'),
          data.settings.startY
        );
      }
    },
    didDrawCell: (data) => {
      if (
        data.column.index === 2 &&
        data.row.section === 'body' &&
        data.row.index >= 0 // -1 on page break
      ) {
        // We add the title in bold here,
        // behause having different text formatting in one cell is not possible otherwise

        const paddingLeft = 1.75;
        const paddingTop = 4.8;

        doc.setFontSize(10);
        // @ts-expect-error it doesn't let me not specify the font family
        doc.setFont(undefined, 'bold');

        const row = formattedEntries[data.row.index];

        doc.text(
          `${row.typeOfEntry}`,
          data.cell.x + paddingLeft,
          data.cell.y + paddingTop
        );
      }
    },
    // @ts-expect-error again function exists but poor typing
    startY: doc.lastAutoTable?.finalY ? doc.lastAutoTable.finalY + 25 : 0,
    margin: {
      top: BASELINE_TOP_MARGIN,
      bottom: BASELINE_BOTTOM_MARGIN,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
}

// TODO: Only used in stories
export function drawDeposits(
  context: Context,
  doc: JsPDF,
  deposits: GetTransactionsWithReceiptDto[]
): void {
  const { fCurrency, fCurrencyCents, fDate, t } = context;
  const formattedDeposits = deposits
    .map((row) =>
      formatResidentPdfTableRowModel(row, '--', '--', {
        t,
        fCurrency,
        fCurrencyCents,
        fDate,
      })
    )
    .map((deposit, index) => ({
      ...deposit,
      index: index + 1,
    }));

  // table
  autoTable(doc, {
    head: [
      [
        `${t('common.number')}.`,
        t('common.type'),
        t('common.info'),
        {
          content: t('common.amount'),
          styles: {
            halign: 'right',
            cellWidth: 35,
            cellPadding: {
              right: 7,
              top: 1.7,
            },
          },
        },
        t('common.date'),
      ],
    ],
    body: [
      // fill array with duplicate data every row twice, we need that for styling purpose, the second row will be used as border bottom
      ...formattedDeposits.map((row) => [
        row.index,
        row.typeString,
        row.info,
        row.amountString,
        row.dateString,
      ]),
    ],
    theme: 'plain',
    headStyles: {
      fontStyle: 'bold',
      fillColor: 'white',
      textColor: 'black',
      minCellHeight: 2,
    },
    styles: {
      lineWidth: {
        bottom: 0.3,
      },
      lineColor: DIVIDER_GREY,
    },
    columnStyles: {
      3: {
        halign: 'right',
        cellPadding: {
          right: 6.4,
          top: 2,
        },
      },
      4: {
        cellWidth: 25,
      },
    },
    // willDrawCell: function (data) {
    //   if (data.row.index % 2 === 0 && data.row.section === 'body') {
    //     // Check if it's the 3rd row
    //     data.row.height = rowZeroHeight; // Set the height of the row to zero
    //   }
    // },
    // didParseCell: function (data) {
    //   if (data.row.index % 2 === 0 && data.row.section === 'body') {
    //     // Check if it's the 3rd row
    //     data.cell.styles.fillColor = DIVIDER_GREY; // Set the background color for the cell
    //   } else {
    //     data.cell.styles.fillColor = 'white';
    //   }
    // },
    didDrawPage: function (data) {
      if (data.pageNumber === 1) {
        // Set Title Style
        doc.setFontSize(12);
        doc.setTextColor(TITLE_GREY);
        // @ts-expect-error it doesn't let me not specify the font family
        doc.setFont(undefined, 'bold');

        // Get Page Height
        const titlePos = data.settings.startY - 5;

        // Add Title
        doc.text(
          t('resident-pdf.document.deposits'),
          data.settings.margin.left + 1.5,
          titlePos
        );
      }
      // If there is no data to show, display a message
      if (deposits.length === 0) {
        drawNoDataTableText(
          doc,
          context.t('resident-pdf.document.no-data-table'),
          data.settings.startY
        );
      }
    },
    // @ts-expect-error again function exists but poor typing
    startY: doc.lastAutoTable?.finalY ? doc.lastAutoTable.finalY + 25 : 0,
    margin: {
      top: BASELINE_TOP_MARGIN,
      bottom: BASELINE_BOTTOM_MARGIN,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
}

// TODO: Only used in stories
export function drawWithdrawals(
  context: Context,
  doc: JsPDF,
  startY: number,
  withdrawals: GetTransactionsWithReceiptDto[],
  receiptImages: ReceiptAttachment[],
  pdfFiles: ReceiptAttachment[]
): void {
  const countAttachmentsReducer = (
    acc: Map<string, number>,
    attachment: ReceiptAttachment
  ) => {
    const numberOfAttachments = acc.get(attachment.transactionData.id) ?? 0;
    acc.set(attachment.transactionData.id, numberOfAttachments + 1);
    return acc;
  };

  const receiptImageNumberMap = receiptImages.reduce(
    countAttachmentsReducer,
    new Map<string, number>()
  );
  const receiptPdfNumberMap = pdfFiles.reduce(
    countAttachmentsReducer,
    new Map<string, number>()
  );

  const { fCurrency, fCurrencyCents, fDate, t } = context;

  const formattedWithdrawals = withdrawals
    .map((row) => ({
      ...formatResidentPdfTableRowModel(row, '--', '--', {
        t,
        fCurrency,
        fCurrencyCents,
        fDate,
      }),
      receiptImageNumber: receiptImageNumberMap.get(row.id) ?? 0,
      receiptPdfNumber: receiptPdfNumberMap.get(row.id) ?? 0,
    }))
    .map((withdrawal, index) => ({
      ...withdrawal,
      index: index + 1,
      typeString:
        withdrawal.sourceType === TransactionSourceType.Receipt
          ? 'Standard'
          : withdrawal.typeString,
      receiptType:
        withdrawal.sourceType === TransactionSourceType.RecurringItem
          ? withdrawal.info
          : withdrawal.receiptType,
    }));

  autoTable(doc, {
    head: [
      [
        `${t('common.number')}.`,
        t('common.type'),
        t('receipts.type'),
        t('receipts.serviceProvider'),
        {
          content: t('common.amount'),
          styles: {
            halign: 'right',
            cellWidth: 35,
            cellPadding: {
              right: 7,
              top: 1.7,
            },
          },
        },
        t('common.date'),
        t('common.attachments'),
      ],
    ],
    body: [
      // eslint-disable-next-line complexity
      ...formattedWithdrawals.map((row) => [
        row.index,
        row.typeString,
        row.receiptType ?? '--',
        row.serviceProvider ?? '--',
        row.amountString,
        row.dateString,
        (row.receiptImageNumber > 0 &&
          row.receiptPdfNumber > 0 &&
          `${row.receiptImageNumber} + ${row.receiptPdfNumber} PDF`) ||
          (row.receiptImageNumber > 0 && `${row.receiptImageNumber}`) ||
          (row.receiptPdfNumber > 0 && `${row.receiptPdfNumber} PDF`) ||
          '--',
      ]),
    ],
    theme: 'plain',
    headStyles: {
      fontStyle: 'bold',
      fillColor: 'white',
      textColor: 'black',
      minCellHeight: 2,
    },
    styles: {
      lineWidth: { bottom: 0.3 },
      lineColor: DIVIDER_GREY,
    },
    columnStyles: {
      3: {
        cellWidth: 35,
      },
      4: {
        halign: 'right',
        cellWidth: 35,
        cellPadding: {
          right: 6.4,
          left: 3,
          top: 2,
        },
      },
      5: {
        cellWidth: 25,
      },
      6: {
        cellWidth: 25,
      },
    },
    didDrawPage: function (data) {
      if (data.pageNumber === 1) {
        // Set Title Style
        doc.setFontSize(12);
        doc.setTextColor(TITLE_GREY);
        // @ts-expect-error it doesn't let me not specify the font family
        doc.setFont(undefined, 'bold');

        // Get Page Height
        const titlePos = data.settings.startY - 5;

        // Add Title
        doc.text(
          t('resident-pdf.document.withdrawals'),
          data.settings.margin.left + 1.5,
          titlePos
        );
      }
      // If there is no data to show, display a message
      if (withdrawals.length === 0) {
        drawNoDataTableText(
          doc,
          context.t('resident-pdf.document.no-data-table'),
          data.settings.startY
        );
      }
    },
    startY: startY,
    margin: {
      top: BASELINE_TOP_MARGIN,
      bottom: BASELINE_BOTTOM_MARGIN,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
}

const drawNoDataTableText = (doc: JsPDF, text: string, startY: number) => {
  const fontSize = 11;
  doc.setFontSize(fontSize);
  doc.setTextColor(TITLE_GREY);
  const pageSize = doc.internal.pageSize;
  const textWidth =
    (doc.getStringUnitWidth(text) * fontSize) / doc.internal.scaleFactor;
  const textOffset = (pageSize.width - textWidth) / 2;

  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');
  doc.text(text, textOffset, startY + 15);
};

interface DrawHeaderOpts {
  nursingHomeName: string;
  residentName: string;
  dateFrom: Date;
  dateTo: Date;
  logo?: string;
}

export const drawHeader = async (
  context: Context,
  doc: JsPDF,
  opts: DrawHeaderOpts
): Promise<void> => {
  const { fDate, t } = context;
  doc.setFillColor(PINK);

  const logo = opts.logo;

  // create a rectangle at the top of the page with a height of 10% of the page
  doc.rect(0, 0, doc.internal.pageSize.width, 35, 'F');

  // header
  autoTable(doc, {
    body: [
      [t('resident-pdf.document.nursing-home'), opts.nursingHomeName],
      [t('resident-pdf.document.resident'), opts.residentName],
      [
        t('resident-pdf.document.time-frame'),
        `${fDate(opts.dateFrom, YEAR_MONTH_DAY_SHORT_FORMAT)} - ${fDate(
          opts.dateTo,
          YEAR_MONTH_DAY_SHORT_FORMAT
        )}`,
      ],
    ],
    theme: 'plain',
    columnStyles: {
      0: {
        fontStyle: 'bold',
        cellWidth: 30,
      },
    },
    styles: {
      cellPadding: 0,
      // minCellHeight: 35,
    },
    startY: 12,
    useCss: true,
  });
  if (!logo) {
    return;
  }

  const img: HTMLImageElement = await new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (err) => {
      reject(err);
    };
    img.crossOrigin = 'Anonymous';
    img.src = logo;
  });

  const { x, y, width, height } = calculateImagePlacement(
    img,
    60,
    35,
    5,
    5,
    10
  );

  doc.addImage(
    img,
    'PNG',
    doc.internal.pageSize.width - 60 + x,
    y,
    width,
    height,
    undefined,
    'FAST'
  );
};

export const drawHeaders = async (
  context: Context,
  doc: JsPDF,
  opts: DrawHeaderOpts
): Promise<void> => {
  const pageCount = doc.internal.pages.length - 1;

  for (let i = 1; i <= pageCount; i++) {
    // Go to page i
    doc.setPage(i);

    await drawHeader(context, doc, opts);
  }
};

export const drawFooter = (
  context: Context,
  doc: JsPDF,
  pageNumber: number,
  pageCount: number
): void => {
  const { t } = context;
  const pageHeight = doc.internal.pageSize.height;

  autoTable(doc, {
    body: [
      [
        {
          content: 'help@pflegenavi.at',
          styles: {
            halign: 'left',
          },
        },
        {
          content: `${t('resident-pdf.document.page')}: ${String(
            pageNumber
          )} ${t('resident-pdf.document.of')} ${String(pageCount)}`,
          styles: {
            halign: 'right',
          },
        },
      ],
    ],
    theme: 'plain',
    startY: pageHeight - 15,
    margin: {
      bottom: 0,
      top: 0,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

export const drawFooters = (context: Context, doc: JsPDF): void => {
  const pageCount = doc.internal.pages.length - 1;

  for (let i = 1; i <= pageCount; i++) {
    // Go to page i
    doc.setPage(i);
    //Print Page 1 of 4 for example
    drawFooter(context, doc, i, pageCount);
  }
};

export const drawDate = (context: Context, doc: JsPDF): void => {
  const { fDate, t } = context;

  autoTable(doc, {
    body: [[{ content: fDate(new Date(), YEAR_MONTH_DAY_SHORT_FORMAT) }]],
    theme: 'plain',
    didDrawPage: function (data) {
      // Set Title Style
      doc.setFontSize(12);
      doc.setTextColor(TITLE_GREY);
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');

      // Get Page Height
      const titlePos = data.settings.startY - 2;

      // Add Title
      doc.text(t('common.date'), data.settings.margin.left + 1.5, titlePos);
    },
    startY: BASELINE_TOP_MARGIN + 12,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 13,
      left: BASELINE_LEFT_MARGIN + 155,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

interface GenerateHeaderOpts {
  dateFrom: Date;
  dateTo: Date;
  residentBalance: number;
  depositAmount: number;
  depositCount: number;
  depositCancellationCount: number;
  withdrawalAmount: number;
  withdrawalCount: number;
  withdrawalCancellationCount: number;
}

export const drawOverview = (
  context: Context,
  doc: JsPDF,
  opts: GenerateHeaderOpts
): void => {
  const { t, fDate, fCurrencyCents } = context;
  const {
    withdrawalAmount,
    depositAmount,
    dateFrom,
    dateTo,
    residentBalance,
    withdrawalCount,
    withdrawalCancellationCount,
    depositCount,
    depositCancellationCount,
  } = opts;
  const rowHeight = 7;
  autoTable(doc, {
    body: [
      [
        {
          content: `${t('resident-pdf.document.account-balance')} ${fDate(
            dateFrom,
            YEAR_MONTH_DAY_SHORT_FORMAT
          )}`,
          styles: { cellWidth: 50 },
        },
        {
          content: fCurrencyCents(
            residentBalance - depositAmount - withdrawalAmount
          ),
          styles: { fontStyle: 'bold' },
        },
        {
          content: '',
          styles: { cellWidth: 50 },
        },
      ],

      [
        {
          content: '',
          styles: { cellWidth: 30 },
        },
        {
          content: '',
          styles: { cellWidth: 17 },
        },
        {
          content: '',
          styles: { cellWidth: 30 },
        },
      ],

      [
        {
          content: t('resident-pdf.document.deposits'),
          styles: { cellWidth: 50 },
        },
        {
          content: fCurrencyCents(depositAmount),
          styles: { cellWidth: 17 },
        },
        {
          content: `${depositCount}x ${
            depositCount === 1
              ? t('resident-pdf.document.deposit')
              : t('resident-pdf.document.deposits')
          }${
            depositCancellationCount > 0
              ? ` (${depositCancellationCount} ${t(
                  'resident-pdf.document.cancellation',
                  { count: depositCancellationCount }
                )})`
              : ''
          }`,
          styles: {
            cellWidth: 65,
            textColor: ROWS_TEXT_GREY,
          },
        },
      ],

      [
        {
          content: t('resident-pdf.document.withdrawals'),
          styles: { cellWidth: 50 },
        },
        {
          content: fCurrencyCents(withdrawalAmount),
          styles: { cellWidth: 17 },
        },
        {
          content: `${withdrawalCount}x ${
            withdrawalCount === 1
              ? t('resident-pdf.document.withdrawal')
              : t('resident-pdf.document.withdrawals')
          }${
            withdrawalCancellationCount > 0
              ? ` (${withdrawalCancellationCount} ${t(
                  'resident-pdf.document.cancellation',
                  { count: withdrawalCancellationCount }
                )})`
              : ''
          }`,
          styles: {
            cellWidth: 65,
            textColor: ROWS_TEXT_GREY,
          },
        },
      ],

      [
        {
          content: '',
          styles: { cellWidth: 50 },
        },
        {
          content: '',
          styles: { cellWidth: 17 },
        },
        {
          content: '',
          styles: { cellWidth: 30 },
        },
      ],

      [
        {
          content: `${t('resident-pdf.document.account-balance')} ${fDate(
            dateTo,
            YEAR_MONTH_DAY_SHORT_FORMAT
          )}`,
          styles: { cellWidth: 50 },
        },
        {
          content: fCurrencyCents(residentBalance),
          styles: {
            cellWidth: 17,
            fontStyle: 'bold',
          },
        },
      ],
    ],
    theme: 'plain',
    tableWidth: 105,
    columnStyles: {
      1: {
        halign: 'right',
        cellWidth: 27,
        cellPadding: {
          right: 5,
          top: 2,
        },
      },
    },
    willDrawCell: function (data) {
      if (data.row.index === 1 || data.row.index === 4) {
        // Check if it's the 3rd row
        data.row.height = rowZeroHeight; // Set the height of the row to zero
      } else if (data.row.index === 5) {
        data.row.height = 4;
      } else {
        data.row.height = rowHeight; // Set the height of other rows to a default height
      }
    },
    didParseCell: function (data) {
      if (data.row.index === 1 || data.row.index === 4) {
        // Check if it's the 3rd row
        data.cell.styles.fillColor = DIVIDER_GREY; // Set the background color for the cell
      } else {
        data.cell.styles.fillColor = 'white';
      }
    },
    didDrawPage: function (data) {
      // Set Title Style
      doc.setFontSize(12);
      doc.setTextColor(TITLE_GREY);
      // @ts-expect-error it doesn't let me not specify the font family
      doc.setFont(undefined, 'bold');

      // Get Page Height
      const titlePos = data.settings.startY - 5;

      // Add Title
      doc.text(
        t('resident-pdf.document.account-overview'),
        data.settings.margin.left + 1.5,
        titlePos
      );
    },
    startY: BASELINE_TOP_MARGIN + 15,
    margin: {
      bottom: BASELINE_BOTTOM_MARGIN + 15,
      left: BASELINE_LEFT_MARGIN,
      right: BASELINE_RIGHT_MARGIN,
    },
  });
};

// eslint-disable-next-line complexity
function getImageLabel(image: ReceiptAttachment, context: Context) {
  const { t, fCurrency, fDate } = context;
  const { sequenceNumber, transactionData, count, receiptImagesLength } = image;
  const { receiptType, serviceProvider, amount, receiptDate } = transactionData;

  const formattedAmount = fCurrency(Math.abs(amount));
  const formattedDate = receiptDate
    ? ` ${t('common.dated')} ${fDate(receiptDate, YEAR_MONTH_DAY_SHORT_FORMAT)}`
    : '';

  const imagesInfo =
    receiptImagesLength > 1
      ? ` (${count} ${t('common.of')} ${receiptImagesLength})`
      : '';
  const formattedReceiptType = receiptType ? ` ${receiptType} -` : '';
  const formattedServiceProvider = serviceProvider
    ? ` ${
        serviceProvider.length > 23
          ? `${serviceProvider.substring(0, 20)}...`
          : serviceProvider
      } -`
    : '';

  return `#${sequenceNumber}:${formattedReceiptType}${formattedServiceProvider} ${formattedAmount}${formattedDate}${imagesInfo}`;
}

function getCancellationLabel(cancellationDate: Date, context: Context) {
  const { t, fDate } = context;

  const formattedDate = fDate(cancellationDate, YEAR_MONTH_DAY_SHORT_FORMAT);

  return t('resident-pdf.cancellation-note', { date: formattedDate });
}

async function resizeAndCompressImage(
  attachment: ReceiptAttachment
): Promise<File> {
  const imgData = await attachment.image.data;

  return await imageCompression(
    new File(
      [imgData],
      'file.' + attachment.image.fileType?.replace(/image\//, '') ?? '',
      {
        type: attachment.image.fileType ?? undefined,
      }
    ),
    {
      maxSizeMB: 2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
  );
}

function getImageDimensions(
  img: HTMLImageElement,
  maxWidth: number,
  maxHeight: number
) {
  const imgWidth = img.width;
  const imgHeight = img.height;
  const aspectRatio = imgWidth / imgHeight;

  // Calculate the width and height of the image on the page
  let width = Math.min(imgWidth, maxWidth);
  let height = width / aspectRatio;
  if (height > maxHeight) {
    height = maxHeight;
    width = height * aspectRatio;
  }
  return {
    width,
    height,
  };
}

// eslint-disable-next-line complexity
export const drawReceiptImages = async (
  context: Context,
  images: ReceiptAttachment[],
  doc: JsPDF
): Promise<void> => {
  // Define the width and height of each page
  const pageWidth = doc.internal.pageSize.getWidth() - 70;
  const pageHeight = doc.internal.pageSize.getHeight() - 70;

  const fontSize = 11;
  doc.setFontSize(fontSize);
  doc.setTextColor('black');
  // @ts-expect-error it doesn't let me not specify the font family
  doc.setFont(undefined, 'normal');

  // Loop through the images array
  for (let i = 0; i < images.length; i++) {
    // Add a new page to the PDF document
    doc.addPage();

    // Load the current image and get its width and height
    const compressedImage = await resizeAndCompressImage(images[i]);

    const img: HTMLImageElement = await new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = URL.createObjectURL(compressedImage);
    });

    const cancellation = images[i].transactionData.cancellation;
    const cancellationDate = images[i].transactionData.cancellationDate;

    const { width, height } = getImageDimensions(
      img,
      pageWidth,
      cancellation ? pageHeight - 20 : pageHeight
    );

    if (cancellation && cancellationDate) {
      const cancellationLabel = getCancellationLabel(cancellationDate, context);
      doc.text(cancellationLabel, 35, BASELINE_TOP_MARGIN + 15.5, {
        maxWidth: pageWidth,
      });
    }

    // Add the current image to the page
    doc.addImage({
      imageData: img,
      x: 35,
      y: BASELINE_TOP_MARGIN + (cancellation ? 25 : 13),
      width,
      height,
      // Enable compression, because the image is resized but in terms of quality it is not compressed
      compression: 'MEDIUM',
      format: 'JPEG',
    });

    const label = getImageLabel(images[i], context);

    doc.text(label, 35, BASELINE_TOP_MARGIN + 9.5, {
      maxWidth: pageWidth,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const usePrintPdfForResident = (
  automaticTransactionNumberingEnabled: boolean
) => {
  const { t } = useTranslation();
  const { fCurrency, fCurrencyCents } = useFormatting();
  const fDate = useFormatDate();

  const handlePrintPdfForResident = async ({
    dateFrom,
    dateTo,
    resident,
    receiptImages,
    pdfFiles,
    numberOfWithdrawals,
    numberOfWithdrawalCancellations,
    numberOfDeposits,
    numberOfDepositCancellations,
    amountOfDepositsCents,
    amountOfWithdrawalsCents,
    balanceEndCents,
    logo,
    allEntries,
  }: HandlePrintProps) => {
    const doc = new JsPDF();

    const context: Context = {
      t,
      fCurrency: (amount, opts) =>
        fCurrency(amount, {
          ...opts,
          useNonBreakingCharacters: false,
        }),
      fCurrencyCents: (amount, opts) =>
        fCurrencyCents(amount, {
          ...opts,
          useNonBreakingCharacters: false,
        }),
      fDate,
    };

    drawDate(context, doc);
    drawOverview(context, doc, {
      residentBalance: balanceEndCents,
      withdrawalCount: numberOfWithdrawals,
      withdrawalCancellationCount: numberOfWithdrawalCancellations,
      withdrawalAmount: amountOfWithdrawalsCents,
      depositCount: numberOfDeposits,
      depositCancellationCount: numberOfDepositCancellations,
      depositAmount: amountOfDepositsCents,
      dateFrom,
      dateTo,
    });

    const balanceStartCents =
      balanceEndCents - amountOfDepositsCents - amountOfWithdrawalsCents;

    drawAccountStatement(
      context,
      doc,
      allEntries,
      balanceStartCents,
      receiptImages,
      pdfFiles,
      dateFrom,
      automaticTransactionNumberingEnabled
    );

    if (receiptImages.length > 0) {
      await drawReceiptImages(context, receiptImages, doc);
    }

    // display the header, intentionally left here in the bottom
    await drawHeaders(context, doc, {
      residentName: `${resident.firstName} ${resident.lastName}`,
      dateTo,
      dateFrom,
      nursingHomeName: resident.nursingHome.name,
      logo,
    });

    drawFooters(context, doc);

    const documentType = t('transactions.account-overview.pdfName');
    const dateFromString = format(dateFrom, 'yyyy-MM-dd');
    const dateToString = format(dateTo, 'yyyy-MM-dd');

    const title = `${resident.lastName} ${resident.firstName} - ${documentType} - ${dateFromString} - ${dateToString}.pdf`;

    // Uncomment to open the pdf in a new tab to debug more easily
    // window.open(doc.output('bloburl'));

    return doc.save(makeSafeFilename(title));
  };

  return {
    handlePrintPdfForResident,
  };
};
