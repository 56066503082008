import type { FC } from 'react';
import React, { useCallback, useState } from 'react';
import { Button, Container, Grid } from '@mui/material';
import { HeaderBreadcrumbs, Stack } from '@pflegenavi/web-components';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import { YEAR_MONTH_FORMAT } from '@pflegenavi/shared/constants';
import { useLocale } from '@pflegenavi/frontend/localization';
import { useTranslation } from 'react-i18next';
import { ResidentCard } from '../components/dashboard/ResidentCard';
import { InfoBox } from '../components/dashboard/InfoBox';
import { LatestFMTransactions } from '../components/dashboard/LatestFMTransactions';
import { LatestFMPayments } from '../components/dashboard/LatestFMPayment';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import {
  usePaymentInfoByResidentIdAsFamilyMember,
  useResidentSummary,
  useTransactionApi,
  useTransactionsForFamilyMember,
} from '@pflegenavi/frontend/api-nursing-home';
import { ManualPaymentFormModal } from '../components/dashboard/ManualPaymentFormModal';
import {
  PaymentInitiatedBy,
  PendingChargePaymentStatus,
  ResidentState,
} from '@pflegenavi/shared/api';
import { DownloadPdfButton } from '@pflegenavi/frontend/pdf-export';
import { isWithinInterval } from 'date-fns';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DashboardPageProps {}

// eslint-disable-next-line complexity
export const DashboardPage: FC<DashboardPageProps> = () => {
  const locale = useLocale();
  const { t } = useTranslation();
  const { selectedResidentId } = useFMContext();

  const [payNowModalOpen, setPayNowModalOpen] = useState(false);

  const transactionsApi = useTransactionApi();

  const { data: residentSummary, isLoading: isSummaryLoading } =
    useResidentSummary(selectedResidentId ?? '');

  const { data: transactionsResident, isLoading: isLoadingTransactions } =
    useTransactionsForFamilyMember(selectedResidentId ?? '', []);

  const { data: paymentInfo } = usePaymentInfoByResidentIdAsFamilyMember(
    selectedResidentId ?? ''
  );

  const getResidentTransactions = useCallback(
    (params: { dateFrom: Date; dateTo: Date }) => {
      if (isLoadingTransactions) {
        return Promise.resolve([]);
      }
      return Promise.resolve(
        transactionsResident
          ?.filter((transaction) => {
            return isWithinInterval(transaction.date, {
              start: params.dateFrom,
              end: params.dateTo,
            });
          })
          ?.reverse() ?? []
      );
    },
    [isLoadingTransactions, transactionsResident]
  );

  const ongoingPayment =
    paymentInfo?.status?.status === PendingChargePaymentStatus.Pending;
  const failedPayment =
    paymentInfo?.status?.status === PendingChargePaymentStatus.Failed;
  const frozenPayment =
    paymentInfo?.status?.status === PendingChargePaymentStatus.Frozen;
  const disabledPayment =
    paymentInfo?.status?.status ===
    PendingChargePaymentStatus.DisabledForNursingHome;

  const handlePayButton = useCallback(() => {
    if (!residentSummary?.resident) {
      return;
    }

    setPayNowModalOpen(true);
  }, [residentSummary?.resident]);

  const paymentByFamilyMember =
    paymentInfo?.initiatedBy === PaymentInitiatedBy.FamilyMember;

  const showPayButton =
    paymentByFamilyMember &&
    residentSummary?.resident?.state === ResidentState.active;

  const disablePayButton = !(
    ongoingPayment !== undefined &&
    !ongoingPayment &&
    !failedPayment &&
    !frozenPayment &&
    !disabledPayment &&
    paymentByFamilyMember
  );

  return (
    <Container sx={{ maxWidth: 1400 }}>
      <HeaderBreadcrumbs
        heading={t('dashboard.main')}
        links={[
          { name: t('dashboard.main'), href: fmAppMainPages.DASHBOARD },
          { name: new Date().toLocaleDateString(locale, YEAR_MONTH_FORMAT) },
        ]}
        action={
          showPayButton ? (
            <div
              title={
                disablePayButton
                  ? t('fm-triggered-payments.pay-disabled-pending-payment')
                  : ''
              }
            >
              <Button
                variant="contained"
                disabled={disablePayButton}
                onClick={handlePayButton}
              >
                {t('fm-triggered-payments.pay-now')}
              </Button>
            </div>
          ) : null
        }
      />
      <ManualPaymentFormModal
        key={payNowModalOpen.toString()}
        open={payNowModalOpen}
        setOpen={setPayNowModalOpen}
      />
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={5}>
          <ResidentCard
            resident={residentSummary?.resident}
            isResidentLoading={isSummaryLoading}
          />
        </Grid>
        <Grid item xs={12} md={7}>
          <InfoBox
            resident={residentSummary?.resident}
            isResidentLoading={isSummaryLoading}
            handleManualPayment={handlePayButton}
            paymentByFamilyMember={paymentByFamilyMember}
          />
        </Grid>
      </Grid>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 1, mt: 1 }}>
        <DownloadPdfButton
          getTransactionsResident={getResidentTransactions}
          resident={
            residentSummary?.resident
              ? {
                  ...residentSummary.resident,
                  entry_date: residentSummary.resident.moveInDate,
                  created_date: residentSummary.resident.createdDate,
                  nursingHome: {
                    name: residentSummary.resident.nursingHomeName,
                  },
                }
              : undefined
          }
          isLoadingResident={isSummaryLoading}
          getResidentBalanceUntilDate={
            transactionsApi.getResidentBalanceUntilDateAsFamilyMember
          }
          automaticTransactionNumberingEnabled
        />
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}>
          <LatestFMTransactions
            isLoading={isSummaryLoading}
            data={residentSummary?.latestTransactions ?? []}
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <LatestFMPayments
            isLoading={isSummaryLoading}
            data={residentSummary?.latestPayments ?? []}
            paymentInfo={paymentInfo}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
