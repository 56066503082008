import type { FC } from 'react';
import React from 'react';

import { useTranslation } from 'react-i18next';

import { Label } from '@pflegenavi/web-components';
import { Stack, Typography } from '@mui/material';

interface InactiveDirectDebitInfoProps {
  nursingHomePhone: string;
}

export const InactiveDirectDebitInfo: FC<InactiveDirectDebitInfoProps> = ({
  nursingHomePhone,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Label sx={{ mb: 2, p: 1.8 }} variant="filled" color="info">
        {t('mobile.family-member.settled-account')}
      </Label>
      <Stack direction="row" justifyContent="center">
        <Typography variant="h6" sx={{ mb: 2, fontWeight: 700 }}>
          {t('mobile.family-member.direct-debit-deactivated-settled')}
        </Typography>
      </Stack>

      <Typography variant="subtitle1">
        {t('mobile.family-member.contact-nursing-home-number', {
          phone: nursingHomePhone?.replace(/\s/g, '\xa0'),
        })}
      </Typography>
    </>
  );
};
