import type { FC } from 'react';
import * as React from 'react';
import { useCallback, useState } from 'react';
import {
  Box,
  Card,
  CloseModalIcon,
  FTextInput,
  IbanInput,
  Modal,
  ModalInner,
  MoneyInput,
} from '@pflegenavi/web-components';
import {
  Alert,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { getIn, useFormik } from 'formik';
import type { IManualPaymentFormValues } from './useManualPaymentValidationSchema';
import { useManualPaymentValidationSchema } from './useManualPaymentValidationSchema';
import {
  useResidentBalanceInfo,
  useResidentSummary,
} from '@pflegenavi/frontend/api-nursing-home';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { useTranslation } from 'react-i18next';
import { useFormatting } from '@pflegenavi/frontend/localization';
import {
  ManualPaymentStatus,
  useConfirmManualPayment,
} from '../../hooks/useConfirmManualPayment';

interface ManualPaymentFormModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

// eslint-disable-next-line complexity
export const ManualPaymentFormModal: FC<ManualPaymentFormModalProps> = ({
  open,
  setOpen,
}) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();

  const { selectedResidentId, familyMember } = useFMContext();

  const { manualPaymentStatus, confirm: confirmManualPayment } =
    useConfirmManualPayment();

  const { data: residentSummary, isLoading: isSummaryLoading } =
    useResidentSummary(selectedResidentId ?? '');

  const balance = residentSummary?.resident?.balance;

  const { data: balanceInfo, isLoading: isBalanceInfoLoading } =
    useResidentBalanceInfo({
      residentId: selectedResidentId ?? '',
    });

  const [error, setError] = useState(false);

  const { initialValues, validationSchema } = useManualPaymentValidationSchema({
    familyMember,
    balanceInfo,
    residentBalance: balance,
  });

  const handleSubmit = async (values: IManualPaymentFormValues) => {
    const { name, amount, iban, email } = values;
    const result = await confirmManualPayment({
      name,
      email,
      iban,
      amount,
    });
    if (result === ManualPaymentStatus.Success) {
      setOpen(false);
    }
    if (result === ManualPaymentStatus.Error) {
      setError(true);
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: handleSubmit,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
  });

  const { setFieldValue } = formik;

  const handleCloseEscape = (
    _: any,
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const handleCloseButton = () => {
    setOpen(false);
  };

  const handleChangeAmount = useCallback(
    (value: number | undefined) => {
      setFieldValue('amount', value ? value / 100 : undefined);
    },
    [setFieldValue]
  );

  if (isBalanceInfoLoading || isSummaryLoading) {
    return (
      <Modal open={open} handleClose={handleCloseEscape}>
        <ModalInner>
          <Box sx={{ width: 500 }}>
            <Card
              sx={{
                p: 3,
                height: 300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress />
            </Card>
          </Box>
        </ModalInner>
      </Modal>
    );
  }

  return (
    <Modal open={open} handleClose={handleCloseEscape}>
      <ModalInner>
        <Box sx={{ width: 800 }}>
          <Card
            sx={{
              p: 3,
              maxHeight: '95vh',
            }}
          >
            <Stack
              direction="row"
              alignItems="flex-start"
              justifyContent="space-between"
            >
              <Typography fontWeight={700} sx={{ mb: 2 }}>
                {t('fm-triggered-payments.amount-screen.new-payment')}
              </Typography>
              <CloseModalIcon onClick={handleCloseButton} />
            </Stack>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <Typography variant="body2" sx={{ pt: 0.2 }}>
                  {t('fm-triggered-payments.error.payment-failed')}
                </Typography>
              </Alert>
            )}
            <Stack sx={{ mb: 3 }} gap={1.5}>
              <Typography>
                {t('fm-triggered-payments.form-details.initiator-details')}:
              </Typography>
              <Stack direction="row" gap={2}>
                <FTextInput
                  id="name"
                  label={t('mobile.family-member.full-name-field')}
                  name="name"
                  formik={formik}
                  sx={{ minWidth: 280 }}
                />
                <FTextInput
                  sx={{ flex: 1 }}
                  id="email"
                  label={t('mobile.family-member.email-field')}
                  name="email"
                  type="email"
                  formik={formik}
                />
              </Stack>
              <IbanInput
                value={formik.values.iban}
                setValue={(newIban) => formik.setFieldValue('iban', newIban)}
                error={getIn(formik.errors, 'iban')}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="flex-start"
              sx={{ mb: 3 }}
              gap={4}
            >
              <Stack gap={1.5}>
                <Typography>
                  {' '}
                  {t('fm-triggered-payments.amount-screen.please-enter-amount')}
                  :
                </Typography>
                <MoneyInput
                  id="amount"
                  name="amount"
                  value={Math.round(formik.values.amount * 100)}
                  onBlur={formik.handleBlur}
                  disabled={formik.isSubmitting}
                  onChange={handleChangeAmount}
                  label={t('fm-triggered-payments.amount-screen.top-up-amount')}
                  sx={{ minWidth: 340 }}
                  InputProps={{
                    inputProps: {
                      min: 50,
                      max: 500,
                    },
                  }}
                  helperText={
                    getIn(formik.touched, 'amount') &&
                    getIn(formik.errors, 'amount')
                  }
                  error={Boolean(
                    getIn(formik.errors, 'amount') &&
                      getIn(formik.touched, 'amount')
                  )}
                />
              </Stack>
              <BalanceSagesCard
                balance={balance ? balance / 100 : 0}
                topUpAmount={
                  formik.values.amount?.toString().length > 0
                    ? formik.values.amount
                    : 0
                }
              />
            </Stack>
            <Typography
              variant="body2"
              sx={{
                color: 'grey.500',
                textAlign: 'justify',
                textAlignLast: 'center',
                mb: 1.5,
              }}
            >
              {t('fm-triggered-payments.form-details.legal-text')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                textAlign: 'center',
                marginX: 1,
                mb: 3,
              }}
            >
              {t('fm-triggered-payments.form-details.by-clicking-pay')}
            </Typography>
            <Stack direction="row" justifyContent="flex-end" gap={2}>
              <Button
                variant="outlined"
                disabled={manualPaymentStatus === ManualPaymentStatus.Loading}
                onClick={handleCloseButton}
              >
                {t('actions.cancel')}
              </Button>
              <LoadingButton
                loading={manualPaymentStatus === ManualPaymentStatus.Loading}
                disabled={
                  error ||
                  !formik.dirty ||
                  !formik.isValid ||
                  manualPaymentStatus === ManualPaymentStatus.Loading
                }
                variant="contained"
                onClick={async () => await formik.handleSubmit()}
              >
                {t('fm-triggered-payments.pay-now')} (
                {fCurrency(formik.values.amount)})
              </LoadingButton>
            </Stack>
          </Card>
        </Box>
      </ModalInner>
    </Modal>
  );
};

const BalanceSagesCard = ({
  balance,
  topUpAmount,
}: {
  balance: number;
  topUpAmount: number;
}) => {
  const { t } = useTranslation();
  const { fCurrency } = useFormatting();

  return (
    <Stack
      justifyContent="space-between"
      sx={{
        p: 1.5,
        bgcolor: 'primary.main',
        borderRadius: 2,
        flex: 1,
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize={16} sx={{ color: 'white' }}>
          {t('mobile.family-member.current-balance')}:
        </Typography>
        <Typography fontSize={16} sx={{ color: 'white' }}>
          {fCurrency(balance)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize={16} sx={{ color: 'white' }}>
          {t('fm-triggered-payments.amount-screen.top-up-amount')}:
        </Typography>
        <Typography fontSize={16} color="success.main">
          {fCurrency(topUpAmount)}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography fontSize={16} sx={{ color: 'white' }}>
          {t('fm-triggered-payments.amount-screen.new-balance')}:
        </Typography>
        <Typography fontSize={16} sx={{ color: 'white' }}>
          {fCurrency(balance + topUpAmount)}
        </Typography>
      </Stack>
    </Stack>
  );
};
