import { useCallback, useEffect, useState } from 'react';
import {
  isApiError,
  useInvalidatePaymentStatusByResidentId,
  usePaymentApi,
  useSubmitDirectDebitManualPayment,
} from '@pflegenavi/frontend/api-nursing-home';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { loadStripe } from '@stripe/stripe-js';

export enum ManualPaymentStatus {
  Idle,
  Loading,
  Success,
  Error,
}

interface ManualPaymentParams {
  amount: number;
  name: string;
  email: string;
  iban: string;
}

interface UseConfirmManualPaymentResult {
  error: string | undefined;
  manualPaymentStatus: ManualPaymentStatus;
  confirm: (params: ManualPaymentParams) => Promise<ManualPaymentStatus>;
}

export const useConfirmManualPayment = (): UseConfirmManualPaymentResult => {
  const api = usePaymentApi();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { selectedResidentId } = useFMContext();

  const invalidatePaymentStatus =
    useInvalidatePaymentStatusByResidentId(selectedResidentId);

  const [manualPaymentStatus, setManualPaymentStatus] = useState(
    ManualPaymentStatus.Idle
  );
  const [error, setError] = useState<undefined | string>(undefined);

  useEffect(() => {
    setError(undefined);
    setManualPaymentStatus(ManualPaymentStatus.Idle);
  }, []);

  const { mutateAsync: submitDirectDebitManualPayment } =
    useSubmitDirectDebitManualPayment(selectedResidentId ?? '');

  const confirm = useCallback(
    // eslint-disable-next-line complexity
    async ({
      name,
      email,
      iban,
      amount,
    }: ManualPaymentParams): Promise<ManualPaymentStatus> => {
      try {
        if (!selectedResidentId) {
          setManualPaymentStatus(ManualPaymentStatus.Error);
          enqueueSnackbar(t('fm-triggered-payments.payment-result.error'), {
            variant: 'error',
          });
          return ManualPaymentStatus.Error;
        }
        setManualPaymentStatus(ManualPaymentStatus.Loading);
        const result = await submitDirectDebitManualPayment({
          amount: Math.round(amount * 100),
        });

        if (isApiError(result)) {
          setManualPaymentStatus(ManualPaymentStatus.Error);
          enqueueSnackbar(t('fm-triggered-payments.payment-result.error'), {
            variant: 'error',
          });
          setError(result.message);
          return ManualPaymentStatus.Error;
        }

        const stripe = await loadStripe(result.publishableKey);

        if (stripe) {
          await new Promise((resolve) => setTimeout(resolve, 1000));

          const { error, paymentIntent } = await stripe.confirmSepaDebitPayment(
            result.paymentIntent,
            {
              payment_method: {
                sepa_debit: { iban },
                billing_details: { name, email },
              },
            }
          );

          if (!error) {
            if (paymentIntent?.id) {
              await api.setupConfirmPaymentIntent({
                body: {
                  paymentIntentId: paymentIntent.id,
                },
                params: {
                  residentId: selectedResidentId ?? '',
                },
              });
            }
            await invalidatePaymentStatus();
            setManualPaymentStatus(ManualPaymentStatus.Success);
            enqueueSnackbar(t('fm-triggered-payments.payment-result.success'), {
              variant: 'success',
            });
            return ManualPaymentStatus.Success;
          } else {
            setManualPaymentStatus(ManualPaymentStatus.Error);
            enqueueSnackbar(t('fm-triggered-payments.payment-result.error'), {
              variant: 'error',
            });
            return ManualPaymentStatus.Error;
          }
        }
      } catch (err) {
        setManualPaymentStatus(ManualPaymentStatus.Error);
        return ManualPaymentStatus.Error;
      }
      return ManualPaymentStatus.Error;
    },
    [
      api,
      enqueueSnackbar,
      invalidatePaymentStatus,
      selectedResidentId,
      submitDirectDebitManualPayment,
      t,
    ]
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (manualPaymentStatus === ManualPaymentStatus.Success) {
      timeout = setTimeout(() => {
        setManualPaymentStatus((status) => {
          if (status === ManualPaymentStatus.Success) {
            return ManualPaymentStatus.Idle;
          }
          return status;
        });
      }, 3000);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [manualPaymentStatus]);

  return {
    error,
    manualPaymentStatus,
    confirm,
  };
};
