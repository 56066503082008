import type { FC } from 'react';
import React from 'react';
import { Label } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';

interface BalanceStatusLabelProps {
  lowBalance: boolean;
  negativeBalance: boolean;
}

export const BalanceStatusLabel: FC<BalanceStatusLabelProps> = ({
  lowBalance,
  negativeBalance,
}) => {
  const { t } = useTranslation();

  if (lowBalance && !negativeBalance) {
    return (
      <Label color="warning" variant="filled">
        {t('common.low')}
      </Label>
    );
  }
  if (negativeBalance) {
    return (
      <Label color="error" variant="filled">
        {t('common.negative')}
      </Label>
    );
  }
  return null;
};
