import type { FC } from 'react';
import { Card, Stack, styled, Typography } from '@mui/material';
import { Label } from '@pflegenavi/web-components';
import { experimental_sx as sx } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { YEAR_MONTH_DAY_FORMAT } from '@pflegenavi/shared/constants';
import { useLocale } from '@pflegenavi/frontend/localization';

interface AccountStatusCardProps {
  active: boolean;
  activeSince: Date | undefined;
}

export const AccountStatusCard: FC<AccountStatusCardProps> = ({
  active,
  activeSince,
}) => {
  const { t } = useTranslation();
  const locale = useLocale();

  return (
    <Card>
      <RootContainer>
        <InnerContainer
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          spacing={1}
          gap={1}
        >
          {active ? (
            <>
              <Typography fontWeight={600}>
                {t('mobile.family-member.account-created-on')}:
              </Typography>
              <Label variant="filled" color="success" sx={{ color: 'white' }}>
                {new Date(activeSince ?? '').toLocaleDateString(
                  locale,
                  YEAR_MONTH_DAY_FORMAT
                )}
              </Label>
            </>
          ) : (
            <>
              <Typography fontWeight={600}>
                {t('mobile.family-member.current-account-status')}
              </Typography>
              <Label variant="filled" color="error" sx={{ color: 'white' }}>
                {t('mobile.family-member.inactive')}
              </Label>
            </>
          )}
        </InnerContainer>
      </RootContainer>
    </Card>
  );
};
const RootContainer = styled(Stack)(
  sx({
    p: 1,
    borderRadius: 3,
  })
);

const InnerContainer = styled(Stack)(
  sx({
    bgcolor: 'grey.200',
    paddingX: 2,
    paddingY: 3,
    borderRadius: 3,
  })
);
