import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResidentInSettlement from '../../assets/undraw_resident_in_settlement.svg';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const SettledDirectDebit = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" sx={{ mt: 7 }}>
      <img
        alt={t('mobile.family-member.direct-debit-deactivated-settled')}
        src={ResidentInSettlement}
        style={{ marginBottom: 35 }}
      />
      <Typography variant="h4" fontWeight="700" sx={{ mb: 2 }}>
        {t('mobile.family-member.settled-account')}
      </Typography>
      <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
        {t('mobile.family-member.direct-debit-deactivated-settled')}
      </Typography>
    </Stack>
  );
};
