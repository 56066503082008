import type { UseTableProps } from '@pflegenavi/web-components';
import { useTable } from '@pflegenavi/web-components';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

export enum TransactionStatusValues {
  ALL = 'All',
}

interface UseFmTransactionsTableResult
  extends UseTableProps<FmTransactionColumn, GetTransactionsWithReceiptDto> {
  reportingYear: Date;
  setReportingYear: (year: Date) => void;
  filterTerms: string;
  setFilterTerms: Dispatch<SetStateAction<string>>;
}

export enum FmTransactionColumn {
  SequenceID = 'sequenceID',
  Type = 'type',
  ReceiptType = 'receiptType',
  ServiceProvider = 'service provider',
  Amount = 'amount',
  Empty2 = 'empty2',
  Date = 'date',
  Receipt = 'receipt',
  Empty = 'empty',
}

export const useFmTransactionsTable = (): UseFmTransactionsTableResult => {
  const { setPage, setSelected, order, orderBy, ...tableProps } = useTable<
    GetTransactionsWithReceiptDto,
    FmTransactionColumn
  >({
    defaultRowsPerPage: 5,
    tableKey: 'familyMemberTransactions',
    defaultOrder: 'desc',
    defaultOrderBy: FmTransactionColumn.Date,
    sortKeys: {
      [FmTransactionColumn.SequenceID]: ['sequenceID'],
      [FmTransactionColumn.Type]: ['type'],
      [FmTransactionColumn.ServiceProvider]: ['serviceProvider'],
      [FmTransactionColumn.ReceiptType]: ['receiptType'],
      [FmTransactionColumn.Amount]: ['amount'],
      [FmTransactionColumn.Empty2]: [],
      [FmTransactionColumn.Date]: ['date', 'receiptCreatedOn', 'id'],
      [FmTransactionColumn.Receipt]: [],
      [FmTransactionColumn.Empty]: [],
    },
    sortComparator: {
      id: (a, b) => {
        return a.localeCompare(b);
      },
    },
  });

  const [filterTerms, setFilterTerms] = useState('');
  const [reportingYear, setReportingYear] = useState(new Date());

  const handleSetReportingYear = (month: Date) => {
    setPage(0);
    setReportingYear(month);
  };

  return {
    filterTerms,
    setFilterTerms,
    reportingYear,
    setReportingYear: handleSetReportingYear,
    setPage,
    setSelected,
    order,
    orderBy,
    ...tableProps,
  };
};
