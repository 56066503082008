import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { TransactionSourceType, TransactionType } from '@pflegenavi/shared/api';

export function prepareResidentOverviewData(
  transactionsResident: GetTransactionsWithReceiptDto[]
): {
  deposits: Array<GetTransactionsWithReceiptDto & { sequenceNumber: number }>;
  amountOfDepositsCents: number;
  numberOfDeposits: number;
  numberOfDepositCancellations: number;
  withdrawals: Array<
    GetTransactionsWithReceiptDto & { sequenceNumber: number }
  >;
  amountOfWithdrawalsCents: number;
  numberOfWithdrawals: number;
  numberOfWithdrawalCancellations: number;
} {
  const deposits = transactionsResident
    .map((transaction, index) => {
      return {
        ...transaction,
        sequenceNumber: index + 1,
      };
    })
    .filter(
      (transaction) =>
        (transaction.type === TransactionType.DEPOSIT &&
          !(
            transaction.sourceType === TransactionSourceType.CancelledReceipt
          )) ||
        transaction.sourceType === TransactionSourceType.CancelledManualDeposit
    );
  const withdrawals = transactionsResident
    .map((transaction, index) => {
      return {
        ...transaction,
        sequenceNumber: index + 1,
      };
    })
    .filter(
      (transaction) =>
        (transaction.type === TransactionType.WITHDRAWAL &&
          transaction.sourceType !==
            TransactionSourceType.CancelledManualDeposit) ||
        transaction.sourceType === TransactionSourceType.CancelledReceipt
    );

  const {
    numberOfDeposits,
    numberOfDepositCancellations,
    amountOfDepositsCents,
  } = deposits.reduce(
    (acc, deposit) => {
      // The number of deposits that were actually made for the resident, cancelled or not (no cancellation transactions)
      acc.numberOfDeposits +=
        deposit.sourceType === TransactionSourceType.CancelledManualDeposit
          ? 0
          : 1;
      // The number of cancellation transactions (must be equal to # of deposits that were cancelled over all time.
      // But we're not guaranteed that cancellation transaction and cancelled deposit are in the same period, and
      // since we want the number of cancellation transactions, we have to count those.)
      acc.numberOfDepositCancellations +=
        deposit.sourceType === TransactionSourceType.CancelledManualDeposit
          ? 1
          : 0;
      acc.amountOfDepositsCents += deposit.amountInCent;
      return acc;
    },
    {
      numberOfDeposits: 0,
      numberOfDepositCancellations: 0,
      amountOfDepositsCents: 0,
    }
  );

  const {
    numberOfWithdrawals,
    numberOfWithdrawalCancellations,
    amountOfWithdrawalsCents,
  } = withdrawals.reduce(
    (acc, withdrawal) => {
      acc.numberOfWithdrawals +=
        withdrawal.sourceType === TransactionSourceType.CancelledReceipt
          ? 0
          : 1;
      acc.numberOfWithdrawalCancellations +=
        withdrawal.sourceType === TransactionSourceType.CancelledReceipt
          ? 1
          : 0;
      acc.amountOfWithdrawalsCents += withdrawal.amountInCent;
      return acc;
    },
    {
      numberOfWithdrawals: 0,
      numberOfWithdrawalCancellations: 0,
      amountOfWithdrawalsCents: 0,
    }
  );

  return {
    deposits,
    amountOfDepositsCents,
    numberOfDeposits: numberOfDeposits,
    numberOfDepositCancellations: numberOfDepositCancellations,
    withdrawals,
    amountOfWithdrawalsCents,
    numberOfWithdrawals: numberOfWithdrawals,
    numberOfWithdrawalCancellations: numberOfWithdrawalCancellations,
  };
}
