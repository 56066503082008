import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResidentInSettlement from '../../assets/undraw_resident_in_settlement.svg';

export const FinalSettlementDirectDebit: FC<{ phone: string }> = ({
  phone,
}) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" sx={{ mt: 7 }}>
      <img
        alt={t('direct-debit.direct-debit-deactivated')}
        src={ResidentInSettlement}
        style={{ marginBottom: 35 }}
      />
      <Typography variant="h4" fontWeight="700" sx={{ mb: 2 }}>
        {t('direct-debit.direct-debit-deactivated')}
      </Typography>
      <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
        {phone
          ? t('direct-debit.direct-debit-settlement-nursing-home', {
              phone,
            })
          : t('direct-debit.direct-debit-settlement-nursing-home-no-phone')}
      </Typography>
    </Stack>
  );
};
