import React from 'react';

import { TableNoDataWithImage } from '@pflegenavi/web-components';
import { useTranslation } from 'react-i18next';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';

import NoRecordsSvg from '../../assets/undraw_no_records.svg';
import ServerNotReached from '../../assets/Server maintenance.svg';

interface Props {
  transactions: GetTransactionsWithReceiptDto[] | undefined;
  transactionsAvailableBeforeFilter: boolean;
  isFiltered: boolean;
  isError: boolean;
  colSpan: number;
}

// eslint-disable-next-line complexity
export const NoFmTransactionsData: React.FC<Props> = ({
  transactions,
  transactionsAvailableBeforeFilter,
  isFiltered,
  isError,
  colSpan,
}) => {
  const { t } = useTranslation();

  if (isError) {
    return (
      <TableNoDataWithImage
        colSpan={colSpan}
        image={ServerNotReached}
        height={200}
        text={t('table.no-connection')}
      />
    );
  }

  if (!isError && !transactionsAvailableBeforeFilter) {
    return (
      <TableNoDataWithImage
        colSpan={colSpan}
        image={NoRecordsSvg}
        height={220}
        text={t('table.no-data')}
      />
    );
  }

  if (
    transactionsAvailableBeforeFilter &&
    isFiltered &&
    transactions?.length === 0
  ) {
    return (
      <TableNoDataWithImage
        colSpan={colSpan}
        image={NoRecordsSvg}
        height={220}
        text={t('table.no-results')}
      />
    );
  }

  if (
    transactionsAvailableBeforeFilter &&
    !isFiltered &&
    transactions?.length === 0
  ) {
    return (
      <TableNoDataWithImage
        colSpan={colSpan}
        image={NoRecordsSvg}
        height={220}
        text={t('table.no-data-this-month')}
      />
    );
  }

  return null;
};
