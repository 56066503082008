import type { UseTableProps } from '@pflegenavi/web-components';
import { useTable } from '@pflegenavi/web-components';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import type { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';

export enum PaymentStatusValues {
  ALL = 'All',
}

interface UseFmPaymentsTableResult
  extends UseTableProps<FmPaymentColumn, GetTransactionsWithReceiptDto> {
  reportingYear: Date;
  setReportingYear: (year: Date) => void;
  filterTerms: string;
  setFilterTerms: Dispatch<SetStateAction<string>>;
}

export enum FmPaymentColumn {
  SequenceID = 'sequenceID',
  Type = 'type',
  Iban = 'iban',
  DateOfReceipt = 'date of receipt',
  Amount = 'amount',
}

export const useFmPaymentsTable = (): UseFmPaymentsTableResult => {
  const { setPage, setSelected, order, orderBy, ...tableProps } = useTable<
    GetTransactionsWithReceiptDto,
    FmPaymentColumn
  >({
    defaultRowsPerPage: 5,
    tableKey: 'familyMemberPayments',
    defaultOrder: 'desc',
    defaultOrderBy: FmPaymentColumn.DateOfReceipt,
    sortKeys: {
      [FmPaymentColumn.SequenceID]: ['sequenceID'],
      [FmPaymentColumn.Type]: ['type'],
      [FmPaymentColumn.Iban]: ['ibanLast4'],
      [FmPaymentColumn.DateOfReceipt]: ['date'],
      [FmPaymentColumn.Amount]: ['amount'],
    },
  });

  const [filterTerms, setFilterTerms] = useState('');
  const [reportingYear, setReportingYear] = useState(new Date());

  const handleSetReportingYear = (year: Date) => {
    setPage(0);
    setReportingYear(year);
  };

  return {
    filterTerms,
    setFilterTerms,
    reportingYear,
    setReportingYear: handleSetReportingYear,
    setPage,
    setSelected,
    order,
    orderBy,
    ...tableProps,
  };
};
