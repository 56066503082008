import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OutstandingReceiptsSettlement from '../../assets/undraw_outstanding_receipts.svg';

export const SettlementDirectDebit: FC<{ phone: string }> = ({ phone }) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" sx={{ mt: 7 }}>
      <img
        alt={t('mobile.family-member.settlement')}
        src={OutstandingReceiptsSettlement}
        style={{ marginBottom: 35 }}
      />
      <Typography variant="h4" fontWeight="700" sx={{ mb: 2 }}>
        {t('mobile.family-member.settlement')}
      </Typography>
      <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
        {phone
          ? t('mobile.family-member.settlement-outstanding-receipts', {
              phone,
            })
          : t('mobile.family-member.settlement-outstanding-receipts-no-phone')}
      </Typography>
    </Stack>
  );
};
