import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import type {
  FamilyMemberResponse,
  IFMContext,
} from './types/familyMember.types';
import { useAuthentication } from '@pflegenavi/frontend/authentication';

const AppContext = createContext<IFMContext | undefined>(undefined);

export const useFMContext = (): IFMContext => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('Missing AppContextProvider!');
  }
  return context;
};

export const FMContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { user } = useAuthentication();
  const [locale] = useState<string | null>(null);
  const [familyMember, setFamilyMember] = useState<
    FamilyMemberResponse | undefined
  >();
  const [selectedResidentId, setSelectedResidentId] = useState<
    string | undefined
  >();
  const [refetchPayments, setRefetchPayments] = useState<boolean>(false);

  useEffect(() => {
    if (!user) {
      setSelectedResidentId(undefined);
      localStorage.removeItem('selectedResidentId');
    }
  }, [user]);

  const initFamilyMember = useCallback((user: FamilyMemberResponse) => {
    setFamilyMember(user);
  }, []);

  const initResident = useCallback((residentId?: string) => {
    if (residentId) {
      localStorage.setItem('selectedResidentId', residentId);
    } else {
      localStorage.removeItem('selectedResidentId');
    }
    setSelectedResidentId(residentId);
  }, []);

  const doRefetchPayments = (fetch: boolean) => {
    setRefetchPayments(fetch);
  };

  return (
    <AppContext.Provider
      value={{
        locale,
        familyMember,
        initFamilyMember,
        initResident,
        selectedResidentId,
        doRefetchPayments,
        refetchPayments,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
