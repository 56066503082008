import type { FC } from 'react';
import React, { useMemo } from 'react';
import Logo from '../../assets/pn-logo-with-name.svg';
import {
  Box,
  CircularProgress,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { Label } from '@pflegenavi/web-components';
import { useFormatting } from '@pflegenavi/frontend/localization';
import type { IResidentFull } from '@pflegenavi/shared/api';
import {
  DirectDebitStatus,
  PaymentInitiatedBy,
  ResidentState,
} from '@pflegenavi/shared/api';
import { useTranslation } from 'react-i18next';
import { usePaymentInfoByResidentIdAsFamilyMember } from '@pflegenavi/frontend/api-nursing-home';
import { BalanceStatusLabel } from '../general/BalanceStatusLabel';

interface ResidentCardProps {
  resident: IResidentFull | undefined;
  isResidentLoading: boolean;
}

// eslint-disable-next-line complexity
export const ResidentCard: FC<ResidentCardProps> = ({
  resident,
  isResidentLoading,
}: ResidentCardProps) => {
  const { fCurrency } = useFormatting();
  const { t } = useTranslation();

  const { data: paymentInfo, isLoading: isPaymentInfoLoading } =
    usePaymentInfoByResidentIdAsFamilyMember(resident?.id ?? '');

  const directDebit = useMemo(() => {
    if (paymentInfo?.initiatedBy === PaymentInitiatedBy.System) {
      return paymentInfo?.directDebit ?? DirectDebitStatus.Inactive;
    }
    return DirectDebitStatus.Inactive;
  }, [paymentInfo]);

  if (!resident || isResidentLoading || isPaymentInfoLoading) {
    return (
      <CardContainer>
        <CardInner spacing={1}>
          <Stack direction="row" justifyContent="flex-end">
            <img src={Logo} alt="Pflegenavi" height={22} />
          </Stack>
          <Stack justifyContent="center" alignItems="center">
            <CircularProgress color="info" sx={{ mt: 5 }} />
          </Stack>
        </CardInner>
      </CardContainer>
    );
  }

  const isInactive = resident?.state === ResidentState.archived;

  return (
    <CardContainer>
      <CardInner justifyContent="space-between" spacing={1}>
        <Stack direction="row" justifyContent="flex-end">
          <img src={Logo} alt="Pflegenavi" height={22} />
        </Stack>

        <Stack direction="column" alignItems="flex-start">
          <Typography variant="subtitle2" color="grey.400" sx={{ mb: 0 }}>
            {t('mobile.family-member.current-balance')}
          </Typography>
          <Typography variant="h3" color="white" sx={{ lineHeight: 1.3 }}>
            {fCurrency(resident.balance / 100)}
          </Typography>
          {!isInactive && (
            <BalanceStatusLabel
              negativeBalance={resident.negativeBalance}
              lowBalance={resident.belowThreshold}
            />
          )}
        </Stack>

        {isInactive ? (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography variant="subtitle2" color="grey.400" sx={{ mb: 0 }}>
              {t('mobile.family-member.account-status')}
            </Typography>
            <Label
              color="info"
              variant="filled"
              sx={{ ml: 0.5, color: 'white' }}
            >
              {t('mobile.family-member.settled')}
            </Label>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="flex-end" alignItems="center">
            <Typography variant="subtitle2" color="grey.400" sx={{ mb: 0 }}>
              {t('mobile.family-member.direct-debit')}
            </Typography>
            <Label sx={{ ml: 0.5, color: 'white' }}>
              {t(renderDirectDebitStatus(directDebit))}
            </Label>
          </Stack>
        )}

        <Stack direction="column" justifyContent="flex-start">
          <Typography variant="caption" color="grey.500" sx={{ mb: 0 }}>
            {t('common.resident')}
          </Typography>
          <Typography variant="h6" color="white" sx={{ lineHeight: 1.2 }}>
            {`${resident.firstName} ${resident.lastName}`}
          </Typography>
          <Typography variant="caption" color="grey.500" sx={{ mb: 0 }}>
            {resident.nursingHomeName}
          </Typography>
        </Stack>
      </CardInner>
    </CardContainer>
  );
};

export const renderDirectDebitStatus = (
  directDebit: DirectDebitStatus
): string => {
  const directDebitString = directDebit.toLowerCase();
  return `direct-debit.status.${directDebitString}`;
};

const CardContainer = styled(Box)(
  sx({
    minHeight: 250,
    borderRadius: 4,
    height: '100%',
    overflow: 'hidden',
    bgcolor: 'primary.main',
    position: 'relative',
    boxShadow: '-5px 5px 20px 2px rgba(150,170,180,0.5)',
  })
);
const CardInner = styled(Stack)(
  sx({
    width: '100%',
    height: '100%',
    paddingX: 3,
    paddingY: 2,
    overflow: 'hidden',
    zIndex: 2,
    position: 'relative',
  })
);
