/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Fuse from 'fuse.js';
import { isSameYear } from 'date-fns';
import type { GetTransactionsWithReceiptDto } from '@pflegenavi/shared/api';
import { YEAR_MONTH_DAY_SHORT_FORMAT } from '@pflegenavi/shared/constants';

export function fmApplySortFilter({
  dataFiltered,
  comparator,
  filterTerms,
  reportingYear,
  locale,
}: {
  dataFiltered: GetTransactionsWithReceiptDto[];
  comparator: (a: any, b: any) => number;
  filterTerms: string;
  reportingYear: Date;
  locale: string;
}) {
  const stabilizedThis = dataFiltered.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  dataFiltered = stabilizedThis.map((el) => el[0]);

  if (filterTerms) {
    const fuse = new Fuse(dataFiltered, {
      includeScore: true, // mostly for test purposes
      threshold: 0.1,
      keys: [
        'type',
        'receiptType',
        'serviceProvider',
        'creatorName',
        'amount',
        'date',
      ],

      getFn: (obj, path) => {
        if (path.length === 1 && path[0] === 'date') {
          return obj[path[0]].toLocaleDateString(
            locale,
            YEAR_MONTH_DAY_SHORT_FORMAT
          );
        }
        if (path.length === 1 && path[0] === 'amount') {
          return obj[path[0]].toString();
        }

        const getValue = (innerObj: object | string, indx: number): string => {
          if (innerObj === undefined || indx >= path.length) {
            return typeof innerObj === 'string' ? innerObj : '';
          }
          // @ts-expect-error index types unknown
          return getValue(innerObj[path[indx]], ++indx);
        };
        return getValue(obj, 0);
      },
    });
    const rawFilteredData = fuse.search(filterTerms);
    dataFiltered = rawFilteredData.map(({ item }) => item);
  }

  const filterByYear = ({ date }: GetTransactionsWithReceiptDto) =>
    isSameYear(reportingYear, date);

  dataFiltered = dataFiltered.filter(filterByYear);

  return dataFiltered;
}
