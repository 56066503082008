import type { KeycloakInstance } from 'keycloak-js';
import Keycloak from 'keycloak-js';
import type { User } from '@pflegenavi/frontend/authentication';
import { shouldUseNewLogin } from '@pflegenavi/shared-frontend/authentication';

export let keycloakUrl = `http://localhost:5051/auth`;
const realm = 'family_member';
if (window.location.hostname === 'family-member.staging.pflegenavi.at') {
  keycloakUrl = `https://auth-staging.pflegenavi.at/auth`;
} else if (window.location.hostname === 'family-member.pflegenavi.at') {
  keycloakUrl = 'https://auth.pflegenavi.at/auth';
} else if (window.location.hostname === 'nginx') {
  keycloakUrl = 'http://nginx:8080/auth';
}

// @ts-expect-error 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
export const keycloak: KeycloakInstance = new Keycloak({
  url: keycloakUrl,
  realm,
  clientId: 'family-member-web-app',
});

// @ts-expect-error Property 'getTokenImmediate' does not exist on type 'KeycloakInstance'.
keycloak.getTokenImmediate = () => keycloak.token;

keycloak.onTokenExpired = () => {
  // eslint-disable-next-line no-console
  console.debug('Token expired');
  keycloak.updateToken(30).then(() => {
    // eslint-disable-next-line no-console
    console.debug('Received new token');
  });
};

export const login = (setUser: (user: User) => void): void => {
  // eslint-disable-next-line complexity
  keycloak.onReady = async (authenticated) => {
    if (authenticated) {
      const userProfile = await keycloak.loadUserProfile();
      if (keycloak.token && userProfile.username) {
        setUser({
          userId: keycloak.tokenParsed?.sub ?? '',
          username: userProfile.username,
          firstName: userProfile.firstName ?? '',
          lastName: userProfile.lastName ?? '',
          email: userProfile.email ?? '',
        });
      }
    }
  };

  if (!shouldUseNewLogin()) {
    keycloak.init({
      onLoad: 'login-required',
    });
  }
};
