import type { FC } from 'react';
import { FinalSettlementDirectDebit } from './FinalSettlementDirectDebit';
import { ResidentState } from '@pflegenavi/shared/api';
import { SettledDirectDebit } from './SettledDirectDebit';
import { SettlementDirectDebitActive } from './SettlementDirectDebitActive';
import { SettlementDirectDebit } from './SettlementDirectDebit';
import { DirectDebitDisabled } from './DirectDebitDisabled';

interface DirectDebitNonActiveCasesProps {
  nursingHomePhone: string;
  residentState: ResidentState | undefined;
  pendingPayment: boolean;
  paymentDisabled: boolean;
}

// eslint-disable-next-line complexity
export const DirectDebitNonActiveCases: FC<DirectDebitNonActiveCasesProps> = ({
  residentState,
  pendingPayment,
  nursingHomePhone,
  paymentDisabled,
}) => {
  if (residentState === ResidentState.archived) {
    return <SettledDirectDebit />;
  }

  if (paymentDisabled) {
    return <DirectDebitDisabled />;
  }

  if (pendingPayment) {
    return <SettlementDirectDebitActive phone={nursingHomePhone} />;
  }

  if (residentState === ResidentState.exited && !pendingPayment) {
    return <SettlementDirectDebit phone={nursingHomePhone} />;
  }

  return <FinalSettlementDirectDebit phone={nursingHomePhone} />;
};
