import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ResidentInSettlement from '../../assets/undraw_resident_in_settlement.svg';

export const SettlementDirectDebitActive: FC<{ phone: string }> = ({
  phone,
}) => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" sx={{ mt: 7 }}>
      <img
        alt={t('direct-debit.settlement-direct-debit-active')}
        src={ResidentInSettlement}
        style={{ marginBottom: 35 }}
      />
      <Typography variant="h4" fontWeight="700" sx={{ mb: 2 }}>
        {t('direct-debit.settlement-direct-debit-active')}
      </Typography>
      <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
        {t('direct-debit.settlement-pending-payment', {
          phone,
        })}
      </Typography>
    </Stack>
  );
};
