import { Container } from '@mui/material';
import type { FC } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Divider,
  HeaderBreadcrumbs,
  NumberLabel,
  Stack,
  Tab,
  Tabs,
  YearNavigationBlock,
} from '@pflegenavi/web-components';
import { fmAppMainPages } from '@pflegenavi/frontend/routing';
import type { TransactionInitiatedBy } from '@pflegenavi/shared/api';
import {
  TransactionSourceType,
  TransactionStatusColors,
} from '@pflegenavi/shared/api';
import { useLocale } from '@pflegenavi/frontend/localization';
import { useTransactionsForFamilyMember } from '@pflegenavi/frontend/api-nursing-home';
import {
  PaymentStatusValues,
  useFmPaymentsTable,
} from '../hooks/useFmPaymentsTable';

import { FmPaymentsToolbar } from '../components/payments/FmPaymentsToolbar';
import { FmPaymentsTable } from '../components/payments/FmPaymentsTable';
import { fmApplyPaymentsSortFilter } from '../helpers/fmPaymentsTableFilter';
import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { usePaymentTypeStringFromSourceType } from '../components/payments/usePaymentTypeStringFromSourceType';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PaymentsPageProps {}

export interface SearchablePayment {
  id: string;
  type: string;
  serviceProvider: string | undefined;
  date: Date;
  amount: number;
  sourceType: TransactionSourceType;
  sourceTypeString: string;
  paymentStateString: string | undefined;
  disputed: boolean;

  initiatedBy: TransactionInitiatedBy;

  cancelled?: boolean;
  ibanLast4?: string;
  sequenceID: string;
}

// We don't want to create a new object every time the component is rendered
const sourceTypes = [
  TransactionSourceType.InitialDeposit,
  TransactionSourceType.ManualDeposit,
  TransactionSourceType.CancelledManualDeposit,
  TransactionSourceType.DirectDebit,
  TransactionSourceType.FinalSettlement,
  TransactionSourceType.Dispute,
];

export const PaymentsPage: FC<PaymentsPageProps> = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { selectedResidentId } = useFMContext();

  const {
    isLoading,
    isError,
    data: payments,
  } = useTransactionsForFamilyMember(
    selectedResidentId ?? 'undefined',
    sourceTypes
  );

  const {
    filterTerms,
    setFilterTerms,
    reportingYear,
    setReportingYear,
    order,
    orderBy,
    setPage,
    ...tableProps
  } = useFmPaymentsTable();

  const { getPaymentTypeString, getAdditionalPaymentTypeString } =
    usePaymentTypeStringFromSourceType();

  const searchablePayments: SearchablePayment[] | undefined = useMemo(() => {
    return payments?.map((payment) => ({
      id: payment.id,
      sourceType: payment.sourceType,
      sourceTypeString: getPaymentTypeString({
        sourceType: payment.sourceType,
        initiatedBy: payment.initiatedBy,
      }),
      type: payment.type,
      serviceProvider: payment.serviceProvider,
      date: payment.date,
      amount: payment.amount,
      paymentStateString: getAdditionalPaymentTypeString({
        sourceType: payment.sourceType,
        cancelled: payment.cancelled,
        disputed: payment.disputed,
      }),
      disputed: payment.disputed,
      initiatedBy: payment.initiatedBy,
      cancelled: payment.cancelled,
      ibanLast4: payment.ibanLast4,
      sequenceID: payment.sequenceID,
    }));
  }, [getAdditionalPaymentTypeString, getPaymentTypeString, payments]);

  const dataFiltered = useMemo(() => {
    return fmApplyPaymentsSortFilter({
      dataFiltered: searchablePayments ?? [],
      comparator: tableProps.comparator,
      filterTerms: filterTerms,
      reportingYear,
      locale,
    });
  }, [
    searchablePayments,
    tableProps.comparator,
    filterTerms,
    reportingYear,
    locale,
  ]);

  const TABS = useMemo(() => {
    return [
      {
        value: PaymentStatusValues.ALL,
        label: t(`transaction-status.${PaymentStatusValues.ALL}`),
        color: TransactionStatusColors.ALL,
        count: dataFiltered?.length ?? 0,
      },
    ];
  }, [dataFiltered, t]);

  return (
    <Container sx={{ maxWidth: 1400 }}>
      <HeaderBreadcrumbs
        heading={t('payments.main')}
        links={[
          {
            name: t('payments.main'),
            href: fmAppMainPages.PAYMENTS,
          },
          { name: t('common.overview') },
        ]}
        sx={{
          pb: 0,
        }}
      />
      <Card sx={{ maxWidth: 850 }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={PaymentStatusValues.ALL}
          sx={{
            px: 2,
            bgcolor: 'background.neutral',
          }}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              label={
                <Stack spacing={1} direction="row" alignItems="center">
                  <div>{tab.label}</div>
                  <NumberLabel color={tab.color} variant="ghost">
                    {tab.count}
                  </NumberLabel>
                </Stack>
              }
            />
          ))}
          <Stack
            sx={{
              flex: 1,
            }}
          />
          <Stack sx={{ justifyContent: 'center' }}>
            <YearNavigationBlock
              reportingYear={reportingYear}
              setReportingYear={setReportingYear}
            />
          </Stack>
        </Tabs>

        <Divider />
        <FmPaymentsToolbar
          filterTerms={filterTerms}
          onFilterTerms={setFilterTerms}
        />

        <FmPaymentsTable
          dataAvailableBeforeFilter={Boolean(
            (searchablePayments ?? []).length > 0
          )}
          payments={dataFiltered}
          isError={isError}
          isLoading={isLoading}
          isFiltered={!!filterTerms}
          order={order}
          orderBy={orderBy}
          setPage={setPage}
          {...tableProps}
        />
      </Card>
    </Container>
  );
};
