import type { FC } from 'react';
import * as React from 'react';
import {
  Box,
  Card,
  Container,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import {
  CommonProfileCover,
  HeaderBreadcrumbs,
  NameAvatar,
  ProfileIcon,
  SvgIconStyle,
  TabsWrapperStyle,
} from '@pflegenavi/web-components';
import { fmAppMainPages, generatePath } from '@pflegenavi/frontend/routing';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { experimental_sx as sx } from '@mui/system';
import { useFamilyMemberProfile } from '@pflegenavi/frontend/api-nursing-home';

export const ProfilePage: FC = () => {
  const currentTab = 'details';

  const { t } = useTranslation();
  const navigate = useNavigate();

  const PROFILE_TABS = [
    {
      key: 'details',
      value: t('usermenu.profile'),
      icon: <SvgIconStyle src={ProfileIcon} sx={{ width: 20, mr: 1 }} />,
      onClick: () => {
        navigate(generatePath(fmAppMainPages.PROFILE));
      },
    },
  ];

  return (
    <Box>
      <Container maxWidth={'lg'}>
        <HeaderBreadcrumbs
          heading={t('usermenu.profile')}
          links={[
            { name: t('usermenu.profile'), href: fmAppMainPages.PROFILE },
            { name: t('common.overview') },
          ]}
        />
        <Card
          sx={{
            mb: 3,
            height: 200,
            position: 'relative',
          }}
        >
          <ProfileCover />

          <TabsWrapperStyle>
            <Tabs
              allowScrollButtonsMobile
              scrollButtons="auto"
              value={currentTab}
            >
              {PROFILE_TABS.map((tab) => (
                <Tab
                  onClick={tab.onClick}
                  disableRipple
                  sx={{ fontSize: 16 }}
                  key={tab.key}
                  value={tab.key}
                  icon={tab.icon}
                  label={tab.value}
                  iconPosition="start"
                />
              ))}
            </Tabs>
          </TabsWrapperStyle>
        </Card>
        <Outlet />
      </Container>
    </Box>
  );
};

export const ProfileCover: FC = () => {
  const { data: userProfile, isLoading: isUserProfileLoading } =
    useFamilyMemberProfile({});

  if (isUserProfileLoading) {
    return <Skeleton width="100%" height="100%" />;
  }

  const name = userProfile?.user
    ? `${userProfile?.user.firstName} ${userProfile?.user.lastName}`
    : '';
  return (
    <CommonProfileCover>
      <StyledNameAvatar fontSize={25} name={name} />
      <NameContainer>
        <Typography variant="h4">{name}</Typography>
      </NameContainer>
    </CommonProfileCover>
  );
};

const NameContainer = styled(Box)(
  sx({
    ml: { md: 2 },
    mt: { xs: 1, md: 0 },
    color: 'text.primary',
    textAlign: { xs: 'center', md: 'left' },
  })
);

const StyledNameAvatar = styled(NameAvatar)(
  sx({
    mx: 'auto',
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'common.white',
    width: { xs: 80, md: 100 },
    height: { xs: 80, md: 100 },
  })
);
