import type { FC } from 'react';
import React from 'react';
import { Stack, styled, Typography } from '@mui/material';
import { experimental_sx as sx } from '@mui/system';
import { DirectDebitStatus } from '@pflegenavi/shared/api';

import InfoBoardImage from '../../assets/undraw_direct_debit_woman.svg';
import { useTranslation } from 'react-i18next';
import { Label } from '@pflegenavi/web-components';

export const DirectDebitInfoBox: FC<{
  status: DirectDebitStatus;
}> = ({ status }) => {
  const { t } = useTranslation();

  const handleScrollToForm = () =>
    document
      .getElementById('activate-direct-debit-button')
      ?.scrollIntoView({ behavior: 'smooth' });

  if (status !== DirectDebitStatus.Inactive) {
    return null;
  }

  return (
    <BoxContainer direction="row" justifyContent="space-between">
      <Stack direction="column" alignItems="flex-start">
        <Label
          color="secondary"
          variant="filled"
          onClick={handleScrollToForm}
          sx={{
            mb: 2,
            bgcolor: 'secondary.dark',
            color: 'white',
            paddingY: 1.7,
          }}
        >
          {t('mobile.family-member.direct-debit-setup')}
        </Label>
        <Typography variant="subtitle1" fontWeight={700}>
          {t('mobile.family-member.direct-debit-takes-few-minutes')}
        </Typography>
        <Typography variant="subtitle1">
          {t('mobile.family-member.please-fill-mandate-data')}
        </Typography>
      </Stack>
      <img src={InfoBoardImage} alt="" width={180} />
    </BoxContainer>
  );
};

const BoxContainer = styled(Stack)(
  sx({
    bgcolor: 'primary.lighter',
    width: '100%',
    borderRadius: 3,
    paddingX: 3,
    paddingY: 3,
    mb: 2,
  })
);
