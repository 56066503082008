import type { FC } from 'react';
import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PaymentDisabled from '../../assets/undraw_payment_failed.svg';

export const DirectDebitDisabled: FC = () => {
  const { t } = useTranslation();

  return (
    <Stack alignItems="center" sx={{ mt: 7 }}>
      <img
        alt={t('mobile.family-member.disabled-payment')}
        src={PaymentDisabled}
        style={{ marginBottom: 35, width: 190 }}
      />
      <Typography variant="h4" fontWeight="700" sx={{ mb: 2 }}>
        {t('mobile.family-member.disabled-payment')}
      </Typography>
      <Typography variant="h6" sx={{ maxWidth: 600, textAlign: 'center' }}>
        {t('mobile.family-member.disabled-payment-contact-nursing-home')}
      </Typography>
    </Stack>
  );
};
