import { useFMContext } from '@pflegenavi/frontend/family-member-context';
import { useMemo } from 'react';
import { useFamilyMemberTenant } from '@pflegenavi/frontend/api-nursing-home';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useInitializeFM = () => {
  const { selectedResidentId } = useFMContext();

  if (!selectedResidentId) {
    throw new Error('No resident selected');
  }
  const { data: residents } = useFamilyMemberTenant();

  const selectedResident = useMemo(() => {
    if (residents) {
      return residents?.filter(
        (resident) => resident.residentId === selectedResidentId
      )[0];
    }
    return undefined;
  }, [residents, selectedResidentId]);

  return {
    residents,
    selectedResident,
  };
};
