import { TransactionSourceType } from '@pflegenavi/shared/api';

import CancelLogo from '../assets/transaction-icons/cancel.svg';
import RefundGreenLogo from '../assets/transaction-icons/refund-green.svg';
import ClockLogo from '../assets/transaction-icons/clock.svg';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTransactionSourceIcon = ({
  sourceType,
  cancelled,
}: {
  sourceType: TransactionSourceType;
  cancelled?: boolean;
}) => {
  if (cancelled) {
    return { icon: CancelLogo, color: 'error' };
  }
  if (sourceType === TransactionSourceType.CancelledReceipt) {
    return { icon: RefundGreenLogo, color: 'success' };
  }
  if (sourceType === TransactionSourceType.RecurringItem) {
    return {
      icon: ClockLogo,
      color: 'info',
    };
  }
  if (sourceType === TransactionSourceType.Receipt) {
    return {
      icon: '',
      color: '',
    };
  }
  return {
    icon: '',
    color: '',
  };
};
